.flex-container {
  display: flex;
  border: 1px solid green;
  justify-content: space-between;
  padding: 5px;
  margin-top: 10px;
}

.flex-item {
  margin: 5px;
}

.flex-item:first-child {
  /* background-color: aqua; */
  flex: 2;
}

.flex-item:nth-child(2) {
  /* background-color: blueviolet; */
  flex: 1;
}

.flex-item:last-child {
  background-color: beige;
  flex: 1;
  flex-direction: column;
  overflow-y: scroll;
}

#remote-media video {
  width: inherit;
  height: 200px;
}

.error-message{
  color: red;
}