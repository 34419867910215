@font-face {
  font-family: 'fonticon';
  src:  url('fonts/fonticon.eot?uwrtux');
  src:  url('fonts/fonticon.eot?uwrtux#iefix') format('embedded-opentype'),
    url('fonts/fonticon.ttf?uwrtux') format('truetype'),
    url('fonts/fonticon.woff?uwrtux') format('woff'),
    url('fonts/fonticon.svg?uwrtux#fonticon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="ficon-"], [class*=" ficon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'fonticon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ficon-px:before {
  content: "\f953";
}
.ficon-accessible-icon:before {
  content: "\f954";
}
.ficon-accusoft:before {
  content: "\f955";
}
.ficon-acquisitions-incorporated:before {
  content: "\f956";
}
.ficon-adn:before {
  content: "\f957";
}
.ficon-adversal:before {
  content: "\f958";
}
.ficon-affiliatetheme:before {
  content: "\f959";
}
.ficon-algolia:before {
  content: "\f95a";
}
.ficon-alipay:before {
  content: "\f95b";
}
.ficon-amazon:before {
  content: "\f95c";
}
.ficon-amazon-pay:before {
  content: "\f95d";
}
.ficon-amilia:before {
  content: "\f95e";
}
.ficon-android:before {
  content: "\f95f";
}
.ficon-angellist:before {
  content: "\f960";
}
.ficon-angrycreative:before {
  content: "\f961";
}
.ficon-angular:before {
  content: "\f962";
}
.ficon-apper:before {
  content: "\f963";
}
.ficon-apple:before {
  content: "\f964";
}
.ficon-apple-pay:before {
  content: "\f965";
}
.ficon-app-store:before {
  content: "\f966";
}
.ficon-app-store-ios:before {
  content: "\f967";
}
.ficon-asymmetrik:before {
  content: "\f968";
}
.ficon-audible:before {
  content: "\f969";
}
.ficon-autoprefixer:before {
  content: "\f96a";
}
.ficon-avianex:before {
  content: "\f96b";
}
.ficon-aviato:before {
  content: "\f96c";
}
.ficon-aws:before {
  content: "\f96d";
}
.ficon-bandcamp:before {
  content: "\f96e";
}
.ficon-behance:before {
  content: "\f96f";
}
.ficon-behance-square:before {
  content: "\f970";
}
.ficon-bimobject:before {
  content: "\f971";
}
.ficon-bitbucket:before {
  content: "\f972";
}
.ficon-bitcoin:before {
  content: "\f973";
}
.ficon-bity:before {
  content: "\f974";
}
.ficon-blackberry:before {
  content: "\f975";
}
.ficon-black-tie:before {
  content: "\f976";
}
.ficon-blogger:before {
  content: "\f977";
}
.ficon-blogger-b:before {
  content: "\f978";
}
.ficon-bluetooth:before {
  content: "\f979";
}
.ficon-bluetooth-b:before {
  content: "\f97a";
}
.ficon-btc:before {
  content: "\f97b";
}
.ficon-buromobelexperte:before {
  content: "\f97c";
}
.ficon-buysellads:before {
  content: "\f97d";
}
.ficon-cc-amazon-pay:before {
  content: "\f97e";
}
.ficon-cc-amex:before {
  content: "\f97f";
}
.ficon-cc-apple-pay:before {
  content: "\f980";
}
.ficon-cc-diners-club:before {
  content: "\f981";
}
.ficon-cc-discover:before {
  content: "\f982";
}
.ficon-cc-jcb:before {
  content: "\f983";
}
.ficon-cc-mastercard:before {
  content: "\f984";
}
.ficon-cc-paypal:before {
  content: "\f985";
}
.ficon-cc-stripe:before {
  content: "\f986";
}
.ficon-cc-visa:before {
  content: "\f987";
}
.ficon-centercode:before {
  content: "\f988";
}
.ficon-chrome:before {
  content: "\f989";
}
.ficon-cloudscale:before {
  content: "\f98a";
}
.ficon-cloudsmith:before {
  content: "\f98b";
}
.ficon-cloudversify:before {
  content: "\f98c";
}
.ficon-codepen:before {
  content: "\f98d";
}
.ficon-codiepie:before {
  content: "\f98e";
}
.ficon-connectdevelop:before {
  content: "\f98f";
}
.ficon-contao:before {
  content: "\f990";
}
.ficon-cpanel:before {
  content: "\f991";
}
.ficon-creative-commons:before {
  content: "\f992";
}
.ficon-creative-commons-by:before {
  content: "\f993";
}
.ficon-creative-commons-nc:before {
  content: "\f994";
}
.ficon-creative-commons-nc-eu:before {
  content: "\f995";
}
.ficon-creative-commons-nc-jp:before {
  content: "\f996";
}
.ficon-creative-commons-nd:before {
  content: "\f997";
}
.ficon-creative-commons-pd:before {
  content: "\f998";
}
.ficon-creative-commons-pd-alt:before {
  content: "\f999";
}
.ficon-creative-commons-remix:before {
  content: "\f99a";
}
.ficon-creative-commons-sa:before {
  content: "\f99b";
}
.ficon-creative-commons-sampling:before {
  content: "\f99c";
}
.ficon-creative-commons-sampling-plus:before {
  content: "\f99d";
}
.ficon-creative-commons-share:before {
  content: "\f99e";
}
.ficon-creative-commons-zero:before {
  content: "\f99f";
}
.ficon-critical-role:before {
  content: "\f9a0";
}
.ficon-css3:before {
  content: "\f9a1";
}
.ficon-css3-alt:before {
  content: "\f9a2";
}
.ficon-cuttlefish:before {
  content: "\f9a3";
}
.ficon-d-and-d:before {
  content: "\f9a4";
}
.ficon-d-and-d-beyond:before {
  content: "\f9a5";
}
.ficon-dashcube:before {
  content: "\f9a6";
}
.ficon-delicious:before {
  content: "\f9a7";
}
.ficon-deploydog:before {
  content: "\f9a8";
}
.ficon-deskpro:before {
  content: "\f9a9";
}
.ficon-dev:before {
  content: "\f9aa";
}
.ficon-deviantart:before {
  content: "\f9ab";
}
.ficon-digg:before {
  content: "\f9ac";
}
.ficon-digital-ocean:before {
  content: "\f9ad";
}
.ficon-discord:before {
  content: "\f9ae";
}
.ficon-discourse:before {
  content: "\f9af";
}
.ficon-dochub:before {
  content: "\f9b0";
}
.ficon-docker:before {
  content: "\f9b1";
}
.ficon-draft2digital:before {
  content: "\f9b2";
}
.ficon-dribbble:before {
  content: "\f9b3";
}
.ficon-dribbble-square:before {
  content: "\f9b4";
}
.ficon-dropbox:before {
  content: "\f9b5";
}
.ficon-drupal:before {
  content: "\f9b6";
}
.ficon-dyalog:before {
  content: "\f9b7";
}
.ficon-earlybirds:before {
  content: "\f9b8";
}
.ficon-ebay:before {
  content: "\f9b9";
}
.ficon-edge:before {
  content: "\f9ba";
}
.ficon-elementor:before {
  content: "\f9bb";
}
.ficon-ello:before {
  content: "\f9bc";
}
.ficon-ember:before {
  content: "\f9bd";
}
.ficon-empire:before {
  content: "\f9be";
}
.ficon-envira:before {
  content: "\f9bf";
}
.ficon-erlang:before {
  content: "\f9c0";
}
.ficon-ethereum:before {
  content: "\f9c1";
}
.ficon-etsy:before {
  content: "\f9c2";
}
.ficon-expeditedssl:before {
  content: "\f9c3";
}
.ficon-facebook1:before {
  content: "\f9c4";
}
.ficon-facebook-f:before {
  content: "\f9c5";
}
.ficon-facebook-messenger:before {
  content: "\f9c6";
}
.ficon-facebook-square:before {
  content: "\f9c7";
}
.ficon-fantasy-flight-games:before {
  content: "\f9c8";
}
.ficon-firefox:before {
  content: "\f9c9";
}
.ficon-firstdraft:before {
  content: "\f9ca";
}
.ficon-first-order:before {
  content: "\f9cb";
}
.ficon-first-order-alt:before {
  content: "\f9cc";
}
.ficon-flickr:before {
  content: "\f9cd";
}
.ficon-flipboard:before {
  content: "\f9ce";
}
.ficon-fly:before {
  content: "\f9cf";
}
.ficon-font-awesome:before {
  content: "\f9d0";
}
.ficon-font-awesome-alt:before {
  content: "\f9d1";
}
.ficon-font-awesome-flag:before {
  content: "\f9d2";
}
.ficon-font-awesome-logo-full:before {
  content: "\f9d3";
}
.ficon-fonticons:before {
  content: "\f9d4";
}
.ficon-fonticons-fi:before {
  content: "\f9d5";
}
.ficon-fort-awesome:before {
  content: "\f9d6";
}
.ficon-fort-awesome-alt:before {
  content: "\f9d7";
}
.ficon-forumbee:before {
  content: "\f9d8";
}
.ficon-foursquare:before {
  content: "\f9d9";
}
.ficon-freebsd:before {
  content: "\f9da";
}
.ficon-free-code-camp:before {
  content: "\f9db";
}
.ficon-fulcrum:before {
  content: "\f9dc";
}
.ficon-galactic-republic:before {
  content: "\f9dd";
}
.ficon-galactic-senate:before {
  content: "\f9de";
}
.ficon-get-pocket:before {
  content: "\f9df";
}
.ficon-gg:before {
  content: "\f9e0";
}
.ficon-gg-circle:before {
  content: "\f9e1";
}
.ficon-git:before {
  content: "\f9e2";
}
.ficon-github:before {
  content: "\f9e3";
}
.ficon-github-alt:before {
  content: "\f9e4";
}
.ficon-github-square:before {
  content: "\f9e5";
}
.ficon-gitkraken:before {
  content: "\f9e6";
}
.ficon-gitlab:before {
  content: "\f9e7";
}
.ficon-git-square:before {
  content: "\f9e8";
}
.ficon-gitter:before {
  content: "\f9e9";
}
.ficon-glide:before {
  content: "\f9ea";
}
.ficon-glide-g:before {
  content: "\f9eb";
}
.ficon-gofore:before {
  content: "\f9ec";
}
.ficon-goodreads:before {
  content: "\f9ed";
}
.ficon-goodreads-g:before {
  content: "\f9ee";
}
.ficon-google:before {
  content: "\f9ef";
}
.ficon-google-drive:before {
  content: "\f9f0";
}
.ficon-google-play:before {
  content: "\f9f1";
}
.ficon-google-plus:before {
  content: "\f9f2";
}
.ficon-google-plus-g:before {
  content: "\f9f3";
}
.ficon-google-plus-square:before {
  content: "\f9f4";
}
.ficon-google-wallet:before {
  content: "\f9f5";
}
.ficon-gratipay:before {
  content: "\f9f6";
}
.ficon-grav:before {
  content: "\f9f7";
}
.ficon-gripfire:before {
  content: "\f9f8";
}
.ficon-grunt:before {
  content: "\f9f9";
}
.ficon-gulp:before {
  content: "\f9fa";
}
.ficon-hacker-news:before {
  content: "\f9fb";
}
.ficon-hacker-news-square:before {
  content: "\f9fc";
}
.ficon-hackerrank:before {
  content: "\f9fd";
}
.ficon-hips:before {
  content: "\f9fe";
}
.ficon-hire-a-helper:before {
  content: "\f9ff";
}
.ficon-hooli:before {
  content: "\fa00";
}
.ficon-hornbill:before {
  content: "\fa01";
}
.ficon-hotjar:before {
  content: "\fa02";
}
.ficon-houzz:before {
  content: "\fa03";
}
.ficon-html5:before {
  content: "\fa04";
}
.ficon-hubspot:before {
  content: "\fa05";
}
.ficon-imdb:before {
  content: "\fa06";
}
.ficon-instagram:before {
  content: "\fa07";
}
.ficon-internet-explorer:before {
  content: "\fa08";
}
.ficon-ioxhost:before {
  content: "\fa09";
}
.ficon-itunes:before {
  content: "\fa0a";
}
.ficon-itunes-note:before {
  content: "\fa0b";
}
.ficon-java:before {
  content: "\fa0c";
}
.ficon-jedi-order:before {
  content: "\fa0d";
}
.ficon-jenkins:before {
  content: "\fa0e";
}
.ficon-joget:before {
  content: "\fa0f";
}
.ficon-joomla:before {
  content: "\fa10";
}
.ficon-js:before {
  content: "\fa11";
}
.ficon-jsfiddle:before {
  content: "\fa12";
}
.ficon-js-square:before {
  content: "\fa13";
}
.ficon-kaggle:before {
  content: "\fa14";
}
.ficon-keybase:before {
  content: "\fa15";
}
.ficon-keycdn:before {
  content: "\fa16";
}
.ficon-kickstarter:before {
  content: "\fa17";
}
.ficon-kickstarter-k:before {
  content: "\fa18";
}
.ficon-korvue:before {
  content: "\fa19";
}
.ficon-laravel:before {
  content: "\fa1a";
}
.ficon-lastfm:before {
  content: "\fa1b";
}
.ficon-lastfm-square:before {
  content: "\fa1c";
}
.ficon-leanpub:before {
  content: "\fa1d";
}
.ficon-less:before {
  content: "\fa1e";
}
.ficon-line:before {
  content: "\fa1f";
}
.ficon-linkedin:before {
  content: "\fa20";
}
.ficon-linkedin-in:before {
  content: "\fa21";
}
.ficon-linode:before {
  content: "\fa22";
}
.ficon-linux:before {
  content: "\fa23";
}
.ficon-lyft:before {
  content: "\fa24";
}
.ficon-magento:before {
  content: "\fa25";
}
.ficon-mailchimp:before {
  content: "\fa26";
}
.ficon-mandalorian:before {
  content: "\fa27";
}
.ficon-markdown:before {
  content: "\fa28";
}
.ficon-mastodon:before {
  content: "\fa29";
}
.ficon-maxcdn:before {
  content: "\fa2a";
}
.ficon-medapps:before {
  content: "\fa2b";
}
.ficon-medium:before {
  content: "\fa2c";
}
.ficon-medium-m:before {
  content: "\fa2d";
}
.ficon-medrt:before {
  content: "\fa2e";
}
.ficon-meetup:before {
  content: "\fa2f";
}
.ficon-megaport:before {
  content: "\fa30";
}
.ficon-microsoft:before {
  content: "\fa31";
}
.ficon-mix:before {
  content: "\fa32";
}
.ficon-mixcloud:before {
  content: "\fa33";
}
.ficon-mizuni:before {
  content: "\fa34";
}
.ficon-modx:before {
  content: "\fa35";
}
.ficon-monero:before {
  content: "\fa36";
}
.ficon-napster:before {
  content: "\fa37";
}
.ficon-neos:before {
  content: "\fa38";
}
.ficon-nimblr:before {
  content: "\fa39";
}
.ficon-nintendo-switch:before {
  content: "\fa3a";
}
.ficon-node:before {
  content: "\fa3b";
}
.ficon-node-js:before {
  content: "\fa3c";
}
.ficon-npm:before {
  content: "\fa3d";
}
.ficon-ns8:before {
  content: "\fa3e";
}
.ficon-nutritionix:before {
  content: "\fa3f";
}
.ficon-odnoklassniki:before {
  content: "\fa40";
}
.ficon-odnoklassniki-square:before {
  content: "\fa41";
}
.ficon-old-republic:before {
  content: "\fa42";
}
.ficon-opencart:before {
  content: "\fa43";
}
.ficon-openid:before {
  content: "\fa44";
}
.ficon-opera:before {
  content: "\fa45";
}
.ficon-optin-monster:before {
  content: "\fa46";
}
.ficon-osi:before {
  content: "\fa47";
}
.ficon-page4:before {
  content: "\fa48";
}
.ficon-pagelines:before {
  content: "\fa49";
}
.ficon-palfed:before {
  content: "\fa4a";
}
.ficon-patreon:before {
  content: "\fa4b";
}
.ficon-paypal:before {
  content: "\fa4c";
}
.ficon-penny-arcade:before {
  content: "\fa4d";
}
.ficon-periscope:before {
  content: "\fa4e";
}
.ficon-phabricator:before {
  content: "\fa4f";
}
.ficon-phoenix-framework:before {
  content: "\fa50";
}
.ficon-phoenix-squadron:before {
  content: "\fa51";
}
.ficon-php:before {
  content: "\fa52";
}
.ficon-pied-piper:before {
  content: "\fa53";
}
.ficon-pied-piper-alt:before {
  content: "\fa54";
}
.ficon-pied-piper-hat:before {
  content: "\fa55";
}
.ficon-pied-piper-pp:before {
  content: "\fa56";
}
.ficon-pinterest1:before {
  content: "\fa57";
}
.ficon-pinterest-p:before {
  content: "\fa58";
}
.ficon-pinterest-square:before {
  content: "\fa59";
}
.ficon-playstation:before {
  content: "\fa5a";
}
.ficon-product-hunt:before {
  content: "\fa5b";
}
.ficon-pushed:before {
  content: "\fa5c";
}
.ficon-python:before {
  content: "\fa5d";
}
.ficon-qq:before {
  content: "\fa5e";
}
.ficon-quinscape:before {
  content: "\fa5f";
}
.ficon-quora:before {
  content: "\fa60";
}
.ficon-ravelry:before {
  content: "\fa61";
}
.ficon-react:before {
  content: "\fa62";
}
.ficon-reacteurope:before {
  content: "\fa63";
}
.ficon-readme:before {
  content: "\fa64";
}
.ficon-rebel:before {
  content: "\fa65";
}
.ficon-reddit:before {
  content: "\fa66";
}
.ficon-reddit-alien:before {
  content: "\fa67";
}
.ficon-reddit-square:before {
  content: "\fa68";
}
.ficon-red-river:before {
  content: "\fa69";
}
.ficon-renren:before {
  content: "\fa6a";
}
.ficon-replyd:before {
  content: "\fa6b";
}
.ficon-researchgate:before {
  content: "\fa6c";
}
.ficon-resolving:before {
  content: "\fa6d";
}
.ficon-rev:before {
  content: "\fa6e";
}
.ficon-rocketchat:before {
  content: "\fa6f";
}
.ficon-rockrms:before {
  content: "\fa70";
}
.ficon-r-project:before {
  content: "\fa71";
}
.ficon-safari:before {
  content: "\fa72";
}
.ficon-sass:before {
  content: "\fa73";
}
.ficon-schlix:before {
  content: "\fa74";
}
.ficon-scribd:before {
  content: "\fa75";
}
.ficon-searchengin:before {
  content: "\fa76";
}
.ficon-sellcast:before {
  content: "\fa77";
}
.ficon-sellsy:before {
  content: "\fa78";
}
.ficon-servicestack:before {
  content: "\fa79";
}
.ficon-shirtsinbulk:before {
  content: "\fa7a";
}
.ficon-shopware:before {
  content: "\fa7b";
}
.ficon-simplybuilt:before {
  content: "\fa7c";
}
.ficon-sistrix:before {
  content: "\fa7d";
}
.ficon-sith:before {
  content: "\fa7e";
}
.ficon-skyatlas:before {
  content: "\fa7f";
}
.ficon-skype:before {
  content: "\fa80";
}
.ficon-slack:before {
  content: "\fa81";
}
.ficon-slack-hash:before {
  content: "\fa82";
}
.ficon-slideshare:before {
  content: "\fa83";
}
.ficon-snapchat:before {
  content: "\fa84";
}
.ficon-snapchat-ghost:before {
  content: "\fa85";
}
.ficon-snapchat-square:before {
  content: "\fa86";
}
.ficon-soundcloud:before {
  content: "\fa87";
}
.ficon-speakap:before {
  content: "\fa88";
}
.ficon-spotify:before {
  content: "\fa89";
}
.ficon-squarespace:before {
  content: "\fa8a";
}
.ficon-stack-exchange:before {
  content: "\fa8b";
}
.ficon-stack-overflow:before {
  content: "\fa8c";
}
.ficon-staylinked:before {
  content: "\fa8d";
}
.ficon-steam:before {
  content: "\fa8e";
}
.ficon-steam-square:before {
  content: "\fa8f";
}
.ficon-steam-symbol:before {
  content: "\fa90";
}
.ficon-sticker-mule:before {
  content: "\fa91";
}
.ficon-strava:before {
  content: "\fa92";
}
.ficon-stripe:before {
  content: "\fa93";
}
.ficon-stripe-s:before {
  content: "\fa94";
}
.ficon-studiovinari:before {
  content: "\fa95";
}
.ficon-stumbleupon:before {
  content: "\fa96";
}
.ficon-stumbleupon-circle:before {
  content: "\fa97";
}
.ficon-superpowers:before {
  content: "\fa98";
}
.ficon-supple:before {
  content: "\fa99";
}
.ficon-teamspeak:before {
  content: "\fa9a";
}
.ficon-telegram:before {
  content: "\fa9b";
}
.ficon-telegram-plane:before {
  content: "\fa9c";
}
.ficon-tencent-weibo:before {
  content: "\fa9d";
}
.ficon-themeco:before {
  content: "\fa9e";
}
.ficon-themeisle:before {
  content: "\fa9f";
}
.ficon-the-red-yeti:before {
  content: "\faa0";
}
.ficon-think-peaks:before {
  content: "\faa1";
}
.ficon-trade-federation:before {
  content: "\faa2";
}
.ficon-trello:before {
  content: "\faa3";
}
.ficon-tripadvisor:before {
  content: "\faa4";
}
.ficon-tumblr:before {
  content: "\faa5";
}
.ficon-tumblr-square:before {
  content: "\faa6";
}
.ficon-twitch:before {
  content: "\faa7";
}
.ficon-twitter1:before {
  content: "\faa8";
}
.ficon-twitter-square:before {
  content: "\faa9";
}
.ficon-typo3:before {
  content: "\faaa";
}
.ficon-uber:before {
  content: "\faab";
}
.ficon-uikit:before {
  content: "\faac";
}
.ficon-uniregistry:before {
  content: "\faad";
}
.ficon-untappd:before {
  content: "\faae";
}
.ficon-usb:before {
  content: "\faaf";
}
.ficon-ussunnah:before {
  content: "\fab0";
}
.ficon-vaadin:before {
  content: "\fab1";
}
.ficon-viacoin:before {
  content: "\fab2";
}
.ficon-viadeo:before {
  content: "\fab3";
}
.ficon-viadeo-square:before {
  content: "\fab4";
}
.ficon-viber:before {
  content: "\fab5";
}
.ficon-vimeo:before {
  content: "\fab6";
}
.ficon-vimeo-square:before {
  content: "\fab7";
}
.ficon-vimeo-v:before {
  content: "\fab8";
}
.ficon-vine:before {
  content: "\fab9";
}
.ficon-vk:before {
  content: "\faba";
}
.ficon-vnv:before {
  content: "\fabb";
}
.ficon-vuejs:before {
  content: "\fabc";
}
.ficon-weebly:before {
  content: "\fabd";
}
.ficon-weibo:before {
  content: "\fabe";
}
.ficon-weixin:before {
  content: "\fabf";
}
.ficon-whatsapp:before {
  content: "\fac0";
}
.ficon-whatsapp-square:before {
  content: "\fac1";
}
.ficon-whmcs:before {
  content: "\fac2";
}
.ficon-wikipedia-w:before {
  content: "\fac3";
}
.ficon-windows:before {
  content: "\fac4";
}
.ficon-wix:before {
  content: "\fac5";
}
.ficon-wizards-of-the-coast:before {
  content: "\fac6";
}
.ficon-wolf-pack-battalion:before {
  content: "\fac7";
}
.ficon-wordpress:before {
  content: "\fac8";
}
.ficon-wordpress-simple:before {
  content: "\fac9";
}
.ficon-wpbeginner:before {
  content: "\faca";
}
.ficon-wpexplorer:before {
  content: "\facb";
}
.ficon-wpforms:before {
  content: "\facc";
}
.ficon-wpressr:before {
  content: "\facd";
}
.ficon-xbox:before {
  content: "\face";
}
.ficon-xing:before {
  content: "\facf";
}
.ficon-xing-square:before {
  content: "\fad0";
}
.ficon-yahoo:before {
  content: "\fad1";
}
.ficon-yandex:before {
  content: "\fad2";
}
.ficon-yandex-international:before {
  content: "\fad3";
}
.ficon-y-combinator:before {
  content: "\fad4";
}
.ficon-yelp:before {
  content: "\fad5";
}
.ficon-yoast:before {
  content: "\fad6";
}
.ficon-youtube:before {
  content: "\fad7";
}
.ficon-abacus:before {
  content: "\f3e2";
}
.ficon-acorn:before {
  content: "\f3e3";
}
.ficon-ad:before {
  content: "\f3e4";
}
.ficon-address-book:before {
  content: "\f3e5";
}
.ficon-address-card:before {
  content: "\f3e6";
}
.ficon-adjust:before {
  content: "\f3e7";
}
.ficon-air-freshener:before {
  content: "\f3e8";
}
.ficon-alarm-clock:before {
  content: "\f3e9";
}
.ficon-alicorn:before {
  content: "\f3ea";
}
.ficon-align-center:before {
  content: "\f3eb";
}
.ficon-align-justify:before {
  content: "\f3ec";
}
.ficon-align-left:before {
  content: "\f3ed";
}
.ficon-align-right:before {
  content: "\f3ee";
}
.ficon-allergies:before {
  content: "\f3ef";
}
.ficon-ambulance:before {
  content: "\f3f0";
}
.ficon-american-sign-language-interpreting:before {
  content: "\f3f1";
}
.ficon-analytics:before {
  content: "\f3f2";
}
.ficon-anchor:before {
  content: "\f3f3";
}
.ficon-angle-double-down:before {
  content: "\f3f4";
}
.ficon-angle-double-left:before {
  content: "\f3f5";
}
.ficon-angle-double-right:before {
  content: "\f3f6";
}
.ficon-angle-double-up:before {
  content: "\f3f7";
}
.ficon-angle-down:before {
  content: "\f3f8";
}
.ficon-angle-left:before {
  content: "\f3f9";
}
.ficon-angle-right:before {
  content: "\f3fa";
}
.ficon-angle-up:before {
  content: "\f3fb";
}
.ficon-angry:before {
  content: "\f3fc";
}
.ficon-ankh:before {
  content: "\f3fd";
}
.ficon-apple-alt:before {
  content: "\f3fe";
}
.ficon-apple-crate:before {
  content: "\f3ff";
}
.ficon-archive:before {
  content: "\f400";
}
.ficon-archway:before {
  content: "\f401";
}
.ficon-arrow-alt-circle-down:before {
  content: "\f402";
}
.ficon-arrow-alt-circle-left:before {
  content: "\f403";
}
.ficon-arrow-alt-circle-right:before {
  content: "\f404";
}
.ficon-arrow-alt-circle-up:before {
  content: "\f405";
}
.ficon-arrow-alt-down:before {
  content: "\f406";
}
.ficon-arrow-alt-from-bottom:before {
  content: "\f407";
}
.ficon-arrow-alt-from-left:before {
  content: "\f408";
}
.ficon-arrow-alt-from-right:before {
  content: "\f409";
}
.ficon-arrow-alt-from-top:before {
  content: "\f40a";
}
.ficon-arrow-alt-left:before {
  content: "\f40b";
}
.ficon-arrow-alt-right:before {
  content: "\f40c";
}
.ficon-arrow-alt-square-down:before {
  content: "\f40d";
}
.ficon-arrow-alt-square-left:before {
  content: "\f40e";
}
.ficon-arrow-alt-square-right:before {
  content: "\f40f";
}
.ficon-arrow-alt-square-up:before {
  content: "\f410";
}
.ficon-arrow-alt-to-bottom:before {
  content: "\f411";
}
.ficon-arrow-alt-to-left:before {
  content: "\f412";
}
.ficon-arrow-alt-to-right:before {
  content: "\f413";
}
.ficon-arrow-alt-to-top:before {
  content: "\f414";
}
.ficon-arrow-alt-up:before {
  content: "\f415";
}
.ficon-arrow-circle-down:before {
  content: "\f416";
}
.ficon-arrow-circle-left:before {
  content: "\f417";
}
.ficon-arrow-circle-right:before {
  content: "\f418";
}
.ficon-arrow-circle-up:before {
  content: "\f419";
}
.ficon-arrow-down:before {
  content: "\f41a";
}
.ficon-arrow-from-bottom:before {
  content: "\f41b";
}
.ficon-arrow-from-left:before {
  content: "\f41c";
}
.ficon-arrow-from-right:before {
  content: "\f41d";
}
.ficon-arrow-from-top:before {
  content: "\f41e";
}
.ficon-arrow-left:before {
  content: "\f41f";
}
.ficon-arrow-right:before {
  content: "\f420";
}
.ficon-arrows:before {
  content: "\f421";
}
.ficon-arrows-alt:before {
  content: "\f422";
}
.ficon-arrows-alt-h:before {
  content: "\f423";
}
.ficon-arrows-alt-v:before {
  content: "\f424";
}
.ficon-arrows-h:before {
  content: "\f425";
}
.ficon-arrow-square-down:before {
  content: "\f426";
}
.ficon-arrow-square-left:before {
  content: "\f427";
}
.ficon-arrow-square-right:before {
  content: "\f428";
}
.ficon-arrow-square-up:before {
  content: "\f429";
}
.ficon-arrows-v:before {
  content: "\f42a";
}
.ficon-arrow-to-bottom:before {
  content: "\f42b";
}
.ficon-arrow-to-left:before {
  content: "\f42c";
}
.ficon-arrow-to-right:before {
  content: "\f42d";
}
.ficon-arrow-to-top:before {
  content: "\f42e";
}
.ficon-arrow-up:before {
  content: "\f42f";
}
.ficon-assistive-listening-systems:before {
  content: "\f430";
}
.ficon-asterisk:before {
  content: "\f431";
}
.ficon-at:before {
  content: "\f432";
}
.ficon-atlas:before {
  content: "\f433";
}
.ficon-atom:before {
  content: "\f434";
}
.ficon-atom-alt:before {
  content: "\f435";
}
.ficon-audio-description:before {
  content: "\f436";
}
.ficon-award:before {
  content: "\f437";
}
.ficon-axe:before {
  content: "\f438";
}
.ficon-axe-battle:before {
  content: "\f439";
}
.ficon-backpack:before {
  content: "\f43a";
}
.ficon-backspace:before {
  content: "\f43b";
}
.ficon-backward:before {
  content: "\f43c";
}
.ficon-badge:before {
  content: "\f43d";
}
.ficon-badge-check:before {
  content: "\f43e";
}
.ficon-badge-dollar:before {
  content: "\f43f";
}
.ficon-badge-percent:before {
  content: "\f440";
}
.ficon-badger-honey:before {
  content: "\f441";
}
.ficon-balance-scale:before {
  content: "\f442";
}
.ficon-balance-scale-left:before {
  content: "\f443";
}
.ficon-balance-scale-right:before {
  content: "\f444";
}
.ficon-ballot:before {
  content: "\f445";
}
.ficon-ballot-check:before {
  content: "\f446";
}
.ficon-ban:before {
  content: "\f447";
}
.ficon-band-aid:before {
  content: "\f448";
}
.ficon-barcode:before {
  content: "\f449";
}
.ficon-barcode-alt:before {
  content: "\f44a";
}
.ficon-barcode-read:before {
  content: "\f44b";
}
.ficon-barcode-scan:before {
  content: "\f44c";
}
.ficon-bars:before {
  content: "\f44d";
}
.ficon-baseball:before {
  content: "\f44e";
}
.ficon-baseball-ball:before {
  content: "\f44f";
}
.ficon-basketball-ball:before {
  content: "\f450";
}
.ficon-basketball-hoop:before {
  content: "\f451";
}
.ficon-bat:before {
  content: "\f452";
}
.ficon-bath:before {
  content: "\f453";
}
.ficon-battery-bolt:before {
  content: "\f454";
}
.ficon-battery-empty:before {
  content: "\f455";
}
.ficon-battery-full:before {
  content: "\f456";
}
.ficon-battery-half:before {
  content: "\f457";
}
.ficon-battery-quarter:before {
  content: "\f458";
}
.ficon-battery-slash:before {
  content: "\f459";
}
.ficon-battery-three-quarters:before {
  content: "\f45a";
}
.ficon-bed:before {
  content: "\f45b";
}
.ficon-beer:before {
  content: "\f45c";
}
.ficon-bell:before {
  content: "\f45d";
}
.ficon-bell-school:before {
  content: "\f45e";
}
.ficon-bell-school-slash:before {
  content: "\f45f";
}
.ficon-bell-slash:before {
  content: "\f460";
}
.ficon-bezier-curve:before {
  content: "\f461";
}
.ficon-bible:before {
  content: "\f462";
}
.ficon-bicycle:before {
  content: "\f463";
}
.ficon-binoculars:before {
  content: "\f464";
}
.ficon-birthday-cake:before {
  content: "\f465";
}
.ficon-blanket:before {
  content: "\f466";
}
.ficon-blender:before {
  content: "\f467";
}
.ficon-blender-phone:before {
  content: "\f468";
}
.ficon-blind:before {
  content: "\f469";
}
.ficon-bold:before {
  content: "\f46a";
}
.ficon-bolt:before {
  content: "\f46b";
}
.ficon-bomb:before {
  content: "\f46c";
}
.ficon-bone:before {
  content: "\f46d";
}
.ficon-bone-break:before {
  content: "\f46e";
}
.ficon-bong:before {
  content: "\f46f";
}
.ficon-book:before {
  content: "\f470";
}
.ficon-book-alt:before {
  content: "\f471";
}
.ficon-book-dead:before {
  content: "\f472";
}
.ficon-book-heart:before {
  content: "\f473";
}
.ficon-bookmark:before {
  content: "\f474";
}
.ficon-book-open:before {
  content: "\f475";
}
.ficon-book-reader:before {
  content: "\f476";
}
.ficon-books:before {
  content: "\f477";
}
.ficon-book-spells:before {
  content: "\f478";
}
.ficon-booth-curtain:before {
  content: "\f479";
}
.ficon-bow-arrow:before {
  content: "\f47a";
}
.ficon-bowling-ball:before {
  content: "\f47b";
}
.ficon-bowling-pins:before {
  content: "\f47c";
}
.ficon-box:before {
  content: "\f47d";
}
.ficon-box-alt:before {
  content: "\f47e";
}
.ficon-box-ballot:before {
  content: "\f47f";
}
.ficon-box-check:before {
  content: "\f480";
}
.ficon-boxes:before {
  content: "\f481";
}
.ficon-boxes-alt:before {
  content: "\f482";
}
.ficon-box-fragile:before {
  content: "\f483";
}
.ficon-box-full:before {
  content: "\f484";
}
.ficon-box-heart:before {
  content: "\f485";
}
.ficon-boxing-glove:before {
  content: "\f486";
}
.ficon-box-open:before {
  content: "\f487";
}
.ficon-box-up:before {
  content: "\f488";
}
.ficon-box-usd:before {
  content: "\f489";
}
.ficon-braille:before {
  content: "\f48a";
}
.ficon-brain:before {
  content: "\f48b";
}
.ficon-briefcase:before {
  content: "\f48c";
}
.ficon-briefcase-medical:before {
  content: "\f48d";
}
.ficon-broadcast-tower:before {
  content: "\f48e";
}
.ficon-broom:before {
  content: "\f48f";
}
.ficon-browser:before {
  content: "\f490";
}
.ficon-brush:before {
  content: "\f491";
}
.ficon-bug:before {
  content: "\f492";
}
.ficon-building:before {
  content: "\f493";
}
.ficon-bullhorn:before {
  content: "\f494";
}
.ficon-bullseye:before {
  content: "\f495";
}
.ficon-bullseye-arrow:before {
  content: "\f496";
}
.ficon-bullseye-pointer:before {
  content: "\f497";
}
.ficon-burn:before {
  content: "\f498";
}
.ficon-bus:before {
  content: "\f499";
}
.ficon-bus-alt:before {
  content: "\f49a";
}
.ficon-business-time:before {
  content: "\f49b";
}
.ficon-bus-school:before {
  content: "\f49c";
}
.ficon-cabinet-filing:before {
  content: "\f49d";
}
.ficon-calculator:before {
  content: "\f49e";
}
.ficon-calculator-alt:before {
  content: "\f49f";
}
.ficon-calendar:before {
  content: "\f4a0";
}
.ficon-calendar-alt:before {
  content: "\f4a1";
}
.ficon-calendar-check:before {
  content: "\f4a2";
}
.ficon-calendar-edit:before {
  content: "\f4a3";
}
.ficon-calendar-exclamation:before {
  content: "\f4a4";
}
.ficon-calendar-minus:before {
  content: "\f4a5";
}
.ficon-calendar-plus:before {
  content: "\f4a6";
}
.ficon-calendar-star:before {
  content: "\f4a7";
}
.ficon-calendar-times:before {
  content: "\f4a8";
}
.ficon-camera:before {
  content: "\f4a9";
}
.ficon-camera-alt:before {
  content: "\f4aa";
}
.ficon-camera-retro:before {
  content: "\f4ab";
}
.ficon-campfire:before {
  content: "\f4ac";
}
.ficon-campground:before {
  content: "\f4ad";
}
.ficon-candle-holder:before {
  content: "\f4ae";
}
.ficon-candy-corn:before {
  content: "\f4af";
}
.ficon-cannabis:before {
  content: "\f4b0";
}
.ficon-capsules:before {
  content: "\f4b1";
}
.ficon-car:before {
  content: "\f4b2";
}
.ficon-car-alt:before {
  content: "\f4b3";
}
.ficon-car-battery:before {
  content: "\f4b4";
}
.ficon-car-bump:before {
  content: "\f4b5";
}
.ficon-car-crash:before {
  content: "\f4b6";
}
.ficon-caret-circle-down:before {
  content: "\f4b7";
}
.ficon-caret-circle-left:before {
  content: "\f4b8";
}
.ficon-caret-circle-right:before {
  content: "\f4b9";
}
.ficon-caret-circle-up:before {
  content: "\f4ba";
}
.ficon-caret-down:before {
  content: "\f4bb";
}
.ficon-caret-left:before {
  content: "\f4bc";
}
.ficon-caret-right:before {
  content: "\f4bd";
}
.ficon-caret-square-down:before {
  content: "\f4be";
}
.ficon-caret-square-left:before {
  content: "\f4bf";
}
.ficon-caret-square-right:before {
  content: "\f4c0";
}
.ficon-caret-square-up:before {
  content: "\f4c1";
}
.ficon-caret-up:before {
  content: "\f4c2";
}
.ficon-car-garage:before {
  content: "\f4c3";
}
.ficon-car-mechanic:before {
  content: "\f4c4";
}
.ficon-car-side:before {
  content: "\f4c5";
}
.ficon-cart-arrow-down:before {
  content: "\f4c6";
}
.ficon-car-tilt:before {
  content: "\f4c7";
}
.ficon-cart-plus:before {
  content: "\f4c8";
}
.ficon-car-wash:before {
  content: "\f4c9";
}
.ficon-cat:before {
  content: "\f4ca";
}
.ficon-cauldron:before {
  content: "\f4cb";
}
.ficon-certificate:before {
  content: "\f4cc";
}
.ficon-chair:before {
  content: "\f4cd";
}
.ficon-chair-office:before {
  content: "\f4ce";
}
.ficon-chalkboard:before {
  content: "\f4cf";
}
.ficon-chalkboard-teacher:before {
  content: "\f4d0";
}
.ficon-charging-station:before {
  content: "\f4d1";
}
.ficon-chart-area:before {
  content: "\f4d2";
}
.ficon-chart-bar:before {
  content: "\f4d3";
}
.ficon-chart-line:before {
  content: "\f4d4";
}
.ficon-chart-line-down:before {
  content: "\f4d5";
}
.ficon-chart-pie:before {
  content: "\f4d6";
}
.ficon-chart-pie-alt:before {
  content: "\f4d7";
}
.ficon-check:before {
  content: "\f4d8";
}
.ficon-check-circle:before {
  content: "\f4d9";
}
.ficon-check-double:before {
  content: "\f4da";
}
.ficon-check-square:before {
  content: "\f4db";
}
.ficon-chess:before {
  content: "\f4dc";
}
.ficon-chess-bishop:before {
  content: "\f4dd";
}
.ficon-chess-bishop-alt:before {
  content: "\f4de";
}
.ficon-chess-board:before {
  content: "\f4df";
}
.ficon-chess-clock:before {
  content: "\f4e0";
}
.ficon-chess-clock-alt:before {
  content: "\f4e1";
}
.ficon-chess-king:before {
  content: "\f4e2";
}
.ficon-chess-king-alt:before {
  content: "\f4e3";
}
.ficon-chess-knight:before {
  content: "\f4e4";
}
.ficon-chess-knight-alt:before {
  content: "\f4e5";
}
.ficon-chess-pawn:before {
  content: "\f4e6";
}
.ficon-chess-pawn-alt:before {
  content: "\f4e7";
}
.ficon-chess-queen:before {
  content: "\f4e8";
}
.ficon-chess-queen-alt:before {
  content: "\f4e9";
}
.ficon-chess-rook:before {
  content: "\f4ea";
}
.ficon-chess-rook-alt:before {
  content: "\f4eb";
}
.ficon-chevron-circle-down:before {
  content: "\f4ec";
}
.ficon-chevron-circle-left:before {
  content: "\f4ed";
}
.ficon-chevron-circle-right:before {
  content: "\f4ee";
}
.ficon-chevron-circle-up:before {
  content: "\f4ef";
}
.ficon-chevron-double-down:before {
  content: "\f4f0";
}
.ficon-chevron-double-left:before {
  content: "\f4f1";
}
.ficon-chevron-double-right:before {
  content: "\f4f2";
}
.ficon-chevron-double-up:before {
  content: "\f4f3";
}
.ficon-chevron-down:before {
  content: "\f4f4";
}
.ficon-chevron-left:before {
  content: "\f4f5";
}
.ficon-chevron-right:before {
  content: "\f4f6";
}
.ficon-chevron-square-down:before {
  content: "\f4f7";
}
.ficon-chevron-square-left:before {
  content: "\f4f8";
}
.ficon-chevron-square-right:before {
  content: "\f4f9";
}
.ficon-chevron-square-up:before {
  content: "\f4fa";
}
.ficon-chevron-up:before {
  content: "\f4fb";
}
.ficon-child:before {
  content: "\f4fc";
}
.ficon-church:before {
  content: "\f4fd";
}
.ficon-circle:before {
  content: "\f4fe";
}
.ficon-circle-notch:before {
  content: "\f4ff";
}
.ficon-city:before {
  content: "\f500";
}
.ficon-claw-marks:before {
  content: "\f501";
}
.ficon-clipboard:before {
  content: "\f502";
}
.ficon-clipboard-check:before {
  content: "\f503";
}
.ficon-clipboard-list:before {
  content: "\f504";
}
.ficon-clipboard-list-check:before {
  content: "\f505";
}
.ficon-clipboard-prescription:before {
  content: "\f506";
}
.ficon-clock:before {
  content: "\f507";
}
.ficon-clone:before {
  content: "\f508";
}
.ficon-closed-captioning:before {
  content: "\f509";
}
.ficon-cloud:before {
  content: "\f50a";
}
.ficon-cloud-download:before {
  content: "\f50b";
}
.ficon-cloud-download-alt:before {
  content: "\f50c";
}
.ficon-cloud-drizzle:before {
  content: "\f50d";
}
.ficon-cloud-hail:before {
  content: "\f50e";
}
.ficon-cloud-hail-mixed:before {
  content: "\f50f";
}
.ficon-cloud-meatball:before {
  content: "\f510";
}
.ficon-cloud-moon:before {
  content: "\f511";
}
.ficon-cloud-moon-rain:before {
  content: "\f512";
}
.ficon-cloud-rain:before {
  content: "\f513";
}
.ficon-cloud-rainbow:before {
  content: "\f514";
}
.ficon-clouds:before {
  content: "\f515";
}
.ficon-cloud-showers:before {
  content: "\f516";
}
.ficon-cloud-showers-heavy:before {
  content: "\f517";
}
.ficon-cloud-sleet:before {
  content: "\f518";
}
.ficon-clouds-moon:before {
  content: "\f519";
}
.ficon-cloud-snow:before {
  content: "\f51a";
}
.ficon-clouds-sun:before {
  content: "\f51b";
}
.ficon-cloud-sun:before {
  content: "\f51c";
}
.ficon-cloud-sun-rain:before {
  content: "\f51d";
}
.ficon-cloud-upload:before {
  content: "\f51e";
}
.ficon-cloud-upload-alt:before {
  content: "\f51f";
}
.ficon-club:before {
  content: "\f520";
}
.ficon-cocktail:before {
  content: "\f521";
}
.ficon-code:before {
  content: "\f522";
}
.ficon-code-branch:before {
  content: "\f523";
}
.ficon-code-commit:before {
  content: "\f524";
}
.ficon-code-merge:before {
  content: "\f525";
}
.ficon-coffee:before {
  content: "\f526";
}
.ficon-coffee-togo:before {
  content: "\f527";
}
.ficon-coffin:before {
  content: "\f528";
}
.ficon-cog:before {
  content: "\f529";
}
.ficon-cogs:before {
  content: "\f52a";
}
.ficon-coins:before {
  content: "\f52b";
}
.ficon-columns:before {
  content: "\f52c";
}
.ficon-comment:before {
  content: "\f52d";
}
.ficon-comment-alt:before {
  content: "\f52e";
}
.ficon-comment-alt-check:before {
  content: "\f52f";
}
.ficon-comment-alt-dollar:before {
  content: "\f530";
}
.ficon-comment-alt-dots:before {
  content: "\f531";
}
.ficon-comment-alt-edit:before {
  content: "\f532";
}
.ficon-comment-alt-exclamation:before {
  content: "\f533";
}
.ficon-comment-alt-lines:before {
  content: "\f534";
}
.ficon-comment-alt-minus:before {
  content: "\f535";
}
.ficon-comment-alt-plus:before {
  content: "\f536";
}
.ficon-comment-alt-slash:before {
  content: "\f537";
}
.ficon-comment-alt-smile:before {
  content: "\f538";
}
.ficon-comment-alt-times:before {
  content: "\f539";
}
.ficon-comment-check:before {
  content: "\f53a";
}
.ficon-comment-dollar:before {
  content: "\f53b";
}
.ficon-comment-dots:before {
  content: "\f53c";
}
.ficon-comment-edit:before {
  content: "\f53d";
}
.ficon-comment-exclamation:before {
  content: "\f53e";
}
.ficon-comment-lines:before {
  content: "\f53f";
}
.ficon-comment-minus:before {
  content: "\f540";
}
.ficon-comment-plus:before {
  content: "\f541";
}
.ficon-comments:before {
  content: "\f542";
}
.ficon-comments-alt:before {
  content: "\f543";
}
.ficon-comments-alt-dollar:before {
  content: "\f544";
}
.ficon-comments-dollar:before {
  content: "\f545";
}
.ficon-comment-slash:before {
  content: "\f546";
}
.ficon-comment-smile:before {
  content: "\f547";
}
.ficon-comment-times:before {
  content: "\f548";
}
.ficon-compact-disc:before {
  content: "\f549";
}
.ficon-compass:before {
  content: "\f54a";
}
.ficon-compass-slash:before {
  content: "\f54b";
}
.ficon-compress:before {
  content: "\f54c";
}
.ficon-compress-alt:before {
  content: "\f54d";
}
.ficon-compress-wide:before {
  content: "\f54e";
}
.ficon-concierge-bell:before {
  content: "\f54f";
}
.ficon-container-storage:before {
  content: "\f550";
}
.ficon-conveyor-belt:before {
  content: "\f551";
}
.ficon-conveyor-belt-alt:before {
  content: "\f552";
}
.ficon-cookie:before {
  content: "\f553";
}
.ficon-cookie-bite:before {
  content: "\f554";
}
.ficon-copy:before {
  content: "\f555";
}
.ficon-copyright:before {
  content: "\f556";
}
.ficon-corn:before {
  content: "\f557";
}
.ficon-couch:before {
  content: "\f558";
}
.ficon-cow:before {
  content: "\f559";
}
.ficon-credit-card:before {
  content: "\f55a";
}
.ficon-credit-card-blank:before {
  content: "\f55b";
}
.ficon-credit-card-front:before {
  content: "\f55c";
}
.ficon-cricket:before {
  content: "\f55d";
}
.ficon-crop:before {
  content: "\f55e";
}
.ficon-crop-alt:before {
  content: "\f55f";
}
.ficon-cross:before {
  content: "\f560";
}
.ficon-crosshairs:before {
  content: "\f561";
}
.ficon-crow:before {
  content: "\f562";
}
.ficon-crown:before {
  content: "\f563";
}
.ficon-cube:before {
  content: "\f564";
}
.ficon-cubes:before {
  content: "\f565";
}
.ficon-curling:before {
  content: "\f566";
}
.ficon-cut:before {
  content: "\f567";
}
.ficon-dagger:before {
  content: "\f568";
}
.ficon-database:before {
  content: "\f569";
}
.ficon-deaf:before {
  content: "\f56a";
}
.ficon-democrat:before {
  content: "\f56b";
}
.ficon-desktop:before {
  content: "\f56c";
}
.ficon-desktop-alt:before {
  content: "\f56d";
}
.ficon-dewpoint:before {
  content: "\f56e";
}
.ficon-dharmachakra:before {
  content: "\f56f";
}
.ficon-diagnoses:before {
  content: "\f570";
}
.ficon-diamond:before {
  content: "\f571";
}
.ficon-dice:before {
  content: "\f572";
}
.ficon-dice-d4:before {
  content: "\f573";
}
.ficon-dice-d6:before {
  content: "\f574";
}
.ficon-dice-d8:before {
  content: "\f575";
}
.ficon-dice-d10:before {
  content: "\f576";
}
.ficon-dice-d12:before {
  content: "\f577";
}
.ficon-dice-d20:before {
  content: "\f578";
}
.ficon-dice-five:before {
  content: "\f579";
}
.ficon-dice-four:before {
  content: "\f57a";
}
.ficon-dice-one:before {
  content: "\f57b";
}
.ficon-dice-six:before {
  content: "\f57c";
}
.ficon-dice-three:before {
  content: "\f57d";
}
.ficon-dice-two:before {
  content: "\f57e";
}
.ficon-digital-tachograph:before {
  content: "\f57f";
}
.ficon-diploma:before {
  content: "\f580";
}
.ficon-directions:before {
  content: "\f581";
}
.ficon-divide:before {
  content: "\f582";
}
.ficon-dizzy:before {
  content: "\f583";
}
.ficon-dna:before {
  content: "\f584";
}
.ficon-dog:before {
  content: "\f585";
}
.ficon-dog-leashed:before {
  content: "\f586";
}
.ficon-dollar-sign:before {
  content: "\f587";
}
.ficon-dolly:before {
  content: "\f588";
}
.ficon-dolly-empty:before {
  content: "\f589";
}
.ficon-dolly-flatbed:before {
  content: "\f58a";
}
.ficon-dolly-flatbed-alt:before {
  content: "\f58b";
}
.ficon-dolly-flatbed-empty:before {
  content: "\f58c";
}
.ficon-donate:before {
  content: "\f58d";
}
.ficon-do-not-enter:before {
  content: "\f58e";
}
.ficon-door-closed:before {
  content: "\f58f";
}
.ficon-door-open:before {
  content: "\f590";
}
.ficon-dot-circle:before {
  content: "\f591";
}
.ficon-dove:before {
  content: "\f592";
}
.ficon-download:before {
  content: "\f593";
}
.ficon-drafting-compass:before {
  content: "\f594";
}
.ficon-dragon:before {
  content: "\f595";
}
.ficon-draw-circle:before {
  content: "\f596";
}
.ficon-draw-polygon:before {
  content: "\f597";
}
.ficon-draw-square:before {
  content: "\f598";
}
.ficon-drum:before {
  content: "\f599";
}
.ficon-drum-steelpan:before {
  content: "\f59a";
}
.ficon-drumstick:before {
  content: "\f59b";
}
.ficon-drumstick-bite:before {
  content: "\f59c";
}
.ficon-duck:before {
  content: "\f59d";
}
.ficon-dumbbell:before {
  content: "\f59e";
}
.ficon-dungeon:before {
  content: "\f59f";
}
.ficon-ear:before {
  content: "\f5a0";
}
.ficon-eclipse:before {
  content: "\f5a1";
}
.ficon-eclipse-alt:before {
  content: "\f5a2";
}
.ficon-edit:before {
  content: "\f5a3";
}
.ficon-eject:before {
  content: "\f5a4";
}
.ficon-elephant:before {
  content: "\f5a5";
}
.ficon-ellipsis-h:before {
  content: "\f5a6";
}
.ficon-ellipsis-h-alt:before {
  content: "\f5a7";
}
.ficon-ellipsis-v:before {
  content: "\f5a8";
}
.ficon-ellipsis-v-alt:before {
  content: "\f5a9";
}
.ficon-empty-set:before {
  content: "\f5aa";
}
.ficon-engine-warning:before {
  content: "\f5ab";
}
.ficon-envelope:before {
  content: "\f5ac";
}
.ficon-envelope-open:before {
  content: "\f5ad";
}
.ficon-envelope-open-dollar:before {
  content: "\f5ae";
}
.ficon-envelope-open-text:before {
  content: "\f5af";
}
.ficon-envelope-square:before {
  content: "\f5b0";
}
.ficon-equals:before {
  content: "\f5b1";
}
.ficon-eraser:before {
  content: "\f5b2";
}
.ficon-euro-sign:before {
  content: "\f5b3";
}
.ficon-exchange:before {
  content: "\f5b4";
}
.ficon-exchange-alt:before {
  content: "\f5b5";
}
.ficon-exclamation:before {
  content: "\f5b6";
}
.ficon-exclamation-circle:before {
  content: "\f5b7";
}
.ficon-exclamation-square:before {
  content: "\f5b8";
}
.ficon-exclamation-triangle:before {
  content: "\f5b9";
}
.ficon-expand:before {
  content: "\f5ba";
}
.ficon-expand-alt:before {
  content: "\f5bb";
}
.ficon-expand-arrows:before {
  content: "\f5bc";
}
.ficon-expand-arrows-alt:before {
  content: "\f5bd";
}
.ficon-expand-wide:before {
  content: "\f5be";
}
.ficon-external-link:before {
  content: "\f5bf";
}
.ficon-external-link-alt:before {
  content: "\f5c0";
}
.ficon-external-link-square:before {
  content: "\f5c1";
}
.ficon-external-link-square-alt:before {
  content: "\f5c2";
}
.ficon-eye:before {
  content: "\f5c3";
}
.ficon-eye-dropper:before {
  content: "\f5c4";
}
.ficon-eye-evil:before {
  content: "\f5c5";
}
.ficon-eye-slash:before {
  content: "\f5c6";
}
.ficon-fast-backward:before {
  content: "\f5c7";
}
.ficon-fast-forward:before {
  content: "\f5c8";
}
.ficon-fax:before {
  content: "\f5c9";
}
.ficon-feather:before {
  content: "\f5ca";
}
.ficon-feather-alt:before {
  content: "\f5cb";
}
.ficon-female:before {
  content: "\f5cc";
}
.ficon-field-hockey:before {
  content: "\f5cd";
}
.ficon-fighter-jet:before {
  content: "\f5ce";
}
.ficon-file:before {
  content: "\f5cf";
}
.ficon-file-alt:before {
  content: "\f5d0";
}
.ficon-file-archive:before {
  content: "\f5d1";
}
.ficon-file-audio:before {
  content: "\f5d2";
}
.ficon-file-certificate:before {
  content: "\f5d3";
}
.ficon-file-chart-line:before {
  content: "\f5d4";
}
.ficon-file-chart-pie:before {
  content: "\f5d5";
}
.ficon-file-check:before {
  content: "\f5d6";
}
.ficon-file-code:before {
  content: "\f5d7";
}
.ficon-file-contract:before {
  content: "\f5d8";
}
.ficon-file-csv:before {
  content: "\f5d9";
}
.ficon-file-download:before {
  content: "\f5da";
}
.ficon-file-edit:before {
  content: "\f5db";
}
.ficon-file-excel:before {
  content: "\f5dc";
}
.ficon-file-exclamation:before {
  content: "\f5dd";
}
.ficon-file-export:before {
  content: "\f5de";
}
.ficon-file-image:before {
  content: "\f5df";
}
.ficon-file-import:before {
  content: "\f5e0";
}
.ficon-file-invoice:before {
  content: "\f5e1";
}
.ficon-file-invoice-dollar:before {
  content: "\f5e2";
}
.ficon-file-medical:before {
  content: "\f5e3";
}
.ficon-file-medical-alt:before {
  content: "\f5e4";
}
.ficon-file-minus:before {
  content: "\f5e5";
}
.ficon-file-pdf:before {
  content: "\f5e6";
}
.ficon-file-plus:before {
  content: "\f5e7";
}
.ficon-file-powerpoint:before {
  content: "\f5e8";
}
.ficon-file-prescription:before {
  content: "\f5e9";
}
.ficon-file-signature:before {
  content: "\f5ea";
}
.ficon-file-spreadsheet:before {
  content: "\f5eb";
}
.ficon-file-times:before {
  content: "\f5ec";
}
.ficon-file-upload:before {
  content: "\f5ed";
}
.ficon-file-user:before {
  content: "\f5ee";
}
.ficon-file-video:before {
  content: "\f5ef";
}
.ficon-file-word:before {
  content: "\f5f0";
}
.ficon-fill:before {
  content: "\f5f1";
}
.ficon-fill-drip:before {
  content: "\f5f2";
}
.ficon-film:before {
  content: "\f5f3";
}
.ficon-film-alt:before {
  content: "\f5f4";
}
.ficon-filter:before {
  content: "\f5f5";
}
.ficon-fingerprint:before {
  content: "\f5f6";
}
.ficon-fire:before {
  content: "\f5f7";
}
.ficon-fire-extinguisher:before {
  content: "\f5f8";
}
.ficon-fire-smoke:before {
  content: "\f5f9";
}
.ficon-first-aid:before {
  content: "\f5fa";
}
.ficon-fish:before {
  content: "\f5fb";
}
.ficon-fist-raised:before {
  content: "\f5fc";
}
.ficon-flag:before {
  content: "\f5fd";
}
.ficon-flag-alt:before {
  content: "\f5fe";
}
.ficon-flag-checkered:before {
  content: "\f5ff";
}
.ficon-flag-usa:before {
  content: "\f600";
}
.ficon-flame:before {
  content: "\f601";
}
.ficon-flask:before {
  content: "\f602";
}
.ficon-flask-poison:before {
  content: "\f603";
}
.ficon-flask-potion:before {
  content: "\f604";
}
.ficon-flushed:before {
  content: "\f605";
}
.ficon-fog:before {
  content: "\f606";
}
.ficon-folder:before {
  content: "\f607";
}
.ficon-folder-minus:before {
  content: "\f608";
}
.ficon-folder-open:before {
  content: "\f609";
}
.ficon-folder-plus:before {
  content: "\f60a";
}
.ficon-folders:before {
  content: "\f60b";
}
.ficon-folder-times:before {
  content: "\f60c";
}
.ficon-font:before {
  content: "\f60d";
}
.ficon-font-awesome-logo-full1:before {
  content: "\f60e";
}
.ficon-football-ball:before {
  content: "\f60f";
}
.ficon-football-helmet:before {
  content: "\f610";
}
.ficon-forklift:before {
  content: "\f611";
}
.ficon-forward:before {
  content: "\f612";
}
.ficon-fragile:before {
  content: "\f613";
}
.ficon-frog:before {
  content: "\f614";
}
.ficon-frown:before {
  content: "\f615";
}
.ficon-frown-open:before {
  content: "\f616";
}
.ficon-function:before {
  content: "\f617";
}
.ficon-funnel-dollar:before {
  content: "\f618";
}
.ficon-futbol:before {
  content: "\f619";
}
.ficon-gamepad:before {
  content: "\f61a";
}
.ficon-gas-pump:before {
  content: "\f61b";
}
.ficon-gas-pump-slash:before {
  content: "\f61c";
}
.ficon-gavel:before {
  content: "\f61d";
}
.ficon-gem:before {
  content: "\f61e";
}
.ficon-genderless:before {
  content: "\f61f";
}
.ficon-ghost:before {
  content: "\f620";
}
.ficon-gift:before {
  content: "\f621";
}
.ficon-gift-card:before {
  content: "\f622";
}
.ficon-glasses:before {
  content: "\f623";
}
.ficon-glasses-alt:before {
  content: "\f624";
}
.ficon-glass-martini:before {
  content: "\f625";
}
.ficon-glass-martini-alt:before {
  content: "\f626";
}
.ficon-globe:before {
  content: "\f627";
}
.ficon-globe-africa:before {
  content: "\f628";
}
.ficon-globe-americas:before {
  content: "\f629";
}
.ficon-globe-asia:before {
  content: "\f62a";
}
.ficon-globe-stand:before {
  content: "\f62b";
}
.ficon-golf-ball:before {
  content: "\f62c";
}
.ficon-golf-club:before {
  content: "\f62d";
}
.ficon-gopuram:before {
  content: "\f62e";
}
.ficon-graduation-cap:before {
  content: "\f62f";
}
.ficon-greater-than:before {
  content: "\f630";
}
.ficon-greater-than-equal:before {
  content: "\f631";
}
.ficon-grimace:before {
  content: "\f632";
}
.ficon-grin:before {
  content: "\f633";
}
.ficon-grin-alt:before {
  content: "\f634";
}
.ficon-grin-beam:before {
  content: "\f635";
}
.ficon-grin-beam-sweat:before {
  content: "\f636";
}
.ficon-grin-hearts:before {
  content: "\f637";
}
.ficon-grin-squint:before {
  content: "\f638";
}
.ficon-grin-squint-tears:before {
  content: "\f639";
}
.ficon-grin-stars:before {
  content: "\f63a";
}
.ficon-grin-tears:before {
  content: "\f63b";
}
.ficon-grin-tongue:before {
  content: "\f63c";
}
.ficon-grin-tongue-squint:before {
  content: "\f63d";
}
.ficon-grin-tongue-wink:before {
  content: "\f63e";
}
.ficon-grin-wink:before {
  content: "\f63f";
}
.ficon-grip-horizontal:before {
  content: "\f640";
}
.ficon-grip-vertical:before {
  content: "\f641";
}
.ficon-h1:before {
  content: "\f642";
}
.ficon-h2:before {
  content: "\f643";
}
.ficon-h3:before {
  content: "\f644";
}
.ficon-hammer:before {
  content: "\f645";
}
.ficon-hammer-war:before {
  content: "\f646";
}
.ficon-hamsa:before {
  content: "\f647";
}
.ficon-hand-heart:before {
  content: "\f648";
}
.ficon-hand-holding:before {
  content: "\f649";
}
.ficon-hand-holding-box:before {
  content: "\f64a";
}
.ficon-hand-holding-heart:before {
  content: "\f64b";
}
.ficon-hand-holding-magic:before {
  content: "\f64c";
}
.ficon-hand-holding-seedling:before {
  content: "\f64d";
}
.ficon-hand-holding-usd:before {
  content: "\f64e";
}
.ficon-hand-holding-water:before {
  content: "\f64f";
}
.ficon-hand-lizard:before {
  content: "\f650";
}
.ficon-hand-paper:before {
  content: "\f651";
}
.ficon-hand-peace:before {
  content: "\f652";
}
.ficon-hand-point-down:before {
  content: "\f653";
}
.ficon-hand-pointer:before {
  content: "\f654";
}
.ficon-hand-point-left:before {
  content: "\f655";
}
.ficon-hand-point-right:before {
  content: "\f656";
}
.ficon-hand-point-up:before {
  content: "\f657";
}
.ficon-hand-receiving:before {
  content: "\f658";
}
.ficon-hand-rock:before {
  content: "\f659";
}
.ficon-hands:before {
  content: "\f65a";
}
.ficon-hand-scissors:before {
  content: "\f65b";
}
.ficon-handshake:before {
  content: "\f65c";
}
.ficon-handshake-alt:before {
  content: "\f65d";
}
.ficon-hands-heart:before {
  content: "\f65e";
}
.ficon-hands-helping:before {
  content: "\f65f";
}
.ficon-hand-spock:before {
  content: "\f660";
}
.ficon-hands-usd:before {
  content: "\f661";
}
.ficon-hanukiah:before {
  content: "\f662";
}
.ficon-hashtag:before {
  content: "\f663";
}
.ficon-hat-witch:before {
  content: "\f664";
}
.ficon-hat-wizard:before {
  content: "\f665";
}
.ficon-haykal:before {
  content: "\f666";
}
.ficon-hdd:before {
  content: "\f667";
}
.ficon-heading:before {
  content: "\f668";
}
.ficon-headphones:before {
  content: "\f669";
}
.ficon-headphones-alt:before {
  content: "\f66a";
}
.ficon-headset:before {
  content: "\f66b";
}
.ficon-head-side:before {
  content: "\f66c";
}
.ficon-head-vr:before {
  content: "\f66d";
}
.ficon-heart:before {
  content: "\f66e";
}
.ficon-heartbeat:before {
  content: "\f66f";
}
.ficon-heart-circle:before {
  content: "\f670";
}
.ficon-heart-rate:before {
  content: "\f671";
}
.ficon-heart-square:before {
  content: "\f672";
}
.ficon-helicopter:before {
  content: "\f673";
}
.ficon-helmet-battle:before {
  content: "\f674";
}
.ficon-hexagon:before {
  content: "\f675";
}
.ficon-highlighter:before {
  content: "\f676";
}
.ficon-hiking:before {
  content: "\f677";
}
.ficon-hippo:before {
  content: "\f678";
}
.ficon-history:before {
  content: "\f679";
}
.ficon-hockey-mask:before {
  content: "\f67a";
}
.ficon-hockey-puck:before {
  content: "\f67b";
}
.ficon-hockey-sticks:before {
  content: "\f67c";
}
.ficon-home:before {
  content: "\f67d";
}
.ficon-home-heart:before {
  content: "\f67e";
}
.ficon-hood-cloak:before {
  content: "\f67f";
}
.ficon-horse:before {
  content: "\f680";
}
.ficon-hospital:before {
  content: "\f681";
}
.ficon-hospital-alt:before {
  content: "\f682";
}
.ficon-hospital-symbol:before {
  content: "\f683";
}
.ficon-hotel:before {
  content: "\f684";
}
.ficon-hot-tub:before {
  content: "\f685";
}
.ficon-hourglass:before {
  content: "\f686";
}
.ficon-hourglass-end:before {
  content: "\f687";
}
.ficon-hourglass-half:before {
  content: "\f688";
}
.ficon-hourglass-start:before {
  content: "\f689";
}
.ficon-house-damage:before {
  content: "\f68a";
}
.ficon-house-flood:before {
  content: "\f68b";
}
.ficon-hryvnia:before {
  content: "\f68c";
}
.ficon-h-square:before {
  content: "\f68d";
}
.ficon-humidity:before {
  content: "\f68e";
}
.ficon-hurricane:before {
  content: "\f68f";
}
.ficon-i-cursor:before {
  content: "\f690";
}
.ficon-id-badge:before {
  content: "\f691";
}
.ficon-id-card:before {
  content: "\f692";
}
.ficon-id-card-alt:before {
  content: "\f693";
}
.ficon-image:before {
  content: "\f694";
}
.ficon-images:before {
  content: "\f695";
}
.ficon-inbox:before {
  content: "\f696";
}
.ficon-inbox-in:before {
  content: "\f697";
}
.ficon-inbox-out:before {
  content: "\f698";
}
.ficon-indent:before {
  content: "\f699";
}
.ficon-industry:before {
  content: "\f69a";
}
.ficon-industry-alt:before {
  content: "\f69b";
}
.ficon-infinity:before {
  content: "\f69c";
}
.ficon-info:before {
  content: "\f69d";
}
.ficon-info-circle:before {
  content: "\f69e";
}
.ficon-info-square:before {
  content: "\f69f";
}
.ficon-inhaler:before {
  content: "\f6a0";
}
.ficon-integral:before {
  content: "\f6a1";
}
.ficon-intersection:before {
  content: "\f6a2";
}
.ficon-inventory:before {
  content: "\f6a3";
}
.ficon-italic:before {
  content: "\f6a4";
}
.ficon-jack-o-lantern:before {
  content: "\f6a5";
}
.ficon-jedi:before {
  content: "\f6a6";
}
.ficon-joint:before {
  content: "\f6a7";
}
.ficon-journal-whills:before {
  content: "\f6a8";
}
.ficon-kaaba:before {
  content: "\f6a9";
}
.ficon-key:before {
  content: "\f6aa";
}
.ficon-keyboard:before {
  content: "\f6ab";
}
.ficon-keynote:before {
  content: "\f6ac";
}
.ficon-key-skeleton:before {
  content: "\f6ad";
}
.ficon-khanda:before {
  content: "\f6ae";
}
.ficon-kidneys:before {
  content: "\f6af";
}
.ficon-kiss:before {
  content: "\f6b0";
}
.ficon-kiss-beam:before {
  content: "\f6b1";
}
.ficon-kiss-wink-heart:before {
  content: "\f6b2";
}
.ficon-kite:before {
  content: "\f6b3";
}
.ficon-kiwi-bird:before {
  content: "\f6b4";
}
.ficon-knife-kitchen:before {
  content: "\f6b5";
}
.ficon-lambda:before {
  content: "\f6b6";
}
.ficon-lamp:before {
  content: "\f6b7";
}
.ficon-landmark:before {
  content: "\f6b8";
}
.ficon-landmark-alt:before {
  content: "\f6b9";
}
.ficon-language:before {
  content: "\f6ba";
}
.ficon-laptop:before {
  content: "\f6bb";
}
.ficon-laptop-code:before {
  content: "\f6bc";
}
.ficon-laugh:before {
  content: "\f6bd";
}
.ficon-laugh-beam:before {
  content: "\f6be";
}
.ficon-laugh-squint:before {
  content: "\f6bf";
}
.ficon-laugh-wink:before {
  content: "\f6c0";
}
.ficon-layer-group:before {
  content: "\f6c1";
}
.ficon-layer-minus:before {
  content: "\f6c2";
}
.ficon-layer-plus:before {
  content: "\f6c3";
}
.ficon-leaf:before {
  content: "\f6c4";
}
.ficon-leaf-heart:before {
  content: "\f6c5";
}
.ficon-leaf-maple:before {
  content: "\f6c6";
}
.ficon-leaf-oak:before {
  content: "\f6c7";
}
.ficon-lemon:before {
  content: "\f6c8";
}
.ficon-less-than:before {
  content: "\f6c9";
}
.ficon-less-than-equal:before {
  content: "\f6ca";
}
.ficon-level-down:before {
  content: "\f6cb";
}
.ficon-level-down-alt:before {
  content: "\f6cc";
}
.ficon-level-up:before {
  content: "\f6cd";
}
.ficon-level-up-alt:before {
  content: "\f6ce";
}
.ficon-life-ring:before {
  content: "\f6cf";
}
.ficon-lightbulb:before {
  content: "\f6d0";
}
.ficon-lightbulb-dollar:before {
  content: "\f6d1";
}
.ficon-lightbulb-exclamation:before {
  content: "\f6d2";
}
.ficon-lightbulb-on:before {
  content: "\f6d3";
}
.ficon-lightbulb-slash:before {
  content: "\f6d4";
}
.ficon-link:before {
  content: "\f6d5";
}
.ficon-lips:before {
  content: "\f6d6";
}
.ficon-lira-sign:before {
  content: "\f6d7";
}
.ficon-list:before {
  content: "\f6d8";
}
.ficon-list-alt:before {
  content: "\f6d9";
}
.ficon-list-ol:before {
  content: "\f6da";
}
.ficon-list-ul:before {
  content: "\f6db";
}
.ficon-location:before {
  content: "\f6dc";
}
.ficon-location-arrow:before {
  content: "\f6dd";
}
.ficon-location-circle:before {
  content: "\f6de";
}
.ficon-location-slash:before {
  content: "\f6df";
}
.ficon-lock:before {
  content: "\f6e0";
}
.ficon-lock-alt:before {
  content: "\f6e1";
}
.ficon-lock-open:before {
  content: "\f6e2";
}
.ficon-lock-open-alt:before {
  content: "\f6e3";
}
.ficon-long-arrow-alt-down:before {
  content: "\f6e4";
}
.ficon-long-arrow-alt-left:before {
  content: "\f6e5";
}
.ficon-long-arrow-alt-right:before {
  content: "\f6e6";
}
.ficon-long-arrow-alt-up:before {
  content: "\f6e7";
}
.ficon-long-arrow-down:before {
  content: "\f6e8";
}
.ficon-long-arrow-left:before {
  content: "\f6e9";
}
.ficon-long-arrow-right:before {
  content: "\f6ea";
}
.ficon-long-arrow-up:before {
  content: "\f6eb";
}
.ficon-loveseat:before {
  content: "\f6ec";
}
.ficon-low-vision:before {
  content: "\f6ed";
}
.ficon-luchador:before {
  content: "\f6ee";
}
.ficon-luggage-cart:before {
  content: "\f6ef";
}
.ficon-lungs:before {
  content: "\f6f0";
}
.ficon-mace:before {
  content: "\f6f1";
}
.ficon-magic:before {
  content: "\f6f2";
}
.ficon-magnet:before {
  content: "\f6f3";
}
.ficon-mail-bulk:before {
  content: "\f6f4";
}
.ficon-male:before {
  content: "\f6f5";
}
.ficon-mandolin:before {
  content: "\f6f6";
}
.ficon-map:before {
  content: "\f6f7";
}
.ficon-map-marked:before {
  content: "\f6f8";
}
.ficon-map-marked-alt:before {
  content: "\f6f9";
}
.ficon-map-marker:before {
  content: "\f6fa";
}
.ficon-map-marker-alt:before {
  content: "\f6fb";
}
.ficon-map-marker-alt-slash:before {
  content: "\f6fc";
}
.ficon-map-marker-check:before {
  content: "\f6fd";
}
.ficon-map-marker-edit:before {
  content: "\f6fe";
}
.ficon-map-marker-exclamation:before {
  content: "\f6ff";
}
.ficon-map-marker-minus:before {
  content: "\f700";
}
.ficon-map-marker-plus:before {
  content: "\f701";
}
.ficon-map-marker-question:before {
  content: "\f702";
}
.ficon-map-marker-slash:before {
  content: "\f703";
}
.ficon-map-marker-smile:before {
  content: "\f704";
}
.ficon-map-marker-times:before {
  content: "\f705";
}
.ficon-map-pin:before {
  content: "\f706";
}
.ficon-map-signs:before {
  content: "\f707";
}
.ficon-marker:before {
  content: "\f708";
}
.ficon-mars:before {
  content: "\f709";
}
.ficon-mars-double:before {
  content: "\f70a";
}
.ficon-mars-stroke:before {
  content: "\f70b";
}
.ficon-mars-stroke-h:before {
  content: "\f70c";
}
.ficon-mars-stroke-v:before {
  content: "\f70d";
}
.ficon-mask:before {
  content: "\f70e";
}
.ficon-medal:before {
  content: "\f70f";
}
.ficon-medkit:before {
  content: "\f710";
}
.ficon-megaphone:before {
  content: "\f711";
}
.ficon-meh:before {
  content: "\f712";
}
.ficon-meh-blank:before {
  content: "\f713";
}
.ficon-meh-rolling-eyes:before {
  content: "\f714";
}
.ficon-memory:before {
  content: "\f715";
}
.ficon-menorah:before {
  content: "\f716";
}
.ficon-mercury:before {
  content: "\f717";
}
.ficon-meteor:before {
  content: "\f718";
}
.ficon-microchip:before {
  content: "\f719";
}
.ficon-microphone:before {
  content: "\f71a";
}
.ficon-microphone-alt:before {
  content: "\f71b";
}
.ficon-microphone-alt-slash:before {
  content: "\f71c";
}
.ficon-microphone-slash:before {
  content: "\f71d";
}
.ficon-microscope:before {
  content: "\f71e";
}
.ficon-mind-share:before {
  content: "\f71f";
}
.ficon-minus:before {
  content: "\f720";
}
.ficon-minus-circle:before {
  content: "\f721";
}
.ficon-minus-hexagon:before {
  content: "\f722";
}
.ficon-minus-octagon:before {
  content: "\f723";
}
.ficon-minus-square:before {
  content: "\f724";
}
.ficon-mobile:before {
  content: "\f725";
}
.ficon-mobile-alt:before {
  content: "\f726";
}
.ficon-mobile-android:before {
  content: "\f727";
}
.ficon-mobile-android-alt:before {
  content: "\f728";
}
.ficon-money-bill:before {
  content: "\f729";
}
.ficon-money-bill-alt:before {
  content: "\f72a";
}
.ficon-money-bill-wave:before {
  content: "\f72b";
}
.ficon-money-bill-wave-alt:before {
  content: "\f72c";
}
.ficon-money-check:before {
  content: "\f72d";
}
.ficon-money-check-alt:before {
  content: "\f72e";
}
.ficon-monitor-heart-rate:before {
  content: "\f72f";
}
.ficon-monkey:before {
  content: "\f730";
}
.ficon-monument:before {
  content: "\f731";
}
.ficon-moon:before {
  content: "\f732";
}
.ficon-moon-cloud:before {
  content: "\f733";
}
.ficon-moon-stars:before {
  content: "\f734";
}
.ficon-mortar-pestle:before {
  content: "\f735";
}
.ficon-mosque:before {
  content: "\f736";
}
.ficon-motorcycle:before {
  content: "\f737";
}
.ficon-mountain:before {
  content: "\f738";
}
.ficon-mountains:before {
  content: "\f739";
}
.ficon-mouse-pointer:before {
  content: "\f73a";
}
.ficon-music:before {
  content: "\f73b";
}
.ficon-narwhal:before {
  content: "\f73c";
}
.ficon-network-wired:before {
  content: "\f73d";
}
.ficon-neuter:before {
  content: "\f73e";
}
.ficon-newspaper:before {
  content: "\f73f";
}
.ficon-not-equal:before {
  content: "\f740";
}
.ficon-notes-medical:before {
  content: "\f741";
}
.ficon-object-group:before {
  content: "\f742";
}
.ficon-object-ungroup:before {
  content: "\f743";
}
.ficon-octagon:before {
  content: "\f744";
}
.ficon-oil-can:before {
  content: "\f745";
}
.ficon-oil-temp:before {
  content: "\f746";
}
.ficon-om:before {
  content: "\f747";
}
.ficon-omega:before {
  content: "\f748";
}
.ficon-otter:before {
  content: "\f749";
}
.ficon-outdent:before {
  content: "\f74a";
}
.ficon-paint-brush:before {
  content: "\f74b";
}
.ficon-paint-brush-alt:before {
  content: "\f74c";
}
.ficon-paint-roller:before {
  content: "\f74d";
}
.ficon-palette:before {
  content: "\f74e";
}
.ficon-pallet:before {
  content: "\f74f";
}
.ficon-pallet-alt:before {
  content: "\f750";
}
.ficon-paperclip:before {
  content: "\f751";
}
.ficon-paper-plane:before {
  content: "\f752";
}
.ficon-parachute-box:before {
  content: "\f753";
}
.ficon-paragraph:before {
  content: "\f754";
}
.ficon-parking:before {
  content: "\f755";
}
.ficon-parking-circle:before {
  content: "\f756";
}
.ficon-parking-circle-slash:before {
  content: "\f757";
}
.ficon-parking-slash:before {
  content: "\f758";
}
.ficon-passport:before {
  content: "\f759";
}
.ficon-pastafarianism:before {
  content: "\f75a";
}
.ficon-paste:before {
  content: "\f75b";
}
.ficon-pause:before {
  content: "\f75c";
}
.ficon-pause-circle:before {
  content: "\f75d";
}
.ficon-paw:before {
  content: "\f75e";
}
.ficon-paw-alt:before {
  content: "\f75f";
}
.ficon-paw-claws:before {
  content: "\f760";
}
.ficon-peace:before {
  content: "\f761";
}
.ficon-pegasus:before {
  content: "\f762";
}
.ficon-pen:before {
  content: "\f763";
}
.ficon-pen-alt:before {
  content: "\f764";
}
.ficon-pencil:before {
  content: "\f765";
}
.ficon-pencil-alt:before {
  content: "\f766";
}
.ficon-pencil-paintbrush:before {
  content: "\f767";
}
.ficon-pencil-ruler:before {
  content: "\f768";
}
.ficon-pen-fancy:before {
  content: "\f769";
}
.ficon-pennant:before {
  content: "\f76a";
}
.ficon-pen-nib:before {
  content: "\f76b";
}
.ficon-pen-square:before {
  content: "\f76c";
}
.ficon-people-carry:before {
  content: "\f76d";
}
.ficon-percent:before {
  content: "\f76e";
}
.ficon-percentage:before {
  content: "\f76f";
}
.ficon-person-booth:before {
  content: "\f770";
}
.ficon-person-carry:before {
  content: "\f771";
}
.ficon-person-dolly:before {
  content: "\f772";
}
.ficon-person-dolly-empty:before {
  content: "\f773";
}
.ficon-person-sign:before {
  content: "\f774";
}
.ficon-phone:before {
  content: "\f775";
}
.ficon-phone-office:before {
  content: "\f776";
}
.ficon-phone-plus:before {
  content: "\f777";
}
.ficon-phone-slash:before {
  content: "\f778";
}
.ficon-phone-square:before {
  content: "\f779";
}
.ficon-phone-volume:before {
  content: "\f77a";
}
.ficon-pi:before {
  content: "\f77b";
}
.ficon-pie:before {
  content: "\f77c";
}
.ficon-pig:before {
  content: "\f77d";
}
.ficon-piggy-bank:before {
  content: "\f77e";
}
.ficon-pills:before {
  content: "\f77f";
}
.ficon-place-of-worship:before {
  content: "\f780";
}
.ficon-plane:before {
  content: "\f781";
}
.ficon-plane-alt:before {
  content: "\f782";
}
.ficon-plane-arrival:before {
  content: "\f783";
}
.ficon-plane-departure:before {
  content: "\f784";
}
.ficon-play:before {
  content: "\f785";
}
.ficon-play-circle:before {
  content: "\f786";
}
.ficon-plug:before {
  content: "\f787";
}
.ficon-plus:before {
  content: "\f788";
}
.ficon-plus-circle:before {
  content: "\f789";
}
.ficon-plus-hexagon:before {
  content: "\f78a";
}
.ficon-plus-octagon:before {
  content: "\f78b";
}
.ficon-plus-square:before {
  content: "\f78c";
}
.ficon-podcast:before {
  content: "\f78d";
}
.ficon-podium:before {
  content: "\f78e";
}
.ficon-podium-star:before {
  content: "\f78f";
}
.ficon-poll:before {
  content: "\f790";
}
.ficon-poll-h:before {
  content: "\f791";
}
.ficon-poll-people:before {
  content: "\f792";
}
.ficon-poo:before {
  content: "\f793";
}
.ficon-poop:before {
  content: "\f794";
}
.ficon-poo-storm:before {
  content: "\f795";
}
.ficon-portrait:before {
  content: "\f796";
}
.ficon-pound-sign:before {
  content: "\f797";
}
.ficon-power-off:before {
  content: "\f798";
}
.ficon-pray:before {
  content: "\f799";
}
.ficon-praying-hands:before {
  content: "\f79a";
}
.ficon-prescription:before {
  content: "\f79b";
}
.ficon-prescription-bottle:before {
  content: "\f79c";
}
.ficon-prescription-bottle-alt:before {
  content: "\f79d";
}
.ficon-presentation:before {
  content: "\f79e";
}
.ficon-print:before {
  content: "\f79f";
}
.ficon-print-slash:before {
  content: "\f7a0";
}
.ficon-procedures:before {
  content: "\f7a1";
}
.ficon-project-diagram:before {
  content: "\f7a2";
}
.ficon-pumpkin:before {
  content: "\f7a3";
}
.ficon-puzzle-piece:before {
  content: "\f7a4";
}
.ficon-qrcode:before {
  content: "\f7a5";
}
.ficon-question:before {
  content: "\f7a6";
}
.ficon-question-circle:before {
  content: "\f7a7";
}
.ficon-question-square:before {
  content: "\f7a8";
}
.ficon-quidditch:before {
  content: "\f7a9";
}
.ficon-quote-left:before {
  content: "\f7aa";
}
.ficon-quote-right:before {
  content: "\f7ab";
}
.ficon-quran:before {
  content: "\f7ac";
}
.ficon-rabbit:before {
  content: "\f7ad";
}
.ficon-rabbit-fast:before {
  content: "\f7ae";
}
.ficon-racquet:before {
  content: "\f7af";
}
.ficon-rainbow:before {
  content: "\f7b0";
}
.ficon-raindrops:before {
  content: "\f7b1";
}
.ficon-ram:before {
  content: "\f7b2";
}
.ficon-ramp-loading:before {
  content: "\f7b3";
}
.ficon-random:before {
  content: "\f7b4";
}
.ficon-receipt:before {
  content: "\f7b5";
}
.ficon-rectangle-landscape:before {
  content: "\f7b6";
}
.ficon-rectangle-portrait:before {
  content: "\f7b7";
}
.ficon-rectangle-wide:before {
  content: "\f7b8";
}
.ficon-recycle:before {
  content: "\f7b9";
}
.ficon-redo:before {
  content: "\f7ba";
}
.ficon-redo-alt:before {
  content: "\f7bb";
}
.ficon-registered:before {
  content: "\f7bc";
}
.ficon-repeat:before {
  content: "\f7bd";
}
.ficon-repeat-1:before {
  content: "\f7be";
}
.ficon-repeat-1-alt:before {
  content: "\f7bf";
}
.ficon-repeat-alt:before {
  content: "\f7c0";
}
.ficon-reply:before {
  content: "\f7c1";
}
.ficon-reply-all:before {
  content: "\f7c2";
}
.ficon-republican:before {
  content: "\f7c3";
}
.ficon-retweet:before {
  content: "\f7c4";
}
.ficon-retweet-alt:before {
  content: "\f7c5";
}
.ficon-ribbon:before {
  content: "\f7c6";
}
.ficon-ring:before {
  content: "\f7c7";
}
.ficon-road:before {
  content: "\f7c8";
}
.ficon-robot:before {
  content: "\f7c9";
}
.ficon-rocket:before {
  content: "\f7ca";
}
.ficon-route:before {
  content: "\f7cb";
}
.ficon-route-highway:before {
  content: "\f7cc";
}
.ficon-route-interstate:before {
  content: "\f7cd";
}
.ficon-rss:before {
  content: "\f7ce";
}
.ficon-rss-square:before {
  content: "\f7cf";
}
.ficon-ruble-sign:before {
  content: "\f7d0";
}
.ficon-ruler:before {
  content: "\f7d1";
}
.ficon-ruler-combined:before {
  content: "\f7d2";
}
.ficon-ruler-horizontal:before {
  content: "\f7d3";
}
.ficon-ruler-triangle:before {
  content: "\f7d4";
}
.ficon-ruler-vertical:before {
  content: "\f7d5";
}
.ficon-running:before {
  content: "\f7d6";
}
.ficon-rupee-sign:before {
  content: "\f7d7";
}
.ficon-sad-cry:before {
  content: "\f7d8";
}
.ficon-sad-tear:before {
  content: "\f7d9";
}
.ficon-save:before {
  content: "\f7da";
}
.ficon-scalpel:before {
  content: "\f7db";
}
.ficon-scalpel-path:before {
  content: "\f7dc";
}
.ficon-scanner:before {
  content: "\f7dd";
}
.ficon-scanner-keyboard:before {
  content: "\f7de";
}
.ficon-scanner-touchscreen:before {
  content: "\f7df";
}
.ficon-scarecrow:before {
  content: "\f7e0";
}
.ficon-school:before {
  content: "\f7e1";
}
.ficon-screwdriver:before {
  content: "\f7e2";
}
.ficon-scroll:before {
  content: "\f7e3";
}
.ficon-scroll-old:before {
  content: "\f7e4";
}
.ficon-scrubber:before {
  content: "\f7e5";
}
.ficon-scythe:before {
  content: "\f7e6";
}
.ficon-search:before {
  content: "\f7e7";
}
.ficon-search-dollar:before {
  content: "\f7e8";
}
.ficon-search-location:before {
  content: "\f7e9";
}
.ficon-search-minus:before {
  content: "\f7ea";
}
.ficon-search-plus:before {
  content: "\f7eb";
}
.ficon-seedling:before {
  content: "\f7ec";
}
.ficon-server:before {
  content: "\f7ed";
}
.ficon-shapes:before {
  content: "\f7ee";
}
.ficon-share:before {
  content: "\f7ef";
}
.ficon-share-all:before {
  content: "\f7f0";
}
.ficon-share-alt:before {
  content: "\f7f1";
}
.ficon-share-alt-square:before {
  content: "\f7f2";
}
.ficon-share-square:before {
  content: "\f7f3";
}
.ficon-sheep:before {
  content: "\f7f4";
}
.ficon-shekel-sign:before {
  content: "\f7f5";
}
.ficon-shield:before {
  content: "\f7f6";
}
.ficon-shield-alt:before {
  content: "\f7f7";
}
.ficon-shield-check:before {
  content: "\f7f8";
}
.ficon-shield-cross:before {
  content: "\f7f9";
}
.ficon-ship:before {
  content: "\f7fa";
}
.ficon-shipping-fast:before {
  content: "\f7fb";
}
.ficon-shipping-timed:before {
  content: "\f7fc";
}
.ficon-shoe-prints:before {
  content: "\f7fd";
}
.ficon-shopping-bag:before {
  content: "\f7fe";
}
.ficon-shopping-basket:before {
  content: "\f7ff";
}
.ficon-shopping-cart:before {
  content: "\f800";
}
.ficon-shovel:before {
  content: "\f801";
}
.ficon-shower:before {
  content: "\f802";
}
.ficon-shredder:before {
  content: "\f803";
}
.ficon-shuttlecock:before {
  content: "\f804";
}
.ficon-shuttle-van:before {
  content: "\f805";
}
.ficon-sigma:before {
  content: "\f806";
}
.ficon-sign:before {
  content: "\f807";
}
.ficon-signal:before {
  content: "\f808";
}
.ficon-signal-1:before {
  content: "\f809";
}
.ficon-signal-2:before {
  content: "\f80a";
}
.ficon-signal-3:before {
  content: "\f80b";
}
.ficon-signal-4:before {
  content: "\f80c";
}
.ficon-signal-alt:before {
  content: "\f80d";
}
.ficon-signal-alt-1:before {
  content: "\f80e";
}
.ficon-signal-alt-2:before {
  content: "\f80f";
}
.ficon-signal-alt-3:before {
  content: "\f810";
}
.ficon-signal-alt-slash:before {
  content: "\f811";
}
.ficon-signal-slash:before {
  content: "\f812";
}
.ficon-signature:before {
  content: "\f813";
}
.ficon-sign-in:before {
  content: "\f814";
}
.ficon-sign-in-alt:before {
  content: "\f815";
}
.ficon-sign-language:before {
  content: "\f816";
}
.ficon-sign-out:before {
  content: "\f817";
}
.ficon-sign-out-alt:before {
  content: "\f818";
}
.ficon-sitemap:before {
  content: "\f819";
}
.ficon-skeleton:before {
  content: "\f81a";
}
.ficon-skull:before {
  content: "\f81b";
}
.ficon-skull-crossbones:before {
  content: "\f81c";
}
.ficon-slash:before {
  content: "\f81d";
}
.ficon-sliders-h:before {
  content: "\f81e";
}
.ficon-sliders-h-square:before {
  content: "\f81f";
}
.ficon-sliders-v:before {
  content: "\f820";
}
.ficon-sliders-v-square:before {
  content: "\f821";
}
.ficon-smile:before {
  content: "\f822";
}
.ficon-smile-beam:before {
  content: "\f823";
}
.ficon-smile-plus:before {
  content: "\f824";
}
.ficon-smile-wink:before {
  content: "\f825";
}
.ficon-smog:before {
  content: "\f826";
}
.ficon-smoke:before {
  content: "\f827";
}
.ficon-smoking:before {
  content: "\f828";
}
.ficon-smoking-ban:before {
  content: "\f829";
}
.ficon-snake:before {
  content: "\f82a";
}
.ficon-snow-blowing:before {
  content: "\f82b";
}
.ficon-snowflake:before {
  content: "\f82c";
}
.ficon-socks:before {
  content: "\f82d";
}
.ficon-solar-panel:before {
  content: "\f82e";
}
.ficon-sort:before {
  content: "\f82f";
}
.ficon-sort-alpha-down:before {
  content: "\f830";
}
.ficon-sort-alpha-up:before {
  content: "\f831";
}
.ficon-sort-amount-down:before {
  content: "\f832";
}
.ficon-sort-amount-up:before {
  content: "\f833";
}
.ficon-sort-down:before {
  content: "\f834";
}
.ficon-sort-numeric-down:before {
  content: "\f835";
}
.ficon-sort-numeric-up:before {
  content: "\f836";
}
.ficon-sort-up:before {
  content: "\f837";
}
.ficon-spa:before {
  content: "\f838";
}
.ficon-space-shuttle:before {
  content: "\f839";
}
.ficon-spade:before {
  content: "\f83a";
}
.ficon-spider:before {
  content: "\f83b";
}
.ficon-spider-black-widow:before {
  content: "\f83c";
}
.ficon-spider-web:before {
  content: "\f83d";
}
.ficon-spinner:before {
  content: "\f83e";
}
.ficon-spinner-third:before {
  content: "\f83f";
}
.ficon-splotch:before {
  content: "\f840";
}
.ficon-spray-can:before {
  content: "\f841";
}
.ficon-square:before {
  content: "\f842";
}
.ficon-square-full:before {
  content: "\f843";
}
.ficon-square-root:before {
  content: "\f844";
}
.ficon-square-root-alt:before {
  content: "\f845";
}
.ficon-squirrel:before {
  content: "\f846";
}
.ficon-staff:before {
  content: "\f847";
}
.ficon-stamp:before {
  content: "\f848";
}
.ficon-star:before {
  content: "\f849";
}
.ficon-star-and-crescent:before {
  content: "\f84a";
}
.ficon-star-exclamation:before {
  content: "\f84b";
}
.ficon-star-half:before {
  content: "\f84c";
}
.ficon-star-half-alt:before {
  content: "\f84d";
}
.ficon-star-of-david:before {
  content: "\f84e";
}
.ficon-star-of-life:before {
  content: "\f84f";
}
.ficon-stars:before {
  content: "\f850";
}
.ficon-steering-wheel:before {
  content: "\f851";
}
.ficon-step-backward:before {
  content: "\f852";
}
.ficon-step-forward:before {
  content: "\f853";
}
.ficon-stethoscope:before {
  content: "\f854";
}
.ficon-sticky-note:before {
  content: "\f855";
}
.ficon-stomach:before {
  content: "\f856";
}
.ficon-stop:before {
  content: "\f857";
}
.ficon-stop-circle:before {
  content: "\f858";
}
.ficon-stopwatch:before {
  content: "\f859";
}
.ficon-store:before {
  content: "\f85a";
}
.ficon-store-alt:before {
  content: "\f85b";
}
.ficon-stream:before {
  content: "\f85c";
}
.ficon-street-view:before {
  content: "\f85d";
}
.ficon-strikethrough:before {
  content: "\f85e";
}
.ficon-stroopwafel:before {
  content: "\f85f";
}
.ficon-subscript:before {
  content: "\f860";
}
.ficon-subway:before {
  content: "\f861";
}
.ficon-suitcase:before {
  content: "\f862";
}
.ficon-suitcase-rolling:before {
  content: "\f863";
}
.ficon-sun:before {
  content: "\f864";
}
.ficon-sun-cloud:before {
  content: "\f865";
}
.ficon-sun-dust:before {
  content: "\f866";
}
.ficon-sun-haze:before {
  content: "\f867";
}
.ficon-sunrise:before {
  content: "\f868";
}
.ficon-sunset:before {
  content: "\f869";
}
.ficon-superscript:before {
  content: "\f86a";
}
.ficon-surprise:before {
  content: "\f86b";
}
.ficon-swatchbook:before {
  content: "\f86c";
}
.ficon-swimmer:before {
  content: "\f86d";
}
.ficon-swimming-pool:before {
  content: "\f86e";
}
.ficon-sword:before {
  content: "\f86f";
}
.ficon-swords:before {
  content: "\f870";
}
.ficon-synagogue:before {
  content: "\f871";
}
.ficon-sync:before {
  content: "\f872";
}
.ficon-sync-alt:before {
  content: "\f873";
}
.ficon-syringe:before {
  content: "\f874";
}
.ficon-table:before {
  content: "\f875";
}
.ficon-tablet:before {
  content: "\f876";
}
.ficon-tablet-alt:before {
  content: "\f877";
}
.ficon-tablet-android:before {
  content: "\f878";
}
.ficon-tablet-android-alt:before {
  content: "\f879";
}
.ficon-table-tennis:before {
  content: "\f87a";
}
.ficon-tablet-rugged:before {
  content: "\f87b";
}
.ficon-tablets:before {
  content: "\f87c";
}
.ficon-tachometer:before {
  content: "\f87d";
}
.ficon-tachometer-alt:before {
  content: "\f87e";
}
.ficon-tachometer-alt-average:before {
  content: "\f87f";
}
.ficon-tachometer-alt-fast:before {
  content: "\f880";
}
.ficon-tachometer-alt-fastest:before {
  content: "\f881";
}
.ficon-tachometer-alt-slow:before {
  content: "\f882";
}
.ficon-tachometer-alt-slowest:before {
  content: "\f883";
}
.ficon-tachometer-average:before {
  content: "\f884";
}
.ficon-tachometer-fast:before {
  content: "\f885";
}
.ficon-tachometer-fastest:before {
  content: "\f886";
}
.ficon-tachometer-slow:before {
  content: "\f887";
}
.ficon-tachometer-slowest:before {
  content: "\f888";
}
.ficon-tag:before {
  content: "\f889";
}
.ficon-tags:before {
  content: "\f88a";
}
.ficon-tally:before {
  content: "\f88b";
}
.ficon-tape:before {
  content: "\f88c";
}
.ficon-tasks:before {
  content: "\f88d";
}
.ficon-taxi:before {
  content: "\f88e";
}
.ficon-teeth:before {
  content: "\f88f";
}
.ficon-teeth-open:before {
  content: "\f890";
}
.ficon-temperature-frigid:before {
  content: "\f891";
}
.ficon-temperature-high:before {
  content: "\f892";
}
.ficon-temperature-hot:before {
  content: "\f893";
}
.ficon-temperature-low:before {
  content: "\f894";
}
.ficon-tennis-ball:before {
  content: "\f895";
}
.ficon-terminal:before {
  content: "\f896";
}
.ficon-text-height:before {
  content: "\f897";
}
.ficon-text-width:before {
  content: "\f898";
}
.ficon-th:before {
  content: "\f899";
}
.ficon-theater-masks:before {
  content: "\f89a";
}
.ficon-thermometer:before {
  content: "\f89b";
}
.ficon-thermometer-empty:before {
  content: "\f89c";
}
.ficon-thermometer-full:before {
  content: "\f89d";
}
.ficon-thermometer-half:before {
  content: "\f89e";
}
.ficon-thermometer-quarter:before {
  content: "\f89f";
}
.ficon-thermometer-three-quarters:before {
  content: "\f8a0";
}
.ficon-theta:before {
  content: "\f8a1";
}
.ficon-th-large:before {
  content: "\f8a2";
}
.ficon-th-list:before {
  content: "\f8a3";
}
.ficon-thumbs-down:before {
  content: "\f8a4";
}
.ficon-thumbs-up:before {
  content: "\f8a5";
}
.ficon-thumbtack:before {
  content: "\f8a6";
}
.ficon-thunderstorm:before {
  content: "\f8a7";
}
.ficon-thunderstorm-moon:before {
  content: "\f8a8";
}
.ficon-thunderstorm-sun:before {
  content: "\f8a9";
}
.ficon-ticket:before {
  content: "\f8aa";
}
.ficon-ticket-alt:before {
  content: "\f8ab";
}
.ficon-tilde:before {
  content: "\f8ac";
}
.ficon-times:before {
  content: "\f8ad";
}
.ficon-times-circle:before {
  content: "\f8ae";
}
.ficon-times-hexagon:before {
  content: "\f8af";
}
.ficon-times-octagon:before {
  content: "\f8b0";
}
.ficon-times-square:before {
  content: "\f8b1";
}
.ficon-tint:before {
  content: "\f8b2";
}
.ficon-tint-slash:before {
  content: "\f8b3";
}
.ficon-tire:before {
  content: "\f8b4";
}
.ficon-tired:before {
  content: "\f8b5";
}
.ficon-tire-flat:before {
  content: "\f8b6";
}
.ficon-tire-pressure-warning:before {
  content: "\f8b7";
}
.ficon-tire-rugged:before {
  content: "\f8b8";
}
.ficon-toggle-off:before {
  content: "\f8b9";
}
.ficon-toggle-on:before {
  content: "\f8ba";
}
.ficon-toilet-paper:before {
  content: "\f8bb";
}
.ficon-toilet-paper-alt:before {
  content: "\f8bc";
}
.ficon-tombstone:before {
  content: "\f8bd";
}
.ficon-tombstone-alt:before {
  content: "\f8be";
}
.ficon-toolbox:before {
  content: "\f8bf";
}
.ficon-tooth:before {
  content: "\f8c0";
}
.ficon-toothbrush:before {
  content: "\f8c1";
}
.ficon-torah:before {
  content: "\f8c2";
}
.ficon-torii-gate:before {
  content: "\f8c3";
}
.ficon-tornado:before {
  content: "\f8c4";
}
.ficon-tractor:before {
  content: "\f8c5";
}
.ficon-trademark:before {
  content: "\f8c6";
}
.ficon-traffic-cone:before {
  content: "\f8c7";
}
.ficon-traffic-light:before {
  content: "\f8c8";
}
.ficon-traffic-light-go:before {
  content: "\f8c9";
}
.ficon-traffic-light-slow:before {
  content: "\f8ca";
}
.ficon-traffic-light-stop:before {
  content: "\f8cb";
}
.ficon-train:before {
  content: "\f8cc";
}
.ficon-transgender:before {
  content: "\f8cd";
}
.ficon-transgender-alt:before {
  content: "\f8ce";
}
.ficon-trash:before {
  content: "\f8cf";
}
.ficon-trash-alt:before {
  content: "\f8d0";
}
.ficon-treasure-chest:before {
  content: "\f8d1";
}
.ficon-tree:before {
  content: "\f8d2";
}
.ficon-tree-alt:before {
  content: "\f8d3";
}
.ficon-trees:before {
  content: "\f8d4";
}
.ficon-triangle:before {
  content: "\f8d5";
}
.ficon-trophy:before {
  content: "\f8d6";
}
.ficon-trophy-alt:before {
  content: "\f8d7";
}
.ficon-truck:before {
  content: "\f8d8";
}
.ficon-truck-container:before {
  content: "\f8d9";
}
.ficon-truck-couch:before {
  content: "\f8da";
}
.ficon-truck-loading:before {
  content: "\f8db";
}
.ficon-truck-monster:before {
  content: "\f8dc";
}
.ficon-truck-moving:before {
  content: "\f8dd";
}
.ficon-truck-pickup:before {
  content: "\f8de";
}
.ficon-truck-ramp:before {
  content: "\f8df";
}
.ficon-tshirt:before {
  content: "\f8e0";
}
.ficon-tty:before {
  content: "\f8e1";
}
.ficon-turkey:before {
  content: "\f8e2";
}
.ficon-turtle:before {
  content: "\f8e3";
}
.ficon-tv:before {
  content: "\f8e4";
}
.ficon-tv-retro:before {
  content: "\f8e5";
}
.ficon-umbrella:before {
  content: "\f8e6";
}
.ficon-umbrella-beach:before {
  content: "\f8e7";
}
.ficon-underline:before {
  content: "\f8e8";
}
.ficon-undo:before {
  content: "\f8e9";
}
.ficon-undo-alt:before {
  content: "\f8ea";
}
.ficon-unicorn:before {
  content: "\f8eb";
}
.ficon-union:before {
  content: "\f8ec";
}
.ficon-universal-access:before {
  content: "\f8ed";
}
.ficon-university:before {
  content: "\f8ee";
}
.ficon-unlink:before {
  content: "\f8ef";
}
.ficon-unlock:before {
  content: "\f8f0";
}
.ficon-unlock-alt:before {
  content: "\f8f1";
}
.ficon-upload:before {
  content: "\f8f2";
}
.ficon-usd-circle:before {
  content: "\f8f3";
}
.ficon-usd-square:before {
  content: "\f8f4";
}
.ficon-user:before {
  content: "\f8f5";
}
.ficon-user-alt:before {
  content: "\f8f6";
}
.ficon-user-alt-slash:before {
  content: "\f8f7";
}
.ficon-user-astronaut:before {
  content: "\f8f8";
}
.ficon-user-chart:before {
  content: "\f8f9";
}
.ficon-user-check:before {
  content: "\f8fa";
}
.ficon-user-circle:before {
  content: "\f8fb";
}
.ficon-user-clock:before {
  content: "\f8fc";
}
.ficon-user-cog:before {
  content: "\f8fd";
}
.ficon-user-crown:before {
  content: "\f8fe";
}
.ficon-user-edit:before {
  content: "\f8ff";
}
.ficon-user-friends:before {
  content: "\f900";
}
.ficon-user-graduate:before {
  content: "\f901";
}
.ficon-user-injured:before {
  content: "\f902";
}
.ficon-user-lock:before {
  content: "\f903";
}
.ficon-user-md:before {
  content: "\f904";
}
.ficon-user-minus:before {
  content: "\f905";
}
.ficon-user-ninja:before {
  content: "\f906";
}
.ficon-user-plus:before {
  content: "\f907";
}
.ficon-users:before {
  content: "\f908";
}
.ficon-users-class:before {
  content: "\f909";
}
.ficon-users-cog:before {
  content: "\f90a";
}
.ficon-users-crown:before {
  content: "\f90b";
}
.ficon-user-secret:before {
  content: "\f90c";
}
.ficon-user-shield:before {
  content: "\f90d";
}
.ficon-user-slash:before {
  content: "\f90e";
}
.ficon-user-tag:before {
  content: "\f90f";
}
.ficon-user-tie:before {
  content: "\f910";
}
.ficon-user-times:before {
  content: "\f911";
}
.ficon-utensil-fork:before {
  content: "\f912";
}
.ficon-utensil-knife:before {
  content: "\f913";
}
.ficon-utensils:before {
  content: "\f914";
}
.ficon-utensils-alt:before {
  content: "\f915";
}
.ficon-utensil-spoon:before {
  content: "\f916";
}
.ficon-value-absolute:before {
  content: "\f917";
}
.ficon-vector-square:before {
  content: "\f918";
}
.ficon-venus:before {
  content: "\f919";
}
.ficon-venus-double:before {
  content: "\f91a";
}
.ficon-venus-mars:before {
  content: "\f91b";
}
.ficon-vial:before {
  content: "\f91c";
}
.ficon-vials:before {
  content: "\f91d";
}
.ficon-video:before {
  content: "\f91e";
}
.ficon-video-plus:before {
  content: "\f91f";
}
.ficon-video-slash:before {
  content: "\f920";
}
.ficon-vihara:before {
  content: "\f921";
}
.ficon-volcano:before {
  content: "\f922";
}
.ficon-volleyball-ball:before {
  content: "\f923";
}
.ficon-volume:before {
  content: "\f924";
}
.ficon-volume-down:before {
  content: "\f925";
}
.ficon-volume-mute:before {
  content: "\f926";
}
.ficon-volume-off:before {
  content: "\f927";
}
.ficon-volume-slash:before {
  content: "\f928";
}
.ficon-volume-up:before {
  content: "\f929";
}
.ficon-vote-nay:before {
  content: "\f92a";
}
.ficon-vote-yea:before {
  content: "\f92b";
}
.ficon-vr-cardboard:before {
  content: "\f92c";
}
.ficon-walking:before {
  content: "\f92d";
}
.ficon-wallet:before {
  content: "\f92e";
}
.ficon-wand:before {
  content: "\f92f";
}
.ficon-wand-magic:before {
  content: "\f930";
}
.ficon-warehouse:before {
  content: "\f931";
}
.ficon-warehouse-alt:before {
  content: "\f932";
}
.ficon-watch:before {
  content: "\f933";
}
.ficon-watch-fitness:before {
  content: "\f934";
}
.ficon-water:before {
  content: "\f935";
}
.ficon-water-lower:before {
  content: "\f936";
}
.ficon-water-rise:before {
  content: "\f937";
}
.ficon-weight:before {
  content: "\f938";
}
.ficon-weight-hanging:before {
  content: "\f939";
}
.ficon-whale:before {
  content: "\f93a";
}
.ficon-wheat:before {
  content: "\f93b";
}
.ficon-wheelchair:before {
  content: "\f93c";
}
.ficon-whistle:before {
  content: "\f93d";
}
.ficon-wifi:before {
  content: "\f93e";
}
.ficon-wifi-1:before {
  content: "\f93f";
}
.ficon-wifi-2:before {
  content: "\f940";
}
.ficon-wifi-slash:before {
  content: "\f941";
}
.ficon-wind:before {
  content: "\f942";
}
.ficon-window:before {
  content: "\f943";
}
.ficon-window-alt:before {
  content: "\f944";
}
.ficon-window-close:before {
  content: "\f945";
}
.ficon-window-maximize:before {
  content: "\f946";
}
.ficon-window-minimize:before {
  content: "\f947";
}
.ficon-window-restore:before {
  content: "\f948";
}
.ficon-windsock:before {
  content: "\f949";
}
.ficon-wind-warning:before {
  content: "\f94a";
}
.ficon-wine-bottle:before {
  content: "\f94b";
}
.ficon-wine-glass:before {
  content: "\f94c";
}
.ficon-wine-glass-alt:before {
  content: "\f94d";
}
.ficon-won-sign:before {
  content: "\f94e";
}
.ficon-wrench:before {
  content: "\f94f";
}
.ficon-x-ray:before {
  content: "\f950";
}
.ficon-yen-sign:before {
  content: "\f951";
}
.ficon-yin-yang:before {
  content: "\f952";
}
.ficon-abacus1:before {
  content: "\e904";
}
.ficon-acorn1:before {
  content: "\e905";
}
.ficon-ad1:before {
  content: "\e906";
}
.ficon-address-book1:before {
  content: "\e907";
}
.ficon-address-card1:before {
  content: "\e908";
}
.ficon-adjust1:before {
  content: "\e909";
}
.ficon-air-freshener1:before {
  content: "\e90a";
}
.ficon-alarm-clock1:before {
  content: "\e90b";
}
.ficon-alicorn1:before {
  content: "\e90c";
}
.ficon-align-center1:before {
  content: "\e90d";
}
.ficon-align-justify1:before {
  content: "\e90e";
}
.ficon-align-left1:before {
  content: "\e90f";
}
.ficon-align-right1:before {
  content: "\e910";
}
.ficon-allergies1:before {
  content: "\e911";
}
.ficon-ambulance1:before {
  content: "\ee15";
}
.ficon-american-sign-language-interpreting1:before {
  content: "\e912";
}
.ficon-analytics1:before {
  content: "\e913";
}
.ficon-anchor1:before {
  content: "\e914";
}
.ficon-angle-double-down1:before {
  content: "\e915";
}
.ficon-angle-double-left1:before {
  content: "\e916";
}
.ficon-angle-double-right1:before {
  content: "\e917";
}
.ficon-angle-double-up1:before {
  content: "\e918";
}
.ficon-angle-down1:before {
  content: "\e919";
}
.ficon-angle-left1:before {
  content: "\e91a";
}
.ficon-angle-right1:before {
  content: "\e91b";
}
.ficon-angle-up1:before {
  content: "\e91c";
}
.ficon-angry1:before {
  content: "\e91d";
}
.ficon-ankh1:before {
  content: "\e91e";
}
.ficon-apple-alt1:before {
  content: "\e91f";
}
.ficon-apple-crate1:before {
  content: "\ee16";
}
.ficon-archive1:before {
  content: "\e920";
}
.ficon-archway1:before {
  content: "\e921";
}
.ficon-arrow-alt-circle-down1:before {
  content: "\e922";
}
.ficon-arrow-alt-circle-left1:before {
  content: "\e923";
}
.ficon-arrow-alt-circle-right1:before {
  content: "\e924";
}
.ficon-arrow-alt-circle-up1:before {
  content: "\e925";
}
.ficon-arrow-alt-down1:before {
  content: "\e926";
}
.ficon-arrow-alt-from-bottom1:before {
  content: "\e927";
}
.ficon-arrow-alt-from-left1:before {
  content: "\e928";
}
.ficon-arrow-alt-from-right1:before {
  content: "\e929";
}
.ficon-arrow-alt-from-top1:before {
  content: "\e92a";
}
.ficon-arrow-alt-left1:before {
  content: "\e92b";
}
.ficon-arrow-alt-right1:before {
  content: "\e92c";
}
.ficon-arrow-alt-square-down1:before {
  content: "\e92d";
}
.ficon-arrow-alt-square-left1:before {
  content: "\ee17";
}
.ficon-arrow-alt-square-right1:before {
  content: "\e92e";
}
.ficon-arrow-alt-square-up1:before {
  content: "\e92f";
}
.ficon-arrow-alt-to-bottom1:before {
  content: "\e930";
}
.ficon-arrow-alt-to-left1:before {
  content: "\e931";
}
.ficon-arrow-alt-to-right1:before {
  content: "\e932";
}
.ficon-arrow-alt-to-top1:before {
  content: "\e933";
}
.ficon-arrow-alt-up1:before {
  content: "\e934";
}
.ficon-arrow-circle-down1:before {
  content: "\e935";
}
.ficon-arrow-circle-left1:before {
  content: "\e936";
}
.ficon-arrow-circle-right1:before {
  content: "\e937";
}
.ficon-arrow-circle-up1:before {
  content: "\e938";
}
.ficon-arrow-down1:before {
  content: "\e939";
}
.ficon-arrow-from-bottom1:before {
  content: "\e93a";
}
.ficon-arrow-from-left1:before {
  content: "\e93b";
}
.ficon-arrow-from-right1:before {
  content: "\ee18";
}
.ficon-arrow-from-top1:before {
  content: "\e93c";
}
.ficon-arrow-left1:before {
  content: "\e93d";
}
.ficon-arrow-right1:before {
  content: "\e93e";
}
.ficon-arrows1:before {
  content: "\e93f";
}
.ficon-arrows-alt1:before {
  content: "\e940";
}
.ficon-arrows-alt-h1:before {
  content: "\e941";
}
.ficon-arrows-alt-v1:before {
  content: "\e942";
}
.ficon-arrows-h1:before {
  content: "\e943";
}
.ficon-arrow-square-down1:before {
  content: "\e944";
}
.ficon-arrow-square-left1:before {
  content: "\e945";
}
.ficon-arrow-square-right1:before {
  content: "\e946";
}
.ficon-arrow-square-up1:before {
  content: "\e947";
}
.ficon-arrows-v1:before {
  content: "\e948";
}
.ficon-arrow-to-bottom1:before {
  content: "\e949";
}
.ficon-arrow-to-left1:before {
  content: "\ee19";
}
.ficon-arrow-to-right1:before {
  content: "\e94a";
}
.ficon-arrow-to-top1:before {
  content: "\e94b";
}
.ficon-arrow-up1:before {
  content: "\e94c";
}
.ficon-assistive-listening-systems1:before {
  content: "\e94d";
}
.ficon-asterisk1:before {
  content: "\e94e";
}
.ficon-at1:before {
  content: "\e94f";
}
.ficon-atlas1:before {
  content: "\e950";
}
.ficon-atom1:before {
  content: "\e951";
}
.ficon-atom-alt1:before {
  content: "\e952";
}
.ficon-audio-description1:before {
  content: "\e953";
}
.ficon-award1:before {
  content: "\e954";
}
.ficon-axe1:before {
  content: "\e955";
}
.ficon-axe-battle1:before {
  content: "\e956";
}
.ficon-backpack1:before {
  content: "\e957";
}
.ficon-backspace1:before {
  content: "\ee1a";
}
.ficon-backward1:before {
  content: "\e958";
}
.ficon-badge1:before {
  content: "\e959";
}
.ficon-badge-check1:before {
  content: "\e95a";
}
.ficon-badge-dollar1:before {
  content: "\e95b";
}
.ficon-badge-percent1:before {
  content: "\e95c";
}
.ficon-badger-honey1:before {
  content: "\e95d";
}
.ficon-balance-scale1:before {
  content: "\e95e";
}
.ficon-balance-scale-left1:before {
  content: "\e95f";
}
.ficon-balance-scale-right1:before {
  content: "\e960";
}
.ficon-ballot1:before {
  content: "\e961";
}
.ficon-ballot-check1:before {
  content: "\e962";
}
.ficon-ban1:before {
  content: "\e963";
}
.ficon-band-aid1:before {
  content: "\e964";
}
.ficon-barcode1:before {
  content: "\e965";
}
.ficon-barcode-alt1:before {
  content: "\ee1b";
}
.ficon-barcode-read1:before {
  content: "\e966";
}
.ficon-barcode-scan1:before {
  content: "\e967";
}
.ficon-bars1:before {
  content: "\e968";
}
.ficon-baseball1:before {
  content: "\e969";
}
.ficon-baseball-ball1:before {
  content: "\e96a";
}
.ficon-basketball-ball1:before {
  content: "\e96b";
}
.ficon-basketball-hoop1:before {
  content: "\e96c";
}
.ficon-bat1:before {
  content: "\e96d";
}
.ficon-bath1:before {
  content: "\e96e";
}
.ficon-battery-bolt1:before {
  content: "\e96f";
}
.ficon-battery-empty1:before {
  content: "\e970";
}
.ficon-battery-full1:before {
  content: "\e971";
}
.ficon-battery-half1:before {
  content: "\e972";
}
.ficon-battery-quarter1:before {
  content: "\e973";
}
.ficon-battery-slash1:before {
  content: "\ee1c";
}
.ficon-battery-three-quarters1:before {
  content: "\e974";
}
.ficon-bed1:before {
  content: "\e975";
}
.ficon-beer1:before {
  content: "\e976";
}
.ficon-bell1:before {
  content: "\e977";
}
.ficon-bell-school1:before {
  content: "\e978";
}
.ficon-bell-school-slash1:before {
  content: "\e979";
}
.ficon-bell-slash1:before {
  content: "\e97a";
}
.ficon-bezier-curve1:before {
  content: "\e97b";
}
.ficon-bible1:before {
  content: "\e97c";
}
.ficon-bicycle1:before {
  content: "\e97d";
}
.ficon-binoculars1:before {
  content: "\e97e";
}
.ficon-birthday-cake1:before {
  content: "\e97f";
}
.ficon-blanket1:before {
  content: "\e980";
}
.ficon-blender1:before {
  content: "\e981";
}
.ficon-blender-phone1:before {
  content: "\ee1d";
}
.ficon-blind1:before {
  content: "\e982";
}
.ficon-bold1:before {
  content: "\e983";
}
.ficon-bolt1:before {
  content: "\e984";
}
.ficon-bomb1:before {
  content: "\e985";
}
.ficon-bone1:before {
  content: "\e986";
}
.ficon-bone-break1:before {
  content: "\e987";
}
.ficon-bong1:before {
  content: "\e988";
}
.ficon-book1:before {
  content: "\e989";
}
.ficon-book-alt1:before {
  content: "\e98a";
}
.ficon-book-dead1:before {
  content: "\e98b";
}
.ficon-book-heart1:before {
  content: "\e98c";
}
.ficon-bookmark1:before {
  content: "\e98d";
}
.ficon-book-open1:before {
  content: "\e98e";
}
.ficon-book-reader1:before {
  content: "\e98f";
}
.ficon-books1:before {
  content: "\ee1e";
}
.ficon-book-spells1:before {
  content: "\e990";
}
.ficon-booth-curtain1:before {
  content: "\e991";
}
.ficon-bow-arrow1:before {
  content: "\e992";
}
.ficon-bowling-ball1:before {
  content: "\e993";
}
.ficon-bowling-pins1:before {
  content: "\e994";
}
.ficon-box1:before {
  content: "\e995";
}
.ficon-box-alt1:before {
  content: "\e996";
}
.ficon-box-ballot1:before {
  content: "\e997";
}
.ficon-box-check1:before {
  content: "\e998";
}
.ficon-boxes1:before {
  content: "\e999";
}
.ficon-boxes-alt1:before {
  content: "\e99a";
}
.ficon-box-fragile1:before {
  content: "\e99b";
}
.ficon-box-full1:before {
  content: "\e99c";
}
.ficon-box-heart1:before {
  content: "\e99d";
}
.ficon-boxing-glove1:before {
  content: "\ee1f";
}
.ficon-box-open1:before {
  content: "\e99e";
}
.ficon-box-up1:before {
  content: "\e99f";
}
.ficon-box-usd1:before {
  content: "\e9a0";
}
.ficon-braille1:before {
  content: "\e9a1";
}
.ficon-brain1:before {
  content: "\e9a2";
}
.ficon-briefcase1:before {
  content: "\e9a3";
}
.ficon-briefcase-medical1:before {
  content: "\e9a4";
}
.ficon-broadcast-tower1:before {
  content: "\e9a5";
}
.ficon-broom1:before {
  content: "\e9a6";
}
.ficon-browser1:before {
  content: "\e9a7";
}
.ficon-brush1:before {
  content: "\e9a8";
}
.ficon-bug1:before {
  content: "\e9a9";
}
.ficon-building1:before {
  content: "\e9aa";
}
.ficon-bullhorn1:before {
  content: "\e9ab";
}
.ficon-bullseye1:before {
  content: "\ee20";
}
.ficon-bullseye-arrow1:before {
  content: "\e9ac";
}
.ficon-bullseye-pointer1:before {
  content: "\e9ad";
}
.ficon-burn1:before {
  content: "\e9ae";
}
.ficon-bus1:before {
  content: "\e9af";
}
.ficon-bus-alt1:before {
  content: "\e9b0";
}
.ficon-business-time1:before {
  content: "\e9b1";
}
.ficon-bus-school1:before {
  content: "\e9b2";
}
.ficon-cabinet-filing1:before {
  content: "\e9b3";
}
.ficon-calculator1:before {
  content: "\e9b4";
}
.ficon-calculator-alt1:before {
  content: "\e9b5";
}
.ficon-calendar1:before {
  content: "\e9b6";
}
.ficon-calendar-alt1:before {
  content: "\e9b7";
}
.ficon-calendar-check1:before {
  content: "\e9b8";
}
.ficon-calendar-edit1:before {
  content: "\e9b9";
}
.ficon-calendar-exclamation1:before {
  content: "\ee21";
}
.ficon-calendar-minus1:before {
  content: "\e9ba";
}
.ficon-calendar-plus1:before {
  content: "\e9bb";
}
.ficon-calendar-star1:before {
  content: "\e9bc";
}
.ficon-calendar-times1:before {
  content: "\e9bd";
}
.ficon-camera1:before {
  content: "\e9be";
}
.ficon-camera-alt1:before {
  content: "\e9bf";
}
.ficon-camera-retro1:before {
  content: "\e9c0";
}
.ficon-campfire1:before {
  content: "\e9c1";
}
.ficon-campground1:before {
  content: "\e9c2";
}
.ficon-candle-holder1:before {
  content: "\e9c3";
}
.ficon-candy-corn1:before {
  content: "\e9c4";
}
.ficon-cannabis1:before {
  content: "\e9c5";
}
.ficon-capsules1:before {
  content: "\e9c6";
}
.ficon-car1:before {
  content: "\e9c7";
}
.ficon-car-alt1:before {
  content: "\ee22";
}
.ficon-car-battery1:before {
  content: "\e9c8";
}
.ficon-car-bump1:before {
  content: "\e9c9";
}
.ficon-car-crash1:before {
  content: "\e9ca";
}
.ficon-caret-circle-down1:before {
  content: "\e9cb";
}
.ficon-caret-circle-left1:before {
  content: "\e9cc";
}
.ficon-caret-circle-right1:before {
  content: "\e9cd";
}
.ficon-caret-circle-up1:before {
  content: "\e9ce";
}
.ficon-caret-down1:before {
  content: "\e9cf";
}
.ficon-caret-left1:before {
  content: "\e9d0";
}
.ficon-caret-right1:before {
  content: "\e9d1";
}
.ficon-caret-square-down1:before {
  content: "\e9d2";
}
.ficon-caret-square-left1:before {
  content: "\e9d3";
}
.ficon-caret-square-right1:before {
  content: "\e9d4";
}
.ficon-caret-square-up1:before {
  content: "\e9d5";
}
.ficon-caret-up1:before {
  content: "\ee23";
}
.ficon-car-garage1:before {
  content: "\e9d6";
}
.ficon-car-mechanic1:before {
  content: "\e9d7";
}
.ficon-car-side1:before {
  content: "\e9d8";
}
.ficon-cart-arrow-down1:before {
  content: "\e9d9";
}
.ficon-car-tilt1:before {
  content: "\e9da";
}
.ficon-cart-plus1:before {
  content: "\e9db";
}
.ficon-car-wash1:before {
  content: "\e9dc";
}
.ficon-cat1:before {
  content: "\e9dd";
}
.ficon-cauldron1:before {
  content: "\e9de";
}
.ficon-certificate1:before {
  content: "\e9df";
}
.ficon-chair1:before {
  content: "\e9e0";
}
.ficon-chair-office1:before {
  content: "\e9e1";
}
.ficon-chalkboard1:before {
  content: "\e9e2";
}
.ficon-chalkboard-teacher1:before {
  content: "\e9e3";
}
.ficon-charging-station1:before {
  content: "\ee24";
}
.ficon-chart-area1:before {
  content: "\e9e4";
}
.ficon-chart-bar1:before {
  content: "\e9e5";
}
.ficon-chart-line1:before {
  content: "\e9e6";
}
.ficon-chart-line-down1:before {
  content: "\e9e7";
}
.ficon-chart-pie1:before {
  content: "\e9e8";
}
.ficon-chart-pie-alt1:before {
  content: "\e9e9";
}
.ficon-check1:before {
  content: "\e9ea";
}
.ficon-check-circle1:before {
  content: "\e9eb";
}
.ficon-check-double1:before {
  content: "\e9ec";
}
.ficon-check-square1:before {
  content: "\e9ed";
}
.ficon-chess1:before {
  content: "\e9ee";
}
.ficon-chess-bishop1:before {
  content: "\e9ef";
}
.ficon-chess-bishop-alt1:before {
  content: "\e9f0";
}
.ficon-chess-board1:before {
  content: "\e9f1";
}
.ficon-chess-clock1:before {
  content: "\ee25";
}
.ficon-chess-clock-alt1:before {
  content: "\e9f2";
}
.ficon-chess-king1:before {
  content: "\e9f3";
}
.ficon-chess-king-alt1:before {
  content: "\e9f4";
}
.ficon-chess-knight1:before {
  content: "\e9f5";
}
.ficon-chess-knight-alt1:before {
  content: "\e9f6";
}
.ficon-chess-pawn1:before {
  content: "\e9f7";
}
.ficon-chess-pawn-alt1:before {
  content: "\e9f8";
}
.ficon-chess-queen1:before {
  content: "\e9f9";
}
.ficon-chess-queen-alt1:before {
  content: "\e9fa";
}
.ficon-chess-rook1:before {
  content: "\e9fb";
}
.ficon-chess-rook-alt1:before {
  content: "\e9fc";
}
.ficon-chevron-circle-down1:before {
  content: "\e9fd";
}
.ficon-chevron-circle-left1:before {
  content: "\e9fe";
}
.ficon-chevron-circle-right1:before {
  content: "\e9ff";
}
.ficon-chevron-circle-up1:before {
  content: "\ee26";
}
.ficon-chevron-double-down1:before {
  content: "\ea00";
}
.ficon-chevron-double-left1:before {
  content: "\ea01";
}
.ficon-chevron-double-right1:before {
  content: "\ea02";
}
.ficon-chevron-double-up1:before {
  content: "\ea03";
}
.ficon-chevron-down1:before {
  content: "\ea04";
}
.ficon-chevron-left1:before {
  content: "\ea05";
}
.ficon-chevron-right1:before {
  content: "\ea06";
}
.ficon-chevron-square-down1:before {
  content: "\ea07";
}
.ficon-chevron-square-left1:before {
  content: "\ea08";
}
.ficon-chevron-square-right1:before {
  content: "\ea09";
}
.ficon-chevron-square-up1:before {
  content: "\ea0a";
}
.ficon-chevron-up1:before {
  content: "\ea0b";
}
.ficon-child1:before {
  content: "\ea0c";
}
.ficon-church1:before {
  content: "\ea0d";
}
.ficon-circle1:before {
  content: "\ee27";
}
.ficon-circle-notch1:before {
  content: "\ea0e";
}
.ficon-city1:before {
  content: "\ea0f";
}
.ficon-claw-marks1:before {
  content: "\ea10";
}
.ficon-clipboard1:before {
  content: "\ea11";
}
.ficon-clipboard-check1:before {
  content: "\ea12";
}
.ficon-clipboard-list1:before {
  content: "\ea13";
}
.ficon-clipboard-list-check1:before {
  content: "\ea14";
}
.ficon-clipboard-prescription1:before {
  content: "\ea15";
}
.ficon-clock1:before {
  content: "\ea16";
}
.ficon-clone1:before {
  content: "\ea17";
}
.ficon-closed-captioning1:before {
  content: "\ea18";
}
.ficon-cloud1:before {
  content: "\ea19";
}
.ficon-cloud-download1:before {
  content: "\ea1a";
}
.ficon-cloud-download-alt1:before {
  content: "\ea1b";
}
.ficon-cloud-drizzle1:before {
  content: "\ee28";
}
.ficon-cloud-hail1:before {
  content: "\ea1c";
}
.ficon-cloud-hail-mixed1:before {
  content: "\ea1d";
}
.ficon-cloud-meatball1:before {
  content: "\ea1e";
}
.ficon-cloud-moon1:before {
  content: "\ea1f";
}
.ficon-cloud-moon-rain1:before {
  content: "\ea20";
}
.ficon-cloud-rain1:before {
  content: "\ea21";
}
.ficon-cloud-rainbow1:before {
  content: "\ea22";
}
.ficon-clouds1:before {
  content: "\ea23";
}
.ficon-cloud-showers1:before {
  content: "\ea24";
}
.ficon-cloud-showers-heavy1:before {
  content: "\ea25";
}
.ficon-cloud-sleet1:before {
  content: "\ea26";
}
.ficon-clouds-moon1:before {
  content: "\ea27";
}
.ficon-cloud-snow1:before {
  content: "\ea28";
}
.ficon-clouds-sun1:before {
  content: "\ea29";
}
.ficon-cloud-sun1:before {
  content: "\ee29";
}
.ficon-cloud-sun-rain1:before {
  content: "\ea2a";
}
.ficon-cloud-upload1:before {
  content: "\ea2b";
}
.ficon-cloud-upload-alt1:before {
  content: "\ea2c";
}
.ficon-club1:before {
  content: "\ea2d";
}
.ficon-cocktail1:before {
  content: "\ea2e";
}
.ficon-code1:before {
  content: "\ea2f";
}
.ficon-code-branch1:before {
  content: "\ea30";
}
.ficon-code-commit1:before {
  content: "\ea31";
}
.ficon-code-merge1:before {
  content: "\ea32";
}
.ficon-coffee1:before {
  content: "\ea33";
}
.ficon-coffee-togo1:before {
  content: "\ea34";
}
.ficon-coffin1:before {
  content: "\ea35";
}
.ficon-cog1:before {
  content: "\ea36";
}
.ficon-cogs1:before {
  content: "\ea37";
}
.ficon-coins1:before {
  content: "\ee2a";
}
.ficon-columns1:before {
  content: "\ea38";
}
.ficon-comment1:before {
  content: "\ea39";
}
.ficon-comment-alt1:before {
  content: "\ea3a";
}
.ficon-comment-alt-check1:before {
  content: "\ea3b";
}
.ficon-comment-alt-dollar1:before {
  content: "\ea3c";
}
.ficon-comment-alt-dots1:before {
  content: "\ea3d";
}
.ficon-comment-alt-edit1:before {
  content: "\ea3e";
}
.ficon-comment-alt-exclamation1:before {
  content: "\ea3f";
}
.ficon-comment-alt-lines1:before {
  content: "\ea40";
}
.ficon-comment-alt-minus1:before {
  content: "\ea41";
}
.ficon-comment-alt-plus1:before {
  content: "\ea42";
}
.ficon-comment-alt-slash1:before {
  content: "\ea43";
}
.ficon-comment-alt-smile1:before {
  content: "\ea44";
}
.ficon-comment-alt-times1:before {
  content: "\ea45";
}
.ficon-comment-check1:before {
  content: "\ee2b";
}
.ficon-comment-dollar1:before {
  content: "\ea46";
}
.ficon-comment-dots1:before {
  content: "\ea47";
}
.ficon-comment-edit1:before {
  content: "\ea48";
}
.ficon-comment-exclamation1:before {
  content: "\ea49";
}
.ficon-comment-lines1:before {
  content: "\ea4a";
}
.ficon-comment-minus1:before {
  content: "\ea4b";
}
.ficon-comment-plus1:before {
  content: "\ea4c";
}
.ficon-comments1:before {
  content: "\ea4d";
}
.ficon-comments-alt1:before {
  content: "\ea4e";
}
.ficon-comments-alt-dollar1:before {
  content: "\ea4f";
}
.ficon-comments-dollar1:before {
  content: "\ea50";
}
.ficon-comment-slash1:before {
  content: "\ea51";
}
.ficon-comment-smile1:before {
  content: "\ea52";
}
.ficon-comment-times1:before {
  content: "\ea53";
}
.ficon-compact-disc1:before {
  content: "\ee2c";
}
.ficon-compass1:before {
  content: "\ea54";
}
.ficon-compass-slash1:before {
  content: "\ea55";
}
.ficon-compress1:before {
  content: "\ea56";
}
.ficon-compress-alt1:before {
  content: "\ea57";
}
.ficon-compress-wide1:before {
  content: "\ea58";
}
.ficon-concierge-bell1:before {
  content: "\ea59";
}
.ficon-container-storage1:before {
  content: "\ea5a";
}
.ficon-conveyor-belt1:before {
  content: "\ea5b";
}
.ficon-conveyor-belt-alt1:before {
  content: "\ea5c";
}
.ficon-cookie1:before {
  content: "\ea5d";
}
.ficon-cookie-bite1:before {
  content: "\ea5e";
}
.ficon-copy1:before {
  content: "\ea5f";
}
.ficon-copyright1:before {
  content: "\ea60";
}
.ficon-corn1:before {
  content: "\ea61";
}
.ficon-couch1:before {
  content: "\ee2d";
}
.ficon-cow1:before {
  content: "\ea62";
}
.ficon-credit-card1:before {
  content: "\ea63";
}
.ficon-credit-card-blank1:before {
  content: "\ea64";
}
.ficon-credit-card-front1:before {
  content: "\ea65";
}
.ficon-cricket1:before {
  content: "\ea66";
}
.ficon-crop1:before {
  content: "\ea67";
}
.ficon-crop-alt1:before {
  content: "\ea68";
}
.ficon-cross1:before {
  content: "\ea69";
}
.ficon-crosshairs1:before {
  content: "\ea6a";
}
.ficon-crow1:before {
  content: "\ea6b";
}
.ficon-crown1:before {
  content: "\ea6c";
}
.ficon-cube1:before {
  content: "\ea6d";
}
.ficon-cubes1:before {
  content: "\ea6e";
}
.ficon-curling1:before {
  content: "\ea6f";
}
.ficon-cut1:before {
  content: "\ee2e";
}
.ficon-dagger1:before {
  content: "\ea70";
}
.ficon-database1:before {
  content: "\ea71";
}
.ficon-deaf1:before {
  content: "\ea72";
}
.ficon-democrat1:before {
  content: "\ea73";
}
.ficon-desktop1:before {
  content: "\ea74";
}
.ficon-desktop-alt1:before {
  content: "\ea75";
}
.ficon-dewpoint1:before {
  content: "\ea76";
}
.ficon-dharmachakra1:before {
  content: "\ea77";
}
.ficon-diagnoses1:before {
  content: "\ea78";
}
.ficon-diamond1:before {
  content: "\ea79";
}
.ficon-dice1:before {
  content: "\ea7a";
}
.ficon-dice-d41:before {
  content: "\ea7b";
}
.ficon-dice-d61:before {
  content: "\ea7c";
}
.ficon-dice-d81:before {
  content: "\ea7d";
}
.ficon-dice-d101:before {
  content: "\ee2f";
}
.ficon-dice-d121:before {
  content: "\ea7e";
}
.ficon-dice-d201:before {
  content: "\ea7f";
}
.ficon-dice-five1:before {
  content: "\ea80";
}
.ficon-dice-four1:before {
  content: "\ea81";
}
.ficon-dice-one1:before {
  content: "\ea82";
}
.ficon-dice-six1:before {
  content: "\ea83";
}
.ficon-dice-three1:before {
  content: "\ea84";
}
.ficon-dice-two1:before {
  content: "\ea85";
}
.ficon-digital-tachograph1:before {
  content: "\ea86";
}
.ficon-diploma1:before {
  content: "\ea87";
}
.ficon-directions1:before {
  content: "\ea88";
}
.ficon-divide1:before {
  content: "\ea89";
}
.ficon-dizzy1:before {
  content: "\ea8a";
}
.ficon-dna1:before {
  content: "\ea8b";
}
.ficon-dog1:before {
  content: "\ee30";
}
.ficon-dog-leashed1:before {
  content: "\ea8c";
}
.ficon-dollar-sign1:before {
  content: "\ea8d";
}
.ficon-dolly1:before {
  content: "\ea8e";
}
.ficon-dolly-empty1:before {
  content: "\ea8f";
}
.ficon-dolly-flatbed1:before {
  content: "\ea90";
}
.ficon-dolly-flatbed-alt1:before {
  content: "\ea91";
}
.ficon-dolly-flatbed-empty1:before {
  content: "\ea92";
}
.ficon-donate1:before {
  content: "\ea93";
}
.ficon-do-not-enter1:before {
  content: "\ea94";
}
.ficon-door-closed1:before {
  content: "\ea95";
}
.ficon-door-open1:before {
  content: "\ea96";
}
.ficon-dot-circle1:before {
  content: "\ea97";
}
.ficon-dove1:before {
  content: "\ea98";
}
.ficon-download1:before {
  content: "\ea99";
}
.ficon-drafting-compass1:before {
  content: "\ee31";
}
.ficon-dragon1:before {
  content: "\ea9a";
}
.ficon-draw-circle1:before {
  content: "\ea9b";
}
.ficon-draw-polygon1:before {
  content: "\ea9c";
}
.ficon-draw-square1:before {
  content: "\ea9d";
}
.ficon-drum1:before {
  content: "\ea9e";
}
.ficon-drum-steelpan1:before {
  content: "\ea9f";
}
.ficon-drumstick1:before {
  content: "\eaa0";
}
.ficon-drumstick-bite1:before {
  content: "\eaa1";
}
.ficon-duck1:before {
  content: "\eaa2";
}
.ficon-dumbbell1:before {
  content: "\eaa3";
}
.ficon-dungeon1:before {
  content: "\eaa4";
}
.ficon-ear1:before {
  content: "\eaa5";
}
.ficon-eclipse1:before {
  content: "\eaa6";
}
.ficon-eclipse-alt1:before {
  content: "\eaa7";
}
.ficon-edit1:before {
  content: "\ee32";
}
.ficon-eject1:before {
  content: "\eaa8";
}
.ficon-elephant1:before {
  content: "\eaa9";
}
.ficon-ellipsis-h1:before {
  content: "\eaaa";
}
.ficon-ellipsis-h-alt1:before {
  content: "\eaab";
}
.ficon-ellipsis-v1:before {
  content: "\eaac";
}
.ficon-ellipsis-v-alt1:before {
  content: "\eaad";
}
.ficon-empty-set1:before {
  content: "\eaae";
}
.ficon-engine-warning1:before {
  content: "\eaaf";
}
.ficon-envelope1:before {
  content: "\eab0";
}
.ficon-envelope-open1:before {
  content: "\eab1";
}
.ficon-envelope-open-dollar1:before {
  content: "\eab2";
}
.ficon-envelope-open-text1:before {
  content: "\eab3";
}
.ficon-envelope-square1:before {
  content: "\eab4";
}
.ficon-equals1:before {
  content: "\eab5";
}
.ficon-eraser1:before {
  content: "\ee33";
}
.ficon-euro-sign1:before {
  content: "\eab6";
}
.ficon-exchange1:before {
  content: "\eab7";
}
.ficon-exchange-alt1:before {
  content: "\eab8";
}
.ficon-exclamation1:before {
  content: "\eab9";
}
.ficon-exclamation-circle1:before {
  content: "\eaba";
}
.ficon-exclamation-square1:before {
  content: "\eabb";
}
.ficon-exclamation-triangle1:before {
  content: "\eabc";
}
.ficon-expand1:before {
  content: "\eabd";
}
.ficon-expand-alt1:before {
  content: "\eabe";
}
.ficon-expand-arrows1:before {
  content: "\eabf";
}
.ficon-expand-arrows-alt1:before {
  content: "\eac0";
}
.ficon-expand-wide1:before {
  content: "\eac1";
}
.ficon-external-link1:before {
  content: "\eac2";
}
.ficon-external-link-alt1:before {
  content: "\eac3";
}
.ficon-external-link-square1:before {
  content: "\ee34";
}
.ficon-external-link-square-alt1:before {
  content: "\eac4";
}
.ficon-eye1:before {
  content: "\eac5";
}
.ficon-eye-dropper1:before {
  content: "\eac6";
}
.ficon-eye-evil1:before {
  content: "\eac7";
}
.ficon-eye-slash1:before {
  content: "\eac8";
}
.ficon-fast-backward1:before {
  content: "\eac9";
}
.ficon-fast-forward1:before {
  content: "\eaca";
}
.ficon-fax1:before {
  content: "\eacb";
}
.ficon-feather1:before {
  content: "\eacc";
}
.ficon-feather-alt1:before {
  content: "\eacd";
}
.ficon-female1:before {
  content: "\eace";
}
.ficon-field-hockey1:before {
  content: "\eacf";
}
.ficon-fighter-jet1:before {
  content: "\ead0";
}
.ficon-file1:before {
  content: "\ead1";
}
.ficon-file-alt1:before {
  content: "\ee35";
}
.ficon-file-archive1:before {
  content: "\ead2";
}
.ficon-file-audio1:before {
  content: "\ead3";
}
.ficon-file-certificate1:before {
  content: "\ead4";
}
.ficon-file-chart-line1:before {
  content: "\ead5";
}
.ficon-file-chart-pie1:before {
  content: "\ead6";
}
.ficon-file-check1:before {
  content: "\ead7";
}
.ficon-file-code1:before {
  content: "\ead8";
}
.ficon-file-contract1:before {
  content: "\ead9";
}
.ficon-file-csv1:before {
  content: "\eada";
}
.ficon-file-download1:before {
  content: "\eadb";
}
.ficon-file-edit1:before {
  content: "\eadc";
}
.ficon-file-excel1:before {
  content: "\eadd";
}
.ficon-file-exclamation1:before {
  content: "\eade";
}
.ficon-file-export1:before {
  content: "\eadf";
}
.ficon-file-image1:before {
  content: "\ee36";
}
.ficon-file-import1:before {
  content: "\eae0";
}
.ficon-file-invoice1:before {
  content: "\eae1";
}
.ficon-file-invoice-dollar1:before {
  content: "\eae2";
}
.ficon-file-medical1:before {
  content: "\eae3";
}
.ficon-file-medical-alt1:before {
  content: "\eae4";
}
.ficon-file-minus1:before {
  content: "\eae5";
}
.ficon-file-pdf1:before {
  content: "\eae6";
}
.ficon-file-plus1:before {
  content: "\eae7";
}
.ficon-file-powerpoint1:before {
  content: "\eae8";
}
.ficon-file-prescription1:before {
  content: "\eae9";
}
.ficon-file-signature1:before {
  content: "\eaea";
}
.ficon-file-spreadsheet1:before {
  content: "\eaeb";
}
.ficon-file-times1:before {
  content: "\eaec";
}
.ficon-file-upload1:before {
  content: "\eaed";
}
.ficon-file-user1:before {
  content: "\ee37";
}
.ficon-file-video1:before {
  content: "\eaee";
}
.ficon-file-word1:before {
  content: "\eaef";
}
.ficon-fill1:before {
  content: "\eaf0";
}
.ficon-fill-drip1:before {
  content: "\eaf1";
}
.ficon-film1:before {
  content: "\eaf2";
}
.ficon-film-alt1:before {
  content: "\eaf3";
}
.ficon-filter1:before {
  content: "\eaf4";
}
.ficon-fingerprint1:before {
  content: "\eaf5";
}
.ficon-fire1:before {
  content: "\eaf6";
}
.ficon-fire-extinguisher1:before {
  content: "\eaf7";
}
.ficon-fire-smoke1:before {
  content: "\eaf8";
}
.ficon-first-aid1:before {
  content: "\eaf9";
}
.ficon-fish1:before {
  content: "\eafa";
}
.ficon-fist-raised1:before {
  content: "\eafb";
}
.ficon-flag1:before {
  content: "\ee38";
}
.ficon-flag-alt1:before {
  content: "\eafc";
}
.ficon-flag-checkered1:before {
  content: "\eafd";
}
.ficon-flag-usa1:before {
  content: "\eafe";
}
.ficon-flame1:before {
  content: "\eaff";
}
.ficon-flask1:before {
  content: "\eb00";
}
.ficon-flask-poison1:before {
  content: "\eb01";
}
.ficon-flask-potion1:before {
  content: "\eb02";
}
.ficon-flushed1:before {
  content: "\eb03";
}
.ficon-fog1:before {
  content: "\eb04";
}
.ficon-folder1:before {
  content: "\eb05";
}
.ficon-folder-minus1:before {
  content: "\eb06";
}
.ficon-folder-open1:before {
  content: "\eb07";
}
.ficon-folder-plus1:before {
  content: "\eb08";
}
.ficon-folders1:before {
  content: "\eb09";
}
.ficon-folder-times1:before {
  content: "\ee39";
}
.ficon-font1:before {
  content: "\eb0a";
}
.ficon-font-awesome-logo-full11:before {
  content: "\eb0b";
}
.ficon-football-ball1:before {
  content: "\eb0c";
}
.ficon-football-helmet1:before {
  content: "\eb0d";
}
.ficon-forklift1:before {
  content: "\eb0e";
}
.ficon-forward1:before {
  content: "\eb0f";
}
.ficon-fragile1:before {
  content: "\eb10";
}
.ficon-frog1:before {
  content: "\eb11";
}
.ficon-frown1:before {
  content: "\eb12";
}
.ficon-frown-open1:before {
  content: "\eb13";
}
.ficon-function1:before {
  content: "\eb14";
}
.ficon-funnel-dollar1:before {
  content: "\eb15";
}
.ficon-futbol1:before {
  content: "\eb16";
}
.ficon-gamepad1:before {
  content: "\ee3a";
}
.ficon-gas-pump1:before {
  content: "\eb17";
}
.ficon-gas-pump-slash1:before {
  content: "\eb18";
}
.ficon-gavel1:before {
  content: "\eb19";
}
.ficon-gem1:before {
  content: "\eb1a";
}
.ficon-genderless1:before {
  content: "\eb1b";
}
.ficon-ghost1:before {
  content: "\eb1c";
}
.ficon-gift1:before {
  content: "\eb1d";
}
.ficon-gift-card1:before {
  content: "\eb1e";
}
.ficon-glasses1:before {
  content: "\eb1f";
}
.ficon-glasses-alt1:before {
  content: "\eb20";
}
.ficon-glass-martini1:before {
  content: "\eb21";
}
.ficon-glass-martini-alt1:before {
  content: "\eb22";
}
.ficon-globe1:before {
  content: "\eb23";
}
.ficon-globe-africa1:before {
  content: "\eb24";
}
.ficon-globe-americas1:before {
  content: "\ee3b";
}
.ficon-globe-asia1:before {
  content: "\eb25";
}
.ficon-globe-stand1:before {
  content: "\eb26";
}
.ficon-golf-ball1:before {
  content: "\eb27";
}
.ficon-golf-club1:before {
  content: "\eb28";
}
.ficon-gopuram1:before {
  content: "\eb29";
}
.ficon-graduation-cap1:before {
  content: "\eb2a";
}
.ficon-greater-than1:before {
  content: "\eb2b";
}
.ficon-greater-than-equal1:before {
  content: "\eb2c";
}
.ficon-grimace1:before {
  content: "\eb2d";
}
.ficon-grin1:before {
  content: "\eb2e";
}
.ficon-grin-alt1:before {
  content: "\eb2f";
}
.ficon-grin-beam1:before {
  content: "\eb30";
}
.ficon-grin-beam-sweat1:before {
  content: "\eb31";
}
.ficon-grin-hearts1:before {
  content: "\eb32";
}
.ficon-grin-squint1:before {
  content: "\ee3c";
}
.ficon-grin-squint-tears1:before {
  content: "\eb33";
}
.ficon-grin-stars1:before {
  content: "\eb34";
}
.ficon-grin-tears1:before {
  content: "\eb35";
}
.ficon-grin-tongue1:before {
  content: "\eb36";
}
.ficon-grin-tongue-squint1:before {
  content: "\eb37";
}
.ficon-grin-tongue-wink1:before {
  content: "\eb38";
}
.ficon-grin-wink1:before {
  content: "\eb39";
}
.ficon-grip-horizontal1:before {
  content: "\eb3a";
}
.ficon-grip-vertical1:before {
  content: "\eb3b";
}
.ficon-h11:before {
  content: "\eb3c";
}
.ficon-h21:before {
  content: "\eb3d";
}
.ficon-h31:before {
  content: "\eb3e";
}
.ficon-hammer1:before {
  content: "\eb3f";
}
.ficon-hammer-war1:before {
  content: "\eb40";
}
.ficon-hamsa1:before {
  content: "\ee3d";
}
.ficon-hand-heart1:before {
  content: "\eb41";
}
.ficon-hand-holding1:before {
  content: "\eb42";
}
.ficon-hand-holding-box1:before {
  content: "\eb43";
}
.ficon-hand-holding-heart1:before {
  content: "\eb44";
}
.ficon-hand-holding-magic1:before {
  content: "\eb45";
}
.ficon-hand-holding-seedling1:before {
  content: "\eb46";
}
.ficon-hand-holding-usd1:before {
  content: "\eb47";
}
.ficon-hand-holding-water1:before {
  content: "\eb48";
}
.ficon-hand-lizard1:before {
  content: "\eb49";
}
.ficon-hand-paper1:before {
  content: "\eb4a";
}
.ficon-hand-peace1:before {
  content: "\eb4b";
}
.ficon-hand-point-down1:before {
  content: "\eb4c";
}
.ficon-hand-pointer1:before {
  content: "\eb4d";
}
.ficon-hand-point-left1:before {
  content: "\eb4e";
}
.ficon-hand-point-right1:before {
  content: "\ee3e";
}
.ficon-hand-point-up1:before {
  content: "\eb4f";
}
.ficon-hand-receiving1:before {
  content: "\eb50";
}
.ficon-hand-rock1:before {
  content: "\eb51";
}
.ficon-hands1:before {
  content: "\eb52";
}
.ficon-hand-scissors1:before {
  content: "\eb53";
}
.ficon-handshake1:before {
  content: "\eb54";
}
.ficon-handshake-alt1:before {
  content: "\eb55";
}
.ficon-hands-heart1:before {
  content: "\eb56";
}
.ficon-hands-helping1:before {
  content: "\eb57";
}
.ficon-hand-spock1:before {
  content: "\eb58";
}
.ficon-hands-usd1:before {
  content: "\eb59";
}
.ficon-hanukiah1:before {
  content: "\eb5a";
}
.ficon-hashtag1:before {
  content: "\eb5b";
}
.ficon-hat-witch1:before {
  content: "\eb5c";
}
.ficon-hat-wizard1:before {
  content: "\ee3f";
}
.ficon-haykal1:before {
  content: "\eb5d";
}
.ficon-hdd1:before {
  content: "\eb5e";
}
.ficon-heading1:before {
  content: "\eb5f";
}
.ficon-headphones1:before {
  content: "\eb60";
}
.ficon-headphones-alt1:before {
  content: "\eb61";
}
.ficon-headset1:before {
  content: "\eb62";
}
.ficon-head-side1:before {
  content: "\eb63";
}
.ficon-head-vr1:before {
  content: "\eb64";
}
.ficon-heart1:before {
  content: "\eb65";
}
.ficon-heartbeat1:before {
  content: "\eb66";
}
.ficon-heart-circle1:before {
  content: "\eb67";
}
.ficon-heart-rate1:before {
  content: "\eb68";
}
.ficon-heart-square1:before {
  content: "\eb69";
}
.ficon-helicopter1:before {
  content: "\eb6a";
}
.ficon-helmet-battle1:before {
  content: "\ee40";
}
.ficon-hexagon1:before {
  content: "\eb6b";
}
.ficon-highlighter1:before {
  content: "\eb6c";
}
.ficon-hiking1:before {
  content: "\eb6d";
}
.ficon-hippo1:before {
  content: "\eb6e";
}
.ficon-history1:before {
  content: "\eb6f";
}
.ficon-hockey-mask1:before {
  content: "\eb70";
}
.ficon-hockey-puck1:before {
  content: "\eb71";
}
.ficon-hockey-sticks1:before {
  content: "\eb72";
}
.ficon-home1:before {
  content: "\eb73";
}
.ficon-home-heart1:before {
  content: "\eb74";
}
.ficon-hood-cloak1:before {
  content: "\eb75";
}
.ficon-horse1:before {
  content: "\eb76";
}
.ficon-hospital1:before {
  content: "\eb77";
}
.ficon-hospital-alt1:before {
  content: "\eb78";
}
.ficon-hospital-symbol1:before {
  content: "\ee41";
}
.ficon-hotel1:before {
  content: "\eb79";
}
.ficon-hot-tub1:before {
  content: "\eb7a";
}
.ficon-hourglass1:before {
  content: "\eb7b";
}
.ficon-hourglass-end1:before {
  content: "\eb7c";
}
.ficon-hourglass-half1:before {
  content: "\eb7d";
}
.ficon-hourglass-start1:before {
  content: "\eb7e";
}
.ficon-house-damage1:before {
  content: "\eb7f";
}
.ficon-house-flood1:before {
  content: "\eb80";
}
.ficon-hryvnia1:before {
  content: "\eb81";
}
.ficon-h-square1:before {
  content: "\eb82";
}
.ficon-humidity1:before {
  content: "\eb83";
}
.ficon-hurricane1:before {
  content: "\eb84";
}
.ficon-i-cursor1:before {
  content: "\eb85";
}
.ficon-id-badge1:before {
  content: "\eb86";
}
.ficon-id-card1:before {
  content: "\ee42";
}
.ficon-id-card-alt1:before {
  content: "\eb87";
}
.ficon-image1:before {
  content: "\eb88";
}
.ficon-images1:before {
  content: "\eb89";
}
.ficon-inbox1:before {
  content: "\eb8a";
}
.ficon-inbox-in1:before {
  content: "\eb8b";
}
.ficon-inbox-out1:before {
  content: "\eb8c";
}
.ficon-indent1:before {
  content: "\eb8d";
}
.ficon-industry1:before {
  content: "\eb8e";
}
.ficon-industry-alt1:before {
  content: "\eb8f";
}
.ficon-infinity1:before {
  content: "\eb90";
}
.ficon-info1:before {
  content: "\eb91";
}
.ficon-info-circle1:before {
  content: "\eb92";
}
.ficon-info-square1:before {
  content: "\eb93";
}
.ficon-inhaler1:before {
  content: "\eb94";
}
.ficon-integral1:before {
  content: "\ee43";
}
.ficon-intersection1:before {
  content: "\eb95";
}
.ficon-inventory1:before {
  content: "\eb96";
}
.ficon-italic1:before {
  content: "\eb97";
}
.ficon-jack-o-lantern1:before {
  content: "\eb98";
}
.ficon-jedi1:before {
  content: "\eb99";
}
.ficon-joint1:before {
  content: "\eb9a";
}
.ficon-journal-whills1:before {
  content: "\eb9b";
}
.ficon-kaaba1:before {
  content: "\eb9c";
}
.ficon-key1:before {
  content: "\eb9d";
}
.ficon-keyboard1:before {
  content: "\eb9e";
}
.ficon-keynote1:before {
  content: "\eb9f";
}
.ficon-key-skeleton1:before {
  content: "\eba0";
}
.ficon-khanda1:before {
  content: "\eba1";
}
.ficon-kidneys1:before {
  content: "\eba2";
}
.ficon-kiss1:before {
  content: "\ee44";
}
.ficon-kiss-beam1:before {
  content: "\eba3";
}
.ficon-kiss-wink-heart1:before {
  content: "\eba4";
}
.ficon-kite1:before {
  content: "\eba5";
}
.ficon-kiwi-bird1:before {
  content: "\eba6";
}
.ficon-knife-kitchen1:before {
  content: "\eba7";
}
.ficon-lambda1:before {
  content: "\eba8";
}
.ficon-lamp1:before {
  content: "\eba9";
}
.ficon-landmark1:before {
  content: "\ebaa";
}
.ficon-landmark-alt1:before {
  content: "\ebab";
}
.ficon-language1:before {
  content: "\ebac";
}
.ficon-laptop1:before {
  content: "\ebad";
}
.ficon-laptop-code1:before {
  content: "\ebae";
}
.ficon-laugh1:before {
  content: "\ebaf";
}
.ficon-laugh-beam1:before {
  content: "\ebb0";
}
.ficon-laugh-squint1:before {
  content: "\ee45";
}
.ficon-laugh-wink1:before {
  content: "\ebb1";
}
.ficon-layer-group1:before {
  content: "\ebb2";
}
.ficon-layer-minus1:before {
  content: "\ebb3";
}
.ficon-layer-plus1:before {
  content: "\ebb4";
}
.ficon-leaf1:before {
  content: "\ebb5";
}
.ficon-leaf-heart1:before {
  content: "\ebb6";
}
.ficon-leaf-maple1:before {
  content: "\ebb7";
}
.ficon-leaf-oak1:before {
  content: "\ebb8";
}
.ficon-lemon1:before {
  content: "\ebb9";
}
.ficon-less-than1:before {
  content: "\ebba";
}
.ficon-less-than-equal1:before {
  content: "\ebbb";
}
.ficon-level-down1:before {
  content: "\ebbc";
}
.ficon-level-down-alt1:before {
  content: "\ebbd";
}
.ficon-level-up1:before {
  content: "\ebbe";
}
.ficon-level-up-alt1:before {
  content: "\ee46";
}
.ficon-life-ring1:before {
  content: "\ebbf";
}
.ficon-lightbulb1:before {
  content: "\ebc0";
}
.ficon-lightbulb-dollar1:before {
  content: "\ebc1";
}
.ficon-lightbulb-exclamation1:before {
  content: "\ebc2";
}
.ficon-lightbulb-on1:before {
  content: "\ebc3";
}
.ficon-lightbulb-slash1:before {
  content: "\ebc4";
}
.ficon-link1:before {
  content: "\ebc5";
}
.ficon-lips1:before {
  content: "\ebc6";
}
.ficon-lira-sign1:before {
  content: "\ebc7";
}
.ficon-list1:before {
  content: "\ebc8";
}
.ficon-list-alt1:before {
  content: "\ebc9";
}
.ficon-list-ol1:before {
  content: "\ebca";
}
.ficon-list-ul1:before {
  content: "\ebcb";
}
.ficon-location1:before {
  content: "\ebcc";
}
.ficon-location-arrow1:before {
  content: "\ee47";
}
.ficon-location-circle1:before {
  content: "\ebcd";
}
.ficon-location-slash1:before {
  content: "\ebce";
}
.ficon-lock1:before {
  content: "\ebcf";
}
.ficon-lock-alt1:before {
  content: "\ebd0";
}
.ficon-lock-open1:before {
  content: "\ebd1";
}
.ficon-lock-open-alt1:before {
  content: "\ebd2";
}
.ficon-long-arrow-alt-down1:before {
  content: "\ebd3";
}
.ficon-long-arrow-alt-left1:before {
  content: "\ebd4";
}
.ficon-long-arrow-alt-right1:before {
  content: "\ebd5";
}
.ficon-long-arrow-alt-up1:before {
  content: "\ebd6";
}
.ficon-long-arrow-down1:before {
  content: "\ebd7";
}
.ficon-long-arrow-left1:before {
  content: "\ebd8";
}
.ficon-long-arrow-right1:before {
  content: "\ebd9";
}
.ficon-long-arrow-up1:before {
  content: "\ebda";
}
.ficon-loveseat1:before {
  content: "\ee48";
}
.ficon-low-vision1:before {
  content: "\ebdb";
}
.ficon-luchador1:before {
  content: "\ebdc";
}
.ficon-luggage-cart1:before {
  content: "\ebdd";
}
.ficon-lungs1:before {
  content: "\ebde";
}
.ficon-mace1:before {
  content: "\ebdf";
}
.ficon-magic1:before {
  content: "\ebe0";
}
.ficon-magnet1:before {
  content: "\ebe1";
}
.ficon-mail-bulk1:before {
  content: "\ebe2";
}
.ficon-male1:before {
  content: "\ebe3";
}
.ficon-mandolin1:before {
  content: "\ebe4";
}
.ficon-map1:before {
  content: "\ebe5";
}
.ficon-map-marked1:before {
  content: "\ebe6";
}
.ficon-map-marked-alt1:before {
  content: "\ebe7";
}
.ficon-map-marker1:before {
  content: "\ebe8";
}
.ficon-map-marker-alt1:before {
  content: "\ee49";
}
.ficon-map-marker-alt-slash1:before {
  content: "\ebe9";
}
.ficon-map-marker-check1:before {
  content: "\ebea";
}
.ficon-map-marker-edit1:before {
  content: "\ebeb";
}
.ficon-map-marker-exclamation1:before {
  content: "\ebec";
}
.ficon-map-marker-minus1:before {
  content: "\ebed";
}
.ficon-map-marker-plus1:before {
  content: "\ebee";
}
.ficon-map-marker-question1:before {
  content: "\ebef";
}
.ficon-map-marker-slash1:before {
  content: "\ebf0";
}
.ficon-map-marker-smile1:before {
  content: "\ebf1";
}
.ficon-map-marker-times1:before {
  content: "\ebf2";
}
.ficon-map-pin1:before {
  content: "\ebf3";
}
.ficon-map-signs1:before {
  content: "\ebf4";
}
.ficon-marker1:before {
  content: "\ebf5";
}
.ficon-mars1:before {
  content: "\ebf6";
}
.ficon-mars-double1:before {
  content: "\ee4a";
}
.ficon-mars-stroke1:before {
  content: "\ebf7";
}
.ficon-mars-stroke-h1:before {
  content: "\ebf8";
}
.ficon-mars-stroke-v1:before {
  content: "\ebf9";
}
.ficon-mask1:before {
  content: "\ebfa";
}
.ficon-medal1:before {
  content: "\ebfb";
}
.ficon-medkit1:before {
  content: "\ebfc";
}
.ficon-megaphone1:before {
  content: "\ebfd";
}
.ficon-meh1:before {
  content: "\ebfe";
}
.ficon-meh-blank1:before {
  content: "\ebff";
}
.ficon-meh-rolling-eyes1:before {
  content: "\ec00";
}
.ficon-memory1:before {
  content: "\ec01";
}
.ficon-menorah1:before {
  content: "\ec02";
}
.ficon-mercury1:before {
  content: "\ec03";
}
.ficon-meteor1:before {
  content: "\ec04";
}
.ficon-microchip1:before {
  content: "\ee4b";
}
.ficon-microphone1:before {
  content: "\ec05";
}
.ficon-microphone-alt1:before {
  content: "\ec06";
}
.ficon-microphone-alt-slash1:before {
  content: "\ec07";
}
.ficon-microphone-slash1:before {
  content: "\ec08";
}
.ficon-microscope1:before {
  content: "\ec09";
}
.ficon-mind-share1:before {
  content: "\ec0a";
}
.ficon-minus1:before {
  content: "\ec0b";
}
.ficon-minus-circle1:before {
  content: "\ec0c";
}
.ficon-minus-hexagon1:before {
  content: "\ec0d";
}
.ficon-minus-octagon1:before {
  content: "\ec0e";
}
.ficon-minus-square1:before {
  content: "\ec0f";
}
.ficon-mobile1:before {
  content: "\ec10";
}
.ficon-mobile-alt1:before {
  content: "\ec11";
}
.ficon-mobile-android1:before {
  content: "\ec12";
}
.ficon-mobile-android-alt1:before {
  content: "\ee4c";
}
.ficon-money-bill1:before {
  content: "\ec13";
}
.ficon-money-bill-alt1:before {
  content: "\ec14";
}
.ficon-money-bill-wave1:before {
  content: "\ec15";
}
.ficon-money-bill-wave-alt1:before {
  content: "\ec16";
}
.ficon-money-check1:before {
  content: "\ec17";
}
.ficon-money-check-alt1:before {
  content: "\ec18";
}
.ficon-monitor-heart-rate1:before {
  content: "\ec19";
}
.ficon-monkey1:before {
  content: "\ec1a";
}
.ficon-monument1:before {
  content: "\ec1b";
}
.ficon-moon1:before {
  content: "\ec1c";
}
.ficon-moon-cloud1:before {
  content: "\ec1d";
}
.ficon-moon-stars1:before {
  content: "\ec1e";
}
.ficon-mortar-pestle1:before {
  content: "\ec1f";
}
.ficon-mosque1:before {
  content: "\ec20";
}
.ficon-motorcycle1:before {
  content: "\ee4d";
}
.ficon-mountain1:before {
  content: "\ec21";
}
.ficon-mountains1:before {
  content: "\ec22";
}
.ficon-mouse-pointer1:before {
  content: "\ec23";
}
.ficon-music1:before {
  content: "\ec24";
}
.ficon-narwhal1:before {
  content: "\ec25";
}
.ficon-network-wired1:before {
  content: "\ec26";
}
.ficon-neuter1:before {
  content: "\ec27";
}
.ficon-newspaper1:before {
  content: "\ec28";
}
.ficon-not-equal1:before {
  content: "\ec29";
}
.ficon-notes-medical1:before {
  content: "\ec2a";
}
.ficon-object-group1:before {
  content: "\ec2b";
}
.ficon-object-ungroup1:before {
  content: "\ec2c";
}
.ficon-octagon1:before {
  content: "\ec2d";
}
.ficon-oil-can1:before {
  content: "\ec2e";
}
.ficon-oil-temp1:before {
  content: "\ee4e";
}
.ficon-om1:before {
  content: "\ec2f";
}
.ficon-omega1:before {
  content: "\ec30";
}
.ficon-otter1:before {
  content: "\ec31";
}
.ficon-outdent1:before {
  content: "\ec32";
}
.ficon-paint-brush1:before {
  content: "\ec33";
}
.ficon-paint-brush-alt1:before {
  content: "\ec34";
}
.ficon-paint-roller1:before {
  content: "\ec35";
}
.ficon-palette1:before {
  content: "\ec36";
}
.ficon-pallet1:before {
  content: "\ec37";
}
.ficon-pallet-alt1:before {
  content: "\ec38";
}
.ficon-paperclip1:before {
  content: "\ec39";
}
.ficon-paper-plane1:before {
  content: "\ec3a";
}
.ficon-parachute-box1:before {
  content: "\ec3b";
}
.ficon-paragraph1:before {
  content: "\ec3c";
}
.ficon-parking1:before {
  content: "\ee4f";
}
.ficon-parking-circle1:before {
  content: "\ec3d";
}
.ficon-parking-circle-slash1:before {
  content: "\ec3e";
}
.ficon-parking-slash1:before {
  content: "\ec3f";
}
.ficon-passport1:before {
  content: "\ec40";
}
.ficon-pastafarianism1:before {
  content: "\ec41";
}
.ficon-paste1:before {
  content: "\ec42";
}
.ficon-pause1:before {
  content: "\ec43";
}
.ficon-pause-circle1:before {
  content: "\ec44";
}
.ficon-paw1:before {
  content: "\ec45";
}
.ficon-paw-alt1:before {
  content: "\ec46";
}
.ficon-paw-claws1:before {
  content: "\ec47";
}
.ficon-peace1:before {
  content: "\ec48";
}
.ficon-pegasus1:before {
  content: "\ec49";
}
.ficon-pen1:before {
  content: "\ec4a";
}
.ficon-pen-alt1:before {
  content: "\ee50";
}
.ficon-pencil1:before {
  content: "\ec4b";
}
.ficon-pencil-alt1:before {
  content: "\ec4c";
}
.ficon-pencil-paintbrush1:before {
  content: "\ec4d";
}
.ficon-pencil-ruler1:before {
  content: "\ec4e";
}
.ficon-pen-fancy1:before {
  content: "\ec4f";
}
.ficon-pennant1:before {
  content: "\ec50";
}
.ficon-pen-nib1:before {
  content: "\ec51";
}
.ficon-pen-square1:before {
  content: "\ec52";
}
.ficon-people-carry1:before {
  content: "\ec53";
}
.ficon-percent1:before {
  content: "\ec54";
}
.ficon-percentage1:before {
  content: "\ec55";
}
.ficon-person-booth1:before {
  content: "\ec56";
}
.ficon-person-carry1:before {
  content: "\ec57";
}
.ficon-person-dolly1:before {
  content: "\ec58";
}
.ficon-person-dolly-empty1:before {
  content: "\ee51";
}
.ficon-person-sign1:before {
  content: "\ec59";
}
.ficon-phone1:before {
  content: "\ec5a";
}
.ficon-phone-office1:before {
  content: "\ec5b";
}
.ficon-phone-plus1:before {
  content: "\ec5c";
}
.ficon-phone-slash1:before {
  content: "\ec5d";
}
.ficon-phone-square1:before {
  content: "\ec5e";
}
.ficon-phone-volume1:before {
  content: "\ec5f";
}
.ficon-pi1:before {
  content: "\ec60";
}
.ficon-pie1:before {
  content: "\ec61";
}
.ficon-pig1:before {
  content: "\ec62";
}
.ficon-piggy-bank1:before {
  content: "\ec63";
}
.ficon-pills1:before {
  content: "\ec64";
}
.ficon-place-of-worship1:before {
  content: "\ec65";
}
.ficon-plane1:before {
  content: "\ec66";
}
.ficon-plane-alt1:before {
  content: "\ee52";
}
.ficon-plane-arrival1:before {
  content: "\ec67";
}
.ficon-plane-departure1:before {
  content: "\ec68";
}
.ficon-play1:before {
  content: "\ec69";
}
.ficon-play-circle1:before {
  content: "\ec6a";
}
.ficon-plug1:before {
  content: "\ec6b";
}
.ficon-plus1:before {
  content: "\ec6c";
}
.ficon-plus-circle1:before {
  content: "\ec6d";
}
.ficon-plus-hexagon1:before {
  content: "\ec6e";
}
.ficon-plus-octagon1:before {
  content: "\ec6f";
}
.ficon-plus-square1:before {
  content: "\ec70";
}
.ficon-podcast1:before {
  content: "\ec71";
}
.ficon-podium1:before {
  content: "\ec72";
}
.ficon-podium-star1:before {
  content: "\ec73";
}
.ficon-poll1:before {
  content: "\ec74";
}
.ficon-poll-h1:before {
  content: "\ee53";
}
.ficon-poll-people1:before {
  content: "\ec75";
}
.ficon-poo1:before {
  content: "\ec76";
}
.ficon-poop1:before {
  content: "\ec77";
}
.ficon-poo-storm1:before {
  content: "\ec78";
}
.ficon-portrait1:before {
  content: "\ec79";
}
.ficon-pound-sign1:before {
  content: "\ec7a";
}
.ficon-power-off1:before {
  content: "\ec7b";
}
.ficon-pray1:before {
  content: "\ec7c";
}
.ficon-praying-hands1:before {
  content: "\ec7d";
}
.ficon-prescription1:before {
  content: "\ec7e";
}
.ficon-prescription-bottle1:before {
  content: "\ec7f";
}
.ficon-prescription-bottle-alt1:before {
  content: "\ec80";
}
.ficon-presentation1:before {
  content: "\ec81";
}
.ficon-print1:before {
  content: "\ec82";
}
.ficon-print-slash1:before {
  content: "\ee54";
}
.ficon-procedures1:before {
  content: "\ec83";
}
.ficon-project-diagram1:before {
  content: "\ec84";
}
.ficon-pumpkin1:before {
  content: "\ec85";
}
.ficon-puzzle-piece1:before {
  content: "\ec86";
}
.ficon-qrcode1:before {
  content: "\ec87";
}
.ficon-question1:before {
  content: "\ec88";
}
.ficon-question-circle1:before {
  content: "\ec89";
}
.ficon-question-square1:before {
  content: "\ec8a";
}
.ficon-quidditch1:before {
  content: "\ec8b";
}
.ficon-quote-left1:before {
  content: "\ec8c";
}
.ficon-quote-right1:before {
  content: "\ec8d";
}
.ficon-quran1:before {
  content: "\ec8e";
}
.ficon-rabbit1:before {
  content: "\ec8f";
}
.ficon-rabbit-fast1:before {
  content: "\ec90";
}
.ficon-racquet1:before {
  content: "\ee55";
}
.ficon-rainbow1:before {
  content: "\ec91";
}
.ficon-raindrops1:before {
  content: "\ec92";
}
.ficon-ram1:before {
  content: "\ec93";
}
.ficon-ramp-loading1:before {
  content: "\ec94";
}
.ficon-random1:before {
  content: "\ec95";
}
.ficon-receipt1:before {
  content: "\ec96";
}
.ficon-rectangle-landscape1:before {
  content: "\ec97";
}
.ficon-rectangle-portrait1:before {
  content: "\ec98";
}
.ficon-rectangle-wide1:before {
  content: "\ec99";
}
.ficon-recycle1:before {
  content: "\ec9a";
}
.ficon-redo1:before {
  content: "\ec9b";
}
.ficon-redo-alt1:before {
  content: "\ec9c";
}
.ficon-registered1:before {
  content: "\ec9d";
}
.ficon-repeat1:before {
  content: "\ec9e";
}
.ficon-repeat-11:before {
  content: "\ee56";
}
.ficon-repeat-1-alt1:before {
  content: "\ec9f";
}
.ficon-repeat-alt1:before {
  content: "\eca0";
}
.ficon-reply1:before {
  content: "\eca1";
}
.ficon-reply-all1:before {
  content: "\eca2";
}
.ficon-republican1:before {
  content: "\eca3";
}
.ficon-retweet1:before {
  content: "\eca4";
}
.ficon-retweet-alt1:before {
  content: "\eca5";
}
.ficon-ribbon1:before {
  content: "\eca6";
}
.ficon-ring1:before {
  content: "\eca7";
}
.ficon-road1:before {
  content: "\eca8";
}
.ficon-robot1:before {
  content: "\eca9";
}
.ficon-rocket1:before {
  content: "\ecaa";
}
.ficon-route1:before {
  content: "\ecab";
}
.ficon-route-highway1:before {
  content: "\ecac";
}
.ficon-route-interstate1:before {
  content: "\ee57";
}
.ficon-rss1:before {
  content: "\ecad";
}
.ficon-rss-square1:before {
  content: "\ecae";
}
.ficon-ruble-sign1:before {
  content: "\ecaf";
}
.ficon-ruler1:before {
  content: "\ecb0";
}
.ficon-ruler-combined1:before {
  content: "\ecb1";
}
.ficon-ruler-horizontal1:before {
  content: "\ecb2";
}
.ficon-ruler-triangle1:before {
  content: "\ecb3";
}
.ficon-ruler-vertical1:before {
  content: "\ecb4";
}
.ficon-running1:before {
  content: "\ecb5";
}
.ficon-rupee-sign1:before {
  content: "\ecb6";
}
.ficon-sad-cry1:before {
  content: "\ecb7";
}
.ficon-sad-tear1:before {
  content: "\ecb8";
}
.ficon-save1:before {
  content: "\ecb9";
}
.ficon-scalpel1:before {
  content: "\ecba";
}
.ficon-scalpel-path1:before {
  content: "\ee58";
}
.ficon-scanner1:before {
  content: "\ecbb";
}
.ficon-scanner-keyboard1:before {
  content: "\ecbc";
}
.ficon-scanner-touchscreen1:before {
  content: "\ecbd";
}
.ficon-scarecrow1:before {
  content: "\ecbe";
}
.ficon-school1:before {
  content: "\ecbf";
}
.ficon-screwdriver1:before {
  content: "\ecc0";
}
.ficon-scroll1:before {
  content: "\ecc1";
}
.ficon-scroll-old1:before {
  content: "\ecc2";
}
.ficon-scrubber1:before {
  content: "\ecc3";
}
.ficon-scythe1:before {
  content: "\ecc4";
}
.ficon-search1:before {
  content: "\ecc5";
}
.ficon-search-dollar1:before {
  content: "\ecc6";
}
.ficon-search-location1:before {
  content: "\ecc7";
}
.ficon-search-minus1:before {
  content: "\ecc8";
}
.ficon-search-plus1:before {
  content: "\ee59";
}
.ficon-seedling1:before {
  content: "\ecc9";
}
.ficon-server1:before {
  content: "\ecca";
}
.ficon-shapes1:before {
  content: "\eccb";
}
.ficon-share1:before {
  content: "\eccc";
}
.ficon-share-all1:before {
  content: "\eccd";
}
.ficon-share-alt1:before {
  content: "\ecce";
}
.ficon-share-alt-square1:before {
  content: "\eccf";
}
.ficon-share-square1:before {
  content: "\ecd0";
}
.ficon-sheep1:before {
  content: "\ecd1";
}
.ficon-shekel-sign1:before {
  content: "\ecd2";
}
.ficon-shield1:before {
  content: "\ecd3";
}
.ficon-shield-alt1:before {
  content: "\ecd4";
}
.ficon-shield-check1:before {
  content: "\ecd5";
}
.ficon-shield-cross1:before {
  content: "\ecd6";
}
.ficon-ship1:before {
  content: "\ee5a";
}
.ficon-shipping-fast1:before {
  content: "\ecd7";
}
.ficon-shipping-timed1:before {
  content: "\ecd8";
}
.ficon-shoe-prints1:before {
  content: "\ecd9";
}
.ficon-shopping-bag1:before {
  content: "\ecda";
}
.ficon-shopping-basket1:before {
  content: "\ecdb";
}
.ficon-shopping-cart1:before {
  content: "\ecdc";
}
.ficon-shovel1:before {
  content: "\ecdd";
}
.ficon-shower1:before {
  content: "\ecde";
}
.ficon-shredder1:before {
  content: "\ecdf";
}
.ficon-shuttlecock1:before {
  content: "\ece0";
}
.ficon-shuttle-van1:before {
  content: "\ece1";
}
.ficon-sigma1:before {
  content: "\ece2";
}
.ficon-sign1:before {
  content: "\ece3";
}
.ficon-signal1:before {
  content: "\ece4";
}
.ficon-signal-11:before {
  content: "\ee5b";
}
.ficon-signal-21:before {
  content: "\ece5";
}
.ficon-signal-31:before {
  content: "\ece6";
}
.ficon-signal-41:before {
  content: "\ece7";
}
.ficon-signal-alt1:before {
  content: "\ece8";
}
.ficon-signal-alt-11:before {
  content: "\ece9";
}
.ficon-signal-alt-21:before {
  content: "\ecea";
}
.ficon-signal-alt-31:before {
  content: "\eceb";
}
.ficon-signal-alt-slash1:before {
  content: "\ecec";
}
.ficon-signal-slash1:before {
  content: "\eced";
}
.ficon-signature1:before {
  content: "\ecee";
}
.ficon-sign-in1:before {
  content: "\ecef";
}
.ficon-sign-in-alt1:before {
  content: "\ecf0";
}
.ficon-sign-language1:before {
  content: "\ecf1";
}
.ficon-sign-out1:before {
  content: "\ecf2";
}
.ficon-sign-out-alt1:before {
  content: "\ee5c";
}
.ficon-sitemap1:before {
  content: "\ecf3";
}
.ficon-skeleton1:before {
  content: "\ecf4";
}
.ficon-skull1:before {
  content: "\ecf5";
}
.ficon-skull-crossbones1:before {
  content: "\ecf6";
}
.ficon-slash1:before {
  content: "\ecf7";
}
.ficon-sliders-h1:before {
  content: "\ecf8";
}
.ficon-sliders-h-square1:before {
  content: "\ecf9";
}
.ficon-sliders-v1:before {
  content: "\ecfa";
}
.ficon-sliders-v-square1:before {
  content: "\ecfb";
}
.ficon-smile1:before {
  content: "\ecfc";
}
.ficon-smile-beam1:before {
  content: "\ecfd";
}
.ficon-smile-plus1:before {
  content: "\ecfe";
}
.ficon-smile-wink1:before {
  content: "\ecff";
}
.ficon-smog1:before {
  content: "\ed00";
}
.ficon-smoke1:before {
  content: "\ee5d";
}
.ficon-smoking1:before {
  content: "\ed01";
}
.ficon-smoking-ban1:before {
  content: "\ed02";
}
.ficon-snake1:before {
  content: "\ed03";
}
.ficon-snow-blowing1:before {
  content: "\ed04";
}
.ficon-snowflake1:before {
  content: "\ed05";
}
.ficon-socks1:before {
  content: "\ed06";
}
.ficon-solar-panel1:before {
  content: "\ed07";
}
.ficon-sort1:before {
  content: "\ed08";
}
.ficon-sort-alpha-down1:before {
  content: "\ed09";
}
.ficon-sort-alpha-up1:before {
  content: "\ed0a";
}
.ficon-sort-amount-down1:before {
  content: "\ed0b";
}
.ficon-sort-amount-up1:before {
  content: "\ed0c";
}
.ficon-sort-down1:before {
  content: "\ed0d";
}
.ficon-sort-numeric-down1:before {
  content: "\ed0e";
}
.ficon-sort-numeric-up1:before {
  content: "\ee5e";
}
.ficon-sort-up1:before {
  content: "\ed0f";
}
.ficon-spa1:before {
  content: "\ed10";
}
.ficon-space-shuttle1:before {
  content: "\ed11";
}
.ficon-spade1:before {
  content: "\ed12";
}
.ficon-spider1:before {
  content: "\ed13";
}
.ficon-spider-black-widow1:before {
  content: "\ed14";
}
.ficon-spider-web1:before {
  content: "\ed15";
}
.ficon-spinner1:before {
  content: "\ed16";
}
.ficon-spinner-third1:before {
  content: "\ed17";
}
.ficon-splotch1:before {
  content: "\ed18";
}
.ficon-spray-can1:before {
  content: "\ed19";
}
.ficon-square1:before {
  content: "\ed1a";
}
.ficon-square-full1:before {
  content: "\ed1b";
}
.ficon-square-root1:before {
  content: "\ed1c";
}
.ficon-square-root-alt1:before {
  content: "\ee5f";
}
.ficon-squirrel1:before {
  content: "\ed1d";
}
.ficon-staff1:before {
  content: "\ed1e";
}
.ficon-stamp1:before {
  content: "\ed1f";
}
.ficon-star1:before {
  content: "\ed20";
}
.ficon-star-and-crescent1:before {
  content: "\ed21";
}
.ficon-star-exclamation1:before {
  content: "\ed22";
}
.ficon-star-half1:before {
  content: "\ed23";
}
.ficon-star-half-alt1:before {
  content: "\ed24";
}
.ficon-star-of-david1:before {
  content: "\ed25";
}
.ficon-star-of-life1:before {
  content: "\ed26";
}
.ficon-stars1:before {
  content: "\ed27";
}
.ficon-steering-wheel1:before {
  content: "\ed28";
}
.ficon-step-backward1:before {
  content: "\ed29";
}
.ficon-step-forward1:before {
  content: "\ed2a";
}
.ficon-stethoscope1:before {
  content: "\ee60";
}
.ficon-sticky-note1:before {
  content: "\ed2b";
}
.ficon-stomach1:before {
  content: "\ed2c";
}
.ficon-stop1:before {
  content: "\ed2d";
}
.ficon-stop-circle1:before {
  content: "\ed2e";
}
.ficon-stopwatch1:before {
  content: "\ed2f";
}
.ficon-store1:before {
  content: "\ed30";
}
.ficon-store-alt1:before {
  content: "\ed31";
}
.ficon-stream1:before {
  content: "\ed32";
}
.ficon-street-view1:before {
  content: "\ed33";
}
.ficon-strikethrough1:before {
  content: "\ed34";
}
.ficon-stroopwafel1:before {
  content: "\ed35";
}
.ficon-subscript1:before {
  content: "\ed36";
}
.ficon-subway1:before {
  content: "\ed37";
}
.ficon-suitcase1:before {
  content: "\ed38";
}
.ficon-suitcase-rolling1:before {
  content: "\ee61";
}
.ficon-sun1:before {
  content: "\ed39";
}
.ficon-sun-cloud1:before {
  content: "\ed3a";
}
.ficon-sun-dust1:before {
  content: "\ed3b";
}
.ficon-sun-haze1:before {
  content: "\ed3c";
}
.ficon-sunrise1:before {
  content: "\ed3d";
}
.ficon-sunset1:before {
  content: "\ed3e";
}
.ficon-superscript1:before {
  content: "\ed3f";
}
.ficon-surprise1:before {
  content: "\ed40";
}
.ficon-swatchbook1:before {
  content: "\ed41";
}
.ficon-swimmer1:before {
  content: "\ed42";
}
.ficon-swimming-pool1:before {
  content: "\ed43";
}
.ficon-sword1:before {
  content: "\ed44";
}
.ficon-swords1:before {
  content: "\ed45";
}
.ficon-synagogue1:before {
  content: "\ed46";
}
.ficon-sync1:before {
  content: "\ee62";
}
.ficon-sync-alt1:before {
  content: "\ed47";
}
.ficon-syringe1:before {
  content: "\ed48";
}
.ficon-table1:before {
  content: "\ed49";
}
.ficon-tablet1:before {
  content: "\ed4a";
}
.ficon-tablet-alt1:before {
  content: "\ed4b";
}
.ficon-tablet-android1:before {
  content: "\ed4c";
}
.ficon-tablet-android-alt1:before {
  content: "\ed4d";
}
.ficon-table-tennis1:before {
  content: "\ed4e";
}
.ficon-tablet-rugged1:before {
  content: "\ed4f";
}
.ficon-tablets1:before {
  content: "\ed50";
}
.ficon-tachometer1:before {
  content: "\ed51";
}
.ficon-tachometer-alt1:before {
  content: "\ed52";
}
.ficon-tachometer-alt-average1:before {
  content: "\ed53";
}
.ficon-tachometer-alt-fast1:before {
  content: "\ed54";
}
.ficon-tachometer-alt-fastest1:before {
  content: "\ee63";
}
.ficon-tachometer-alt-slow1:before {
  content: "\ed55";
}
.ficon-tachometer-alt-slowest1:before {
  content: "\ed56";
}
.ficon-tachometer-average1:before {
  content: "\ed57";
}
.ficon-tachometer-fast1:before {
  content: "\ed58";
}
.ficon-tachometer-fastest1:before {
  content: "\ed59";
}
.ficon-tachometer-slow1:before {
  content: "\ed5a";
}
.ficon-tachometer-slowest1:before {
  content: "\ed5b";
}
.ficon-tag1:before {
  content: "\ed5c";
}
.ficon-tags1:before {
  content: "\ed5d";
}
.ficon-tally1:before {
  content: "\ed5e";
}
.ficon-tape1:before {
  content: "\ed5f";
}
.ficon-tasks1:before {
  content: "\ed60";
}
.ficon-taxi1:before {
  content: "\ed61";
}
.ficon-teeth1:before {
  content: "\ed62";
}
.ficon-teeth-open1:before {
  content: "\ee64";
}
.ficon-temperature-frigid1:before {
  content: "\ed63";
}
.ficon-temperature-high1:before {
  content: "\ed64";
}
.ficon-temperature-hot1:before {
  content: "\ed65";
}
.ficon-temperature-low1:before {
  content: "\ed66";
}
.ficon-tennis-ball1:before {
  content: "\ed67";
}
.ficon-terminal1:before {
  content: "\ed68";
}
.ficon-text-height1:before {
  content: "\ed69";
}
.ficon-text-width1:before {
  content: "\ed6a";
}
.ficon-th1:before {
  content: "\ed6b";
}
.ficon-theater-masks1:before {
  content: "\ed6c";
}
.ficon-thermometer1:before {
  content: "\ed6d";
}
.ficon-thermometer-empty1:before {
  content: "\ed6e";
}
.ficon-thermometer-full1:before {
  content: "\ed6f";
}
.ficon-thermometer-half1:before {
  content: "\ed70";
}
.ficon-thermometer-quarter1:before {
  content: "\ee65";
}
.ficon-thermometer-three-quarters1:before {
  content: "\ed71";
}
.ficon-theta1:before {
  content: "\ed72";
}
.ficon-th-large1:before {
  content: "\ed73";
}
.ficon-th-list1:before {
  content: "\ed74";
}
.ficon-thumbs-down1:before {
  content: "\ed75";
}
.ficon-thumbs-up1:before {
  content: "\ed76";
}
.ficon-thumbtack1:before {
  content: "\ed77";
}
.ficon-thunderstorm1:before {
  content: "\ed78";
}
.ficon-thunderstorm-moon1:before {
  content: "\ed79";
}
.ficon-thunderstorm-sun1:before {
  content: "\ed7a";
}
.ficon-ticket1:before {
  content: "\ed7b";
}
.ficon-ticket-alt1:before {
  content: "\ed7c";
}
.ficon-tilde1:before {
  content: "\ed7d";
}
.ficon-times1:before {
  content: "\ed7e";
}
.ficon-times-circle1:before {
  content: "\ee66";
}
.ficon-times-hexagon1:before {
  content: "\ed7f";
}
.ficon-times-octagon1:before {
  content: "\ed80";
}
.ficon-times-square1:before {
  content: "\ed81";
}
.ficon-tint1:before {
  content: "\ed82";
}
.ficon-tint-slash1:before {
  content: "\ed83";
}
.ficon-tire1:before {
  content: "\ed84";
}
.ficon-tired1:before {
  content: "\ed85";
}
.ficon-tire-flat1:before {
  content: "\ed86";
}
.ficon-tire-pressure-warning1:before {
  content: "\ed87";
}
.ficon-tire-rugged1:before {
  content: "\ed88";
}
.ficon-toggle-off1:before {
  content: "\ed89";
}
.ficon-toggle-on1:before {
  content: "\ed8a";
}
.ficon-toilet-paper1:before {
  content: "\ed8b";
}
.ficon-toilet-paper-alt1:before {
  content: "\ed8c";
}
.ficon-tombstone1:before {
  content: "\ee67";
}
.ficon-tombstone-alt1:before {
  content: "\ed8d";
}
.ficon-toolbox1:before {
  content: "\ed8e";
}
.ficon-tooth1:before {
  content: "\ed8f";
}
.ficon-toothbrush1:before {
  content: "\ed90";
}
.ficon-torah1:before {
  content: "\ed91";
}
.ficon-torii-gate1:before {
  content: "\ed92";
}
.ficon-tornado1:before {
  content: "\ed93";
}
.ficon-tractor1:before {
  content: "\ed94";
}
.ficon-trademark1:before {
  content: "\ed95";
}
.ficon-traffic-cone1:before {
  content: "\ed96";
}
.ficon-traffic-light1:before {
  content: "\ed97";
}
.ficon-traffic-light-go1:before {
  content: "\ed98";
}
.ficon-traffic-light-slow1:before {
  content: "\ed99";
}
.ficon-traffic-light-stop1:before {
  content: "\ed9a";
}
.ficon-train1:before {
  content: "\ee68";
}
.ficon-transgender1:before {
  content: "\ed9b";
}
.ficon-transgender-alt1:before {
  content: "\ed9c";
}
.ficon-trash1:before {
  content: "\ed9d";
}
.ficon-trash-alt1:before {
  content: "\ed9e";
}
.ficon-treasure-chest1:before {
  content: "\ed9f";
}
.ficon-tree1:before {
  content: "\eda0";
}
.ficon-tree-alt1:before {
  content: "\eda1";
}
.ficon-trees1:before {
  content: "\eda2";
}
.ficon-triangle1:before {
  content: "\eda3";
}
.ficon-trophy1:before {
  content: "\eda4";
}
.ficon-trophy-alt1:before {
  content: "\eda5";
}
.ficon-truck1:before {
  content: "\eda6";
}
.ficon-truck-container1:before {
  content: "\eda7";
}
.ficon-truck-couch1:before {
  content: "\eda8";
}
.ficon-truck-loading1:before {
  content: "\ee69";
}
.ficon-truck-monster1:before {
  content: "\eda9";
}
.ficon-truck-moving1:before {
  content: "\edaa";
}
.ficon-truck-pickup1:before {
  content: "\edab";
}
.ficon-truck-ramp1:before {
  content: "\edac";
}
.ficon-tshirt1:before {
  content: "\edad";
}
.ficon-tty1:before {
  content: "\edae";
}
.ficon-turkey1:before {
  content: "\edaf";
}
.ficon-turtle1:before {
  content: "\edb0";
}
.ficon-tv1:before {
  content: "\edb1";
}
.ficon-tv-retro1:before {
  content: "\edb2";
}
.ficon-umbrella1:before {
  content: "\edb3";
}
.ficon-umbrella-beach1:before {
  content: "\edb4";
}
.ficon-underline1:before {
  content: "\edb5";
}
.ficon-undo1:before {
  content: "\edb6";
}
.ficon-undo-alt1:before {
  content: "\ee6a";
}
.ficon-unicorn1:before {
  content: "\edb7";
}
.ficon-union1:before {
  content: "\edb8";
}
.ficon-universal-access1:before {
  content: "\edb9";
}
.ficon-university1:before {
  content: "\edba";
}
.ficon-unlink1:before {
  content: "\edbb";
}
.ficon-unlock1:before {
  content: "\edbc";
}
.ficon-unlock-alt1:before {
  content: "\edbd";
}
.ficon-upload1:before {
  content: "\edbe";
}
.ficon-usd-circle1:before {
  content: "\edbf";
}
.ficon-usd-square1:before {
  content: "\edc0";
}
.ficon-user1:before {
  content: "\edc1";
}
.ficon-user-alt1:before {
  content: "\edc2";
}
.ficon-user-alt-slash1:before {
  content: "\edc3";
}
.ficon-user-astronaut1:before {
  content: "\edc4";
}
.ficon-user-chart1:before {
  content: "\ee6b";
}
.ficon-user-check1:before {
  content: "\edc5";
}
.ficon-user-circle1:before {
  content: "\edc6";
}
.ficon-user-clock1:before {
  content: "\edc7";
}
.ficon-user-cog1:before {
  content: "\edc8";
}
.ficon-user-crown1:before {
  content: "\edc9";
}
.ficon-user-edit1:before {
  content: "\edca";
}
.ficon-user-friends1:before {
  content: "\edcb";
}
.ficon-user-graduate1:before {
  content: "\edcc";
}
.ficon-user-injured1:before {
  content: "\edcd";
}
.ficon-user-lock1:before {
  content: "\edce";
}
.ficon-user-md1:before {
  content: "\edcf";
}
.ficon-user-minus1:before {
  content: "\edd0";
}
.ficon-user-ninja1:before {
  content: "\edd1";
}
.ficon-user-plus1:before {
  content: "\edd2";
}
.ficon-users1:before {
  content: "\ee6c";
}
.ficon-users-class1:before {
  content: "\edd3";
}
.ficon-users-cog1:before {
  content: "\edd4";
}
.ficon-users-crown1:before {
  content: "\edd5";
}
.ficon-user-secret1:before {
  content: "\edd6";
}
.ficon-user-shield1:before {
  content: "\edd7";
}
.ficon-user-slash1:before {
  content: "\edd8";
}
.ficon-user-tag1:before {
  content: "\edd9";
}
.ficon-user-tie1:before {
  content: "\edda";
}
.ficon-user-times1:before {
  content: "\eddb";
}
.ficon-utensil-fork1:before {
  content: "\eddc";
}
.ficon-utensil-knife1:before {
  content: "\eddd";
}
.ficon-utensils1:before {
  content: "\edde";
}
.ficon-utensils-alt1:before {
  content: "\eddf";
}
.ficon-utensil-spoon1:before {
  content: "\ede0";
}
.ficon-value-absolute1:before {
  content: "\ee6d";
}
.ficon-vector-square1:before {
  content: "\ede1";
}
.ficon-venus1:before {
  content: "\ede2";
}
.ficon-venus-double1:before {
  content: "\ede3";
}
.ficon-venus-mars1:before {
  content: "\ede4";
}
.ficon-vial1:before {
  content: "\ede5";
}
.ficon-vials1:before {
  content: "\ede6";
}
.ficon-video1:before {
  content: "\ede7";
}
.ficon-video-plus1:before {
  content: "\ede8";
}
.ficon-video-slash1:before {
  content: "\ede9";
}
.ficon-vihara1:before {
  content: "\edea";
}
.ficon-volcano1:before {
  content: "\edeb";
}
.ficon-volleyball-ball1:before {
  content: "\edec";
}
.ficon-volume1:before {
  content: "\eded";
}
.ficon-volume-down1:before {
  content: "\edee";
}
.ficon-volume-mute1:before {
  content: "\ee6e";
}
.ficon-volume-off1:before {
  content: "\edef";
}
.ficon-volume-slash1:before {
  content: "\edf0";
}
.ficon-volume-up1:before {
  content: "\edf1";
}
.ficon-vote-nay1:before {
  content: "\edf2";
}
.ficon-vote-yea1:before {
  content: "\edf3";
}
.ficon-vr-cardboard1:before {
  content: "\edf4";
}
.ficon-walking1:before {
  content: "\edf5";
}
.ficon-wallet1:before {
  content: "\edf6";
}
.ficon-wand1:before {
  content: "\edf7";
}
.ficon-wand-magic1:before {
  content: "\edf8";
}
.ficon-warehouse1:before {
  content: "\edf9";
}
.ficon-warehouse-alt1:before {
  content: "\edfa";
}
.ficon-watch1:before {
  content: "\edfb";
}
.ficon-watch-fitness1:before {
  content: "\edfc";
}
.ficon-water1:before {
  content: "\ee6f";
}
.ficon-water-lower1:before {
  content: "\edfd";
}
.ficon-water-rise1:before {
  content: "\edfe";
}
.ficon-weight1:before {
  content: "\edff";
}
.ficon-weight-hanging1:before {
  content: "\ee00";
}
.ficon-whale1:before {
  content: "\ee01";
}
.ficon-wheat1:before {
  content: "\ee02";
}
.ficon-wheelchair1:before {
  content: "\ee03";
}
.ficon-whistle1:before {
  content: "\ee04";
}
.ficon-wifi1:before {
  content: "\ee05";
}
.ficon-wifi-11:before {
  content: "\ee06";
}
.ficon-wifi-21:before {
  content: "\ee07";
}
.ficon-wifi-slash1:before {
  content: "\ee08";
}
.ficon-wind1:before {
  content: "\ee09";
}
.ficon-window1:before {
  content: "\ee0a";
}
.ficon-window-alt1:before {
  content: "\ee70";
}
.ficon-window-close1:before {
  content: "\ee0b";
}
.ficon-window-maximize1:before {
  content: "\ee0c";
}
.ficon-window-minimize1:before {
  content: "\ee0d";
}
.ficon-window-restore1:before {
  content: "\ee0e";
}
.ficon-windsock1:before {
  content: "\ee0f";
}
.ficon-wind-warning1:before {
  content: "\ee10";
}
.ficon-wine-bottle1:before {
  content: "\ee11";
}
.ficon-wine-glass1:before {
  content: "\ee12";
}
.ficon-wine-glass-alt1:before {
  content: "\ee13";
}
.ficon-won-sign1:before {
  content: "\ee14";
}
.ficon-wrench1:before {
  content: "\ee71";
}
.ficon-x-ray1:before {
  content: "\ee72";
}
.ficon-yen-sign1:before {
  content: "\ee73";
}
.ficon-yin-yang1:before {
  content: "\ee74";
}
.ficon-abacus11:before {
  content: "\ee75";
}
.ficon-acorn11:before {
  content: "\ee76";
}
.ficon-ad11:before {
  content: "\ee77";
}
.ficon-address-book11:before {
  content: "\ee78";
}
.ficon-address-card11:before {
  content: "\ee79";
}
.ficon-adjust11:before {
  content: "\ee7a";
}
.ficon-air-freshener11:before {
  content: "\ee7b";
}
.ficon-alarm-clock11:before {
  content: "\ee7c";
}
.ficon-alicorn11:before {
  content: "\ee7d";
}
.ficon-align-center11:before {
  content: "\ee7e";
}
.ficon-align-justify11:before {
  content: "\ee7f";
}
.ficon-align-left11:before {
  content: "\ee80";
}
.ficon-align-right11:before {
  content: "\ee81";
}
.ficon-allergies11:before {
  content: "\ee82";
}
.ficon-ambulance11:before {
  content: "\ee83";
}
.ficon-american-sign-language-interpreting11:before {
  content: "\ee84";
}
.ficon-analytics11:before {
  content: "\ee85";
}
.ficon-anchor11:before {
  content: "\ee86";
}
.ficon-angle-double-down11:before {
  content: "\ee87";
}
.ficon-angle-double-left11:before {
  content: "\ee88";
}
.ficon-angle-double-right11:before {
  content: "\ee89";
}
.ficon-angle-double-up11:before {
  content: "\ee8a";
}
.ficon-angle-down11:before {
  content: "\ee8b";
}
.ficon-angle-left11:before {
  content: "\ee8c";
}
.ficon-angle-right11:before {
  content: "\ee8d";
}
.ficon-angle-up11:before {
  content: "\ee8e";
}
.ficon-angry11:before {
  content: "\ee8f";
}
.ficon-ankh11:before {
  content: "\ee90";
}
.ficon-apple-alt11:before {
  content: "\ee91";
}
.ficon-apple-crate11:before {
  content: "\ee92";
}
.ficon-archive11:before {
  content: "\ee93";
}
.ficon-archway11:before {
  content: "\ee94";
}
.ficon-arrow-alt-circle-down11:before {
  content: "\ee95";
}
.ficon-arrow-alt-circle-left11:before {
  content: "\ee96";
}
.ficon-arrow-alt-circle-right11:before {
  content: "\ee97";
}
.ficon-arrow-alt-circle-up11:before {
  content: "\ee98";
}
.ficon-arrow-alt-down11:before {
  content: "\ee99";
}
.ficon-arrow-alt-from-bottom11:before {
  content: "\ee9a";
}
.ficon-arrow-alt-from-left11:before {
  content: "\ee9b";
}
.ficon-arrow-alt-from-right11:before {
  content: "\ee9c";
}
.ficon-arrow-alt-from-top11:before {
  content: "\ee9d";
}
.ficon-arrow-alt-left11:before {
  content: "\ee9e";
}
.ficon-arrow-alt-right11:before {
  content: "\ee9f";
}
.ficon-arrow-alt-square-down11:before {
  content: "\eea0";
}
.ficon-arrow-alt-square-left11:before {
  content: "\eea1";
}
.ficon-arrow-alt-square-right11:before {
  content: "\eea2";
}
.ficon-arrow-alt-square-up11:before {
  content: "\eea3";
}
.ficon-arrow-alt-to-bottom11:before {
  content: "\eea4";
}
.ficon-arrow-alt-to-left11:before {
  content: "\eea5";
}
.ficon-arrow-alt-to-right11:before {
  content: "\eea6";
}
.ficon-arrow-alt-to-top11:before {
  content: "\eea7";
}
.ficon-arrow-alt-up11:before {
  content: "\eea8";
}
.ficon-arrow-circle-down11:before {
  content: "\eea9";
}
.ficon-arrow-circle-left11:before {
  content: "\eeaa";
}
.ficon-arrow-circle-right11:before {
  content: "\eeab";
}
.ficon-arrow-circle-up11:before {
  content: "\eeac";
}
.ficon-arrow-down11:before {
  content: "\eead";
}
.ficon-arrow-from-bottom11:before {
  content: "\eeae";
}
.ficon-arrow-from-left11:before {
  content: "\eeaf";
}
.ficon-arrow-from-right11:before {
  content: "\eeb0";
}
.ficon-arrow-from-top11:before {
  content: "\eeb1";
}
.ficon-arrow-left11:before {
  content: "\eeb2";
}
.ficon-arrow-right11:before {
  content: "\eeb3";
}
.ficon-arrows11:before {
  content: "\eeb4";
}
.ficon-arrows-alt11:before {
  content: "\eeb5";
}
.ficon-arrows-alt-h11:before {
  content: "\eeb6";
}
.ficon-arrows-alt-v11:before {
  content: "\eeb7";
}
.ficon-arrows-h11:before {
  content: "\eeb8";
}
.ficon-arrow-square-down11:before {
  content: "\eeb9";
}
.ficon-arrow-square-left11:before {
  content: "\eeba";
}
.ficon-arrow-square-right11:before {
  content: "\eebb";
}
.ficon-arrow-square-up11:before {
  content: "\eebc";
}
.ficon-arrows-v11:before {
  content: "\eebd";
}
.ficon-arrow-to-bottom11:before {
  content: "\eebe";
}
.ficon-arrow-to-left11:before {
  content: "\eebf";
}
.ficon-arrow-to-right11:before {
  content: "\eec0";
}
.ficon-arrow-to-top11:before {
  content: "\eec1";
}
.ficon-arrow-up11:before {
  content: "\eec2";
}
.ficon-assistive-listening-systems11:before {
  content: "\eec3";
}
.ficon-asterisk11:before {
  content: "\eec4";
}
.ficon-at11:before {
  content: "\eec5";
}
.ficon-atlas11:before {
  content: "\eec6";
}
.ficon-atom11:before {
  content: "\eec7";
}
.ficon-atom-alt11:before {
  content: "\eec8";
}
.ficon-audio-description11:before {
  content: "\eec9";
}
.ficon-award11:before {
  content: "\eeca";
}
.ficon-axe11:before {
  content: "\eecb";
}
.ficon-axe-battle11:before {
  content: "\eecc";
}
.ficon-backpack11:before {
  content: "\eecd";
}
.ficon-backspace11:before {
  content: "\eece";
}
.ficon-backward11:before {
  content: "\eecf";
}
.ficon-badge11:before {
  content: "\eed0";
}
.ficon-badge-check11:before {
  content: "\eed1";
}
.ficon-badge-dollar11:before {
  content: "\eed2";
}
.ficon-badge-percent11:before {
  content: "\eed3";
}
.ficon-badger-honey11:before {
  content: "\eed4";
}
.ficon-balance-scale11:before {
  content: "\eed5";
}
.ficon-balance-scale-left11:before {
  content: "\eed6";
}
.ficon-balance-scale-right11:before {
  content: "\eed7";
}
.ficon-ballot11:before {
  content: "\eed8";
}
.ficon-ballot-check11:before {
  content: "\eed9";
}
.ficon-ban11:before {
  content: "\eeda";
}
.ficon-band-aid11:before {
  content: "\eedb";
}
.ficon-barcode11:before {
  content: "\eedc";
}
.ficon-barcode-alt11:before {
  content: "\eedd";
}
.ficon-barcode-read11:before {
  content: "\eede";
}
.ficon-barcode-scan11:before {
  content: "\eedf";
}
.ficon-bars11:before {
  content: "\eee0";
}
.ficon-baseball11:before {
  content: "\eee1";
}
.ficon-baseball-ball11:before {
  content: "\eee2";
}
.ficon-basketball-ball11:before {
  content: "\eee3";
}
.ficon-basketball-hoop11:before {
  content: "\eee4";
}
.ficon-bat11:before {
  content: "\eee5";
}
.ficon-bath11:before {
  content: "\eee6";
}
.ficon-battery-bolt11:before {
  content: "\eee7";
}
.ficon-battery-empty11:before {
  content: "\eee8";
}
.ficon-battery-full11:before {
  content: "\eee9";
}
.ficon-battery-half11:before {
  content: "\eeea";
}
.ficon-battery-quarter11:before {
  content: "\eeeb";
}
.ficon-battery-slash11:before {
  content: "\eeec";
}
.ficon-battery-three-quarters11:before {
  content: "\eeed";
}
.ficon-bed11:before {
  content: "\eeee";
}
.ficon-beer11:before {
  content: "\eeef";
}
.ficon-bell11:before {
  content: "\eef0";
}
.ficon-bell-school11:before {
  content: "\eef1";
}
.ficon-bell-school-slash11:before {
  content: "\eef2";
}
.ficon-bell-slash11:before {
  content: "\eef3";
}
.ficon-bezier-curve11:before {
  content: "\eef4";
}
.ficon-bible11:before {
  content: "\eef5";
}
.ficon-bicycle11:before {
  content: "\eef6";
}
.ficon-binoculars11:before {
  content: "\eef7";
}
.ficon-birthday-cake11:before {
  content: "\eef8";
}
.ficon-blanket11:before {
  content: "\eef9";
}
.ficon-blender11:before {
  content: "\eefa";
}
.ficon-blender-phone11:before {
  content: "\eefb";
}
.ficon-blind11:before {
  content: "\eefc";
}
.ficon-bold11:before {
  content: "\eefd";
}
.ficon-bolt11:before {
  content: "\eefe";
}
.ficon-bomb11:before {
  content: "\eeff";
}
.ficon-bone11:before {
  content: "\ef00";
}
.ficon-bone-break11:before {
  content: "\ef01";
}
.ficon-bong11:before {
  content: "\ef02";
}
.ficon-book11:before {
  content: "\ef03";
}
.ficon-book-alt11:before {
  content: "\ef04";
}
.ficon-book-dead11:before {
  content: "\ef05";
}
.ficon-book-heart11:before {
  content: "\ef06";
}
.ficon-bookmark11:before {
  content: "\ef07";
}
.ficon-book-open11:before {
  content: "\ef08";
}
.ficon-book-reader11:before {
  content: "\ef09";
}
.ficon-books11:before {
  content: "\ef0a";
}
.ficon-book-spells11:before {
  content: "\ef0b";
}
.ficon-booth-curtain11:before {
  content: "\ef0c";
}
.ficon-bow-arrow11:before {
  content: "\ef0d";
}
.ficon-bowling-ball11:before {
  content: "\ef0e";
}
.ficon-bowling-pins11:before {
  content: "\ef0f";
}
.ficon-box11:before {
  content: "\ef10";
}
.ficon-box-alt11:before {
  content: "\ef11";
}
.ficon-box-ballot11:before {
  content: "\ef12";
}
.ficon-box-check11:before {
  content: "\ef13";
}
.ficon-boxes11:before {
  content: "\ef14";
}
.ficon-boxes-alt11:before {
  content: "\ef15";
}
.ficon-box-fragile11:before {
  content: "\ef16";
}
.ficon-box-full11:before {
  content: "\ef17";
}
.ficon-box-heart11:before {
  content: "\ef18";
}
.ficon-boxing-glove11:before {
  content: "\ef19";
}
.ficon-box-open11:before {
  content: "\ef1a";
}
.ficon-box-up11:before {
  content: "\ef1b";
}
.ficon-box-usd11:before {
  content: "\ef1c";
}
.ficon-braille11:before {
  content: "\ef1d";
}
.ficon-brain11:before {
  content: "\ef1e";
}
.ficon-briefcase11:before {
  content: "\ef1f";
}
.ficon-briefcase-medical11:before {
  content: "\ef20";
}
.ficon-broadcast-tower11:before {
  content: "\ef21";
}
.ficon-broom11:before {
  content: "\ef22";
}
.ficon-browser11:before {
  content: "\ef23";
}
.ficon-brush11:before {
  content: "\ef24";
}
.ficon-bug11:before {
  content: "\ef25";
}
.ficon-building11:before {
  content: "\ef26";
}
.ficon-bullhorn11:before {
  content: "\ef27";
}
.ficon-bullseye11:before {
  content: "\ef28";
}
.ficon-bullseye-arrow11:before {
  content: "\ef29";
}
.ficon-bullseye-pointer11:before {
  content: "\ef2a";
}
.ficon-burn11:before {
  content: "\ef2b";
}
.ficon-bus11:before {
  content: "\ef2c";
}
.ficon-bus-alt11:before {
  content: "\ef2d";
}
.ficon-business-time11:before {
  content: "\ef2e";
}
.ficon-bus-school11:before {
  content: "\ef2f";
}
.ficon-cabinet-filing11:before {
  content: "\ef30";
}
.ficon-calculator11:before {
  content: "\ef31";
}
.ficon-calculator-alt11:before {
  content: "\ef32";
}
.ficon-calendar11:before {
  content: "\ef33";
}
.ficon-calendar-alt11:before {
  content: "\ef34";
}
.ficon-calendar-check11:before {
  content: "\ef35";
}
.ficon-calendar-edit11:before {
  content: "\ef36";
}
.ficon-calendar-exclamation11:before {
  content: "\ef37";
}
.ficon-calendar-minus11:before {
  content: "\ef38";
}
.ficon-calendar-plus11:before {
  content: "\ef39";
}
.ficon-calendar-star11:before {
  content: "\ef3a";
}
.ficon-calendar-times11:before {
  content: "\ef3b";
}
.ficon-camera11:before {
  content: "\ef3c";
}
.ficon-camera-alt11:before {
  content: "\ef3d";
}
.ficon-camera-retro11:before {
  content: "\ef3e";
}
.ficon-campfire11:before {
  content: "\ef3f";
}
.ficon-campground11:before {
  content: "\ef40";
}
.ficon-candle-holder11:before {
  content: "\ef41";
}
.ficon-candy-corn11:before {
  content: "\ef42";
}
.ficon-cannabis11:before {
  content: "\ef43";
}
.ficon-capsules11:before {
  content: "\ef44";
}
.ficon-car11:before {
  content: "\ef45";
}
.ficon-car-alt11:before {
  content: "\ef46";
}
.ficon-car-battery11:before {
  content: "\ef47";
}
.ficon-car-bump11:before {
  content: "\ef48";
}
.ficon-car-crash11:before {
  content: "\ef49";
}
.ficon-caret-circle-down11:before {
  content: "\ef4a";
}
.ficon-caret-circle-left11:before {
  content: "\ef4b";
}
.ficon-caret-circle-right11:before {
  content: "\ef4c";
}
.ficon-caret-circle-up11:before {
  content: "\ef4d";
}
.ficon-caret-down11:before {
  content: "\ef4e";
}
.ficon-caret-left11:before {
  content: "\ef4f";
}
.ficon-caret-right11:before {
  content: "\ef50";
}
.ficon-caret-square-down11:before {
  content: "\ef51";
}
.ficon-caret-square-left11:before {
  content: "\ef52";
}
.ficon-caret-square-right11:before {
  content: "\ef53";
}
.ficon-caret-square-up11:before {
  content: "\ef54";
}
.ficon-caret-up11:before {
  content: "\ef55";
}
.ficon-car-garage11:before {
  content: "\ef56";
}
.ficon-car-mechanic11:before {
  content: "\ef57";
}
.ficon-car-side11:before {
  content: "\ef58";
}
.ficon-cart-arrow-down11:before {
  content: "\ef59";
}
.ficon-car-tilt11:before {
  content: "\ef5a";
}
.ficon-cart-plus11:before {
  content: "\ef5b";
}
.ficon-car-wash11:before {
  content: "\ef5c";
}
.ficon-cat11:before {
  content: "\ef5d";
}
.ficon-cauldron11:before {
  content: "\ef5e";
}
.ficon-certificate11:before {
  content: "\ef5f";
}
.ficon-chair11:before {
  content: "\ef60";
}
.ficon-chair-office11:before {
  content: "\ef61";
}
.ficon-chalkboard11:before {
  content: "\ef62";
}
.ficon-chalkboard-teacher11:before {
  content: "\ef63";
}
.ficon-charging-station11:before {
  content: "\ef64";
}
.ficon-chart-area11:before {
  content: "\ef65";
}
.ficon-chart-bar11:before {
  content: "\ef66";
}
.ficon-chart-line11:before {
  content: "\ef67";
}
.ficon-chart-line-down11:before {
  content: "\ef68";
}
.ficon-chart-pie11:before {
  content: "\ef69";
}
.ficon-chart-pie-alt11:before {
  content: "\ef6a";
}
.ficon-check11:before {
  content: "\ef6b";
}
.ficon-check-circle11:before {
  content: "\ef6c";
}
.ficon-check-double11:before {
  content: "\ef6d";
}
.ficon-check-square11:before {
  content: "\ef6e";
}
.ficon-chess11:before {
  content: "\ef6f";
}
.ficon-chess-bishop11:before {
  content: "\ef70";
}
.ficon-chess-bishop-alt11:before {
  content: "\ef71";
}
.ficon-chess-board11:before {
  content: "\ef72";
}
.ficon-chess-clock11:before {
  content: "\ef73";
}
.ficon-chess-clock-alt11:before {
  content: "\ef74";
}
.ficon-chess-king11:before {
  content: "\ef75";
}
.ficon-chess-king-alt11:before {
  content: "\ef76";
}
.ficon-chess-knight11:before {
  content: "\ef77";
}
.ficon-chess-knight-alt11:before {
  content: "\ef78";
}
.ficon-chess-pawn11:before {
  content: "\ef79";
}
.ficon-chess-pawn-alt11:before {
  content: "\ef7a";
}
.ficon-chess-queen11:before {
  content: "\ef7b";
}
.ficon-chess-queen-alt11:before {
  content: "\ef7c";
}
.ficon-chess-rook11:before {
  content: "\ef7d";
}
.ficon-chess-rook-alt11:before {
  content: "\ef7e";
}
.ficon-chevron-circle-down11:before {
  content: "\ef7f";
}
.ficon-chevron-circle-left11:before {
  content: "\ef80";
}
.ficon-chevron-circle-right11:before {
  content: "\ef81";
}
.ficon-chevron-circle-up11:before {
  content: "\ef82";
}
.ficon-chevron-double-down11:before {
  content: "\ef83";
}
.ficon-chevron-double-left11:before {
  content: "\ef84";
}
.ficon-chevron-double-right11:before {
  content: "\ef85";
}
.ficon-chevron-double-up11:before {
  content: "\ef86";
}
.ficon-chevron-down11:before {
  content: "\ef87";
}
.ficon-chevron-left11:before {
  content: "\ef88";
}
.ficon-chevron-right11:before {
  content: "\ef89";
}
.ficon-chevron-square-down11:before {
  content: "\ef8a";
}
.ficon-chevron-square-left11:before {
  content: "\ef8b";
}
.ficon-chevron-square-right11:before {
  content: "\ef8c";
}
.ficon-chevron-square-up11:before {
  content: "\ef8d";
}
.ficon-chevron-up11:before {
  content: "\ef8e";
}
.ficon-child11:before {
  content: "\ef8f";
}
.ficon-church11:before {
  content: "\ef90";
}
.ficon-circle11:before {
  content: "\ef91";
}
.ficon-circle-notch11:before {
  content: "\ef92";
}
.ficon-city11:before {
  content: "\ef93";
}
.ficon-claw-marks11:before {
  content: "\ef94";
}
.ficon-clipboard11:before {
  content: "\ef95";
}
.ficon-clipboard-check11:before {
  content: "\ef96";
}
.ficon-clipboard-list11:before {
  content: "\ef97";
}
.ficon-clipboard-list-check11:before {
  content: "\ef98";
}
.ficon-clipboard-prescription11:before {
  content: "\ef99";
}
.ficon-clock11:before {
  content: "\ef9a";
}
.ficon-clone11:before {
  content: "\ef9b";
}
.ficon-closed-captioning11:before {
  content: "\ef9c";
}
.ficon-cloud11:before {
  content: "\ef9d";
}
.ficon-cloud-download11:before {
  content: "\ef9e";
}
.ficon-cloud-download-alt11:before {
  content: "\ef9f";
}
.ficon-cloud-drizzle11:before {
  content: "\efa0";
}
.ficon-cloud-hail11:before {
  content: "\efa1";
}
.ficon-cloud-hail-mixed11:before {
  content: "\efa2";
}
.ficon-cloud-meatball11:before {
  content: "\efa3";
}
.ficon-cloud-moon11:before {
  content: "\efa4";
}
.ficon-cloud-moon-rain11:before {
  content: "\efa5";
}
.ficon-cloud-rain11:before {
  content: "\efa6";
}
.ficon-cloud-rainbow11:before {
  content: "\efa7";
}
.ficon-clouds11:before {
  content: "\efa8";
}
.ficon-cloud-showers11:before {
  content: "\efa9";
}
.ficon-cloud-showers-heavy11:before {
  content: "\efaa";
}
.ficon-cloud-sleet11:before {
  content: "\efab";
}
.ficon-clouds-moon11:before {
  content: "\efac";
}
.ficon-cloud-snow11:before {
  content: "\efad";
}
.ficon-clouds-sun11:before {
  content: "\efae";
}
.ficon-cloud-sun11:before {
  content: "\efaf";
}
.ficon-cloud-sun-rain11:before {
  content: "\efb0";
}
.ficon-cloud-upload11:before {
  content: "\efb1";
}
.ficon-cloud-upload-alt11:before {
  content: "\efb2";
}
.ficon-club11:before {
  content: "\efb3";
}
.ficon-cocktail11:before {
  content: "\efb4";
}
.ficon-code11:before {
  content: "\efb5";
}
.ficon-code-branch11:before {
  content: "\efb6";
}
.ficon-code-commit11:before {
  content: "\efb7";
}
.ficon-code-merge11:before {
  content: "\efb8";
}
.ficon-coffee11:before {
  content: "\efb9";
}
.ficon-coffee-togo11:before {
  content: "\efba";
}
.ficon-coffin11:before {
  content: "\efbb";
}
.ficon-cog11:before {
  content: "\efbc";
}
.ficon-cogs11:before {
  content: "\efbd";
}
.ficon-coins11:before {
  content: "\efbe";
}
.ficon-columns11:before {
  content: "\efbf";
}
.ficon-comment11:before {
  content: "\efc0";
}
.ficon-comment-alt11:before {
  content: "\efc1";
}
.ficon-comment-alt-check11:before {
  content: "\efc2";
}
.ficon-comment-alt-dollar11:before {
  content: "\efc3";
}
.ficon-comment-alt-dots11:before {
  content: "\efc4";
}
.ficon-comment-alt-edit11:before {
  content: "\efc5";
}
.ficon-comment-alt-exclamation11:before {
  content: "\efc6";
}
.ficon-comment-alt-lines11:before {
  content: "\efc7";
}
.ficon-comment-alt-minus11:before {
  content: "\efc8";
}
.ficon-comment-alt-plus11:before {
  content: "\efc9";
}
.ficon-comment-alt-slash11:before {
  content: "\efca";
}
.ficon-comment-alt-smile11:before {
  content: "\efcb";
}
.ficon-comment-alt-times11:before {
  content: "\efcc";
}
.ficon-comment-check11:before {
  content: "\efcd";
}
.ficon-comment-dollar11:before {
  content: "\efce";
}
.ficon-comment-dots11:before {
  content: "\efcf";
}
.ficon-comment-edit11:before {
  content: "\efd0";
}
.ficon-comment-exclamation11:before {
  content: "\efd1";
}
.ficon-comment-lines11:before {
  content: "\efd2";
}
.ficon-comment-minus11:before {
  content: "\efd3";
}
.ficon-comment-plus11:before {
  content: "\efd4";
}
.ficon-comments11:before {
  content: "\efd5";
}
.ficon-comments-alt11:before {
  content: "\efd6";
}
.ficon-comments-alt-dollar11:before {
  content: "\efd7";
}
.ficon-comments-dollar11:before {
  content: "\efd8";
}
.ficon-comment-slash11:before {
  content: "\efd9";
}
.ficon-comment-smile11:before {
  content: "\efda";
}
.ficon-comment-times11:before {
  content: "\efdb";
}
.ficon-compact-disc11:before {
  content: "\efdc";
}
.ficon-compass11:before {
  content: "\efdd";
}
.ficon-compass-slash11:before {
  content: "\efde";
}
.ficon-compress11:before {
  content: "\efdf";
}
.ficon-compress-alt11:before {
  content: "\efe0";
}
.ficon-compress-wide11:before {
  content: "\efe1";
}
.ficon-concierge-bell11:before {
  content: "\efe2";
}
.ficon-container-storage11:before {
  content: "\efe3";
}
.ficon-conveyor-belt11:before {
  content: "\efe4";
}
.ficon-conveyor-belt-alt11:before {
  content: "\efe5";
}
.ficon-cookie11:before {
  content: "\efe6";
}
.ficon-cookie-bite11:before {
  content: "\efe7";
}
.ficon-copy11:before {
  content: "\efe8";
}
.ficon-copyright11:before {
  content: "\efe9";
}
.ficon-corn11:before {
  content: "\efea";
}
.ficon-couch11:before {
  content: "\efeb";
}
.ficon-cow11:before {
  content: "\efec";
}
.ficon-credit-card11:before {
  content: "\efed";
}
.ficon-credit-card-blank11:before {
  content: "\efee";
}
.ficon-credit-card-front11:before {
  content: "\efef";
}
.ficon-cricket11:before {
  content: "\eff0";
}
.ficon-crop11:before {
  content: "\eff1";
}
.ficon-crop-alt11:before {
  content: "\eff2";
}
.ficon-cross11:before {
  content: "\eff3";
}
.ficon-crosshairs11:before {
  content: "\eff4";
}
.ficon-crow11:before {
  content: "\eff5";
}
.ficon-crown11:before {
  content: "\eff6";
}
.ficon-cube11:before {
  content: "\eff7";
}
.ficon-cubes11:before {
  content: "\eff8";
}
.ficon-curling11:before {
  content: "\eff9";
}
.ficon-cut11:before {
  content: "\effa";
}
.ficon-dagger11:before {
  content: "\effb";
}
.ficon-database11:before {
  content: "\effc";
}
.ficon-deaf11:before {
  content: "\effd";
}
.ficon-democrat11:before {
  content: "\effe";
}
.ficon-desktop11:before {
  content: "\efff";
}
.ficon-desktop-alt11:before {
  content: "\f000";
}
.ficon-dewpoint11:before {
  content: "\f001";
}
.ficon-dharmachakra11:before {
  content: "\f002";
}
.ficon-diagnoses11:before {
  content: "\f003";
}
.ficon-diamond11:before {
  content: "\f004";
}
.ficon-dice11:before {
  content: "\f005";
}
.ficon-dice-d411:before {
  content: "\f006";
}
.ficon-dice-d611:before {
  content: "\f007";
}
.ficon-dice-d811:before {
  content: "\f008";
}
.ficon-dice-d1011:before {
  content: "\f009";
}
.ficon-dice-d1211:before {
  content: "\f00a";
}
.ficon-dice-d2011:before {
  content: "\f00b";
}
.ficon-dice-five11:before {
  content: "\f00c";
}
.ficon-dice-four11:before {
  content: "\f00d";
}
.ficon-dice-one11:before {
  content: "\f00e";
}
.ficon-dice-six11:before {
  content: "\f00f";
}
.ficon-dice-three11:before {
  content: "\f010";
}
.ficon-dice-two11:before {
  content: "\f011";
}
.ficon-digital-tachograph11:before {
  content: "\f012";
}
.ficon-diploma11:before {
  content: "\f013";
}
.ficon-directions11:before {
  content: "\f014";
}
.ficon-divide11:before {
  content: "\f015";
}
.ficon-dizzy11:before {
  content: "\f016";
}
.ficon-dna11:before {
  content: "\f017";
}
.ficon-dog11:before {
  content: "\f018";
}
.ficon-dog-leashed11:before {
  content: "\f019";
}
.ficon-dollar-sign11:before {
  content: "\f01a";
}
.ficon-dolly11:before {
  content: "\f01b";
}
.ficon-dolly-empty11:before {
  content: "\f01c";
}
.ficon-dolly-flatbed11:before {
  content: "\f01d";
}
.ficon-dolly-flatbed-alt11:before {
  content: "\f01e";
}
.ficon-dolly-flatbed-empty11:before {
  content: "\f01f";
}
.ficon-donate11:before {
  content: "\f020";
}
.ficon-do-not-enter11:before {
  content: "\f021";
}
.ficon-door-closed11:before {
  content: "\f022";
}
.ficon-door-open11:before {
  content: "\f023";
}
.ficon-dot-circle11:before {
  content: "\f024";
}
.ficon-dove11:before {
  content: "\f025";
}
.ficon-download11:before {
  content: "\f026";
}
.ficon-drafting-compass11:before {
  content: "\f027";
}
.ficon-dragon11:before {
  content: "\f028";
}
.ficon-draw-circle11:before {
  content: "\f029";
}
.ficon-draw-polygon11:before {
  content: "\f02a";
}
.ficon-draw-square11:before {
  content: "\f02b";
}
.ficon-drum11:before {
  content: "\f02c";
}
.ficon-drum-steelpan11:before {
  content: "\f02d";
}
.ficon-drumstick11:before {
  content: "\f02e";
}
.ficon-drumstick-bite11:before {
  content: "\f02f";
}
.ficon-duck11:before {
  content: "\f030";
}
.ficon-dumbbell11:before {
  content: "\f031";
}
.ficon-dungeon11:before {
  content: "\f032";
}
.ficon-ear11:before {
  content: "\f033";
}
.ficon-eclipse11:before {
  content: "\f034";
}
.ficon-eclipse-alt11:before {
  content: "\f035";
}
.ficon-edit11:before {
  content: "\f036";
}
.ficon-eject11:before {
  content: "\f037";
}
.ficon-elephant11:before {
  content: "\f038";
}
.ficon-ellipsis-h11:before {
  content: "\f039";
}
.ficon-ellipsis-h-alt11:before {
  content: "\f03a";
}
.ficon-ellipsis-v11:before {
  content: "\f03b";
}
.ficon-ellipsis-v-alt11:before {
  content: "\f03c";
}
.ficon-empty-set11:before {
  content: "\f03d";
}
.ficon-engine-warning11:before {
  content: "\f03e";
}
.ficon-envelope11:before {
  content: "\f03f";
}
.ficon-envelope-open11:before {
  content: "\f040";
}
.ficon-envelope-open-dollar11:before {
  content: "\f041";
}
.ficon-envelope-open-text11:before {
  content: "\f042";
}
.ficon-envelope-square11:before {
  content: "\f043";
}
.ficon-equals11:before {
  content: "\f044";
}
.ficon-eraser11:before {
  content: "\f045";
}
.ficon-euro-sign11:before {
  content: "\f046";
}
.ficon-exchange11:before {
  content: "\f047";
}
.ficon-exchange-alt11:before {
  content: "\f048";
}
.ficon-exclamation11:before {
  content: "\f049";
}
.ficon-exclamation-circle11:before {
  content: "\f04a";
}
.ficon-exclamation-square11:before {
  content: "\f04b";
}
.ficon-exclamation-triangle11:before {
  content: "\f04c";
}
.ficon-expand11:before {
  content: "\f04d";
}
.ficon-expand-alt11:before {
  content: "\f04e";
}
.ficon-expand-arrows11:before {
  content: "\f04f";
}
.ficon-expand-arrows-alt11:before {
  content: "\f050";
}
.ficon-expand-wide11:before {
  content: "\f051";
}
.ficon-external-link11:before {
  content: "\f052";
}
.ficon-external-link-alt11:before {
  content: "\f053";
}
.ficon-external-link-square11:before {
  content: "\f054";
}
.ficon-external-link-square-alt11:before {
  content: "\f055";
}
.ficon-eye11:before {
  content: "\f056";
}
.ficon-eye-dropper11:before {
  content: "\f057";
}
.ficon-eye-evil11:before {
  content: "\f058";
}
.ficon-eye-slash11:before {
  content: "\f059";
}
.ficon-fast-backward11:before {
  content: "\f05a";
}
.ficon-fast-forward11:before {
  content: "\f05b";
}
.ficon-fax11:before {
  content: "\f05c";
}
.ficon-feather11:before {
  content: "\f05d";
}
.ficon-feather-alt11:before {
  content: "\f05e";
}
.ficon-female11:before {
  content: "\f05f";
}
.ficon-field-hockey11:before {
  content: "\f060";
}
.ficon-fighter-jet11:before {
  content: "\f061";
}
.ficon-file11:before {
  content: "\f062";
}
.ficon-file-alt11:before {
  content: "\f063";
}
.ficon-file-archive11:before {
  content: "\f064";
}
.ficon-file-audio11:before {
  content: "\f065";
}
.ficon-file-certificate11:before {
  content: "\f066";
}
.ficon-file-chart-line11:before {
  content: "\f067";
}
.ficon-file-chart-pie11:before {
  content: "\f068";
}
.ficon-file-check11:before {
  content: "\f069";
}
.ficon-file-code11:before {
  content: "\f06a";
}
.ficon-file-contract11:before {
  content: "\f06b";
}
.ficon-file-csv11:before {
  content: "\f06c";
}
.ficon-file-download11:before {
  content: "\f06d";
}
.ficon-file-edit11:before {
  content: "\f06e";
}
.ficon-file-excel11:before {
  content: "\f06f";
}
.ficon-file-exclamation11:before {
  content: "\f070";
}
.ficon-file-export11:before {
  content: "\f071";
}
.ficon-file-image11:before {
  content: "\f072";
}
.ficon-file-import11:before {
  content: "\f073";
}
.ficon-file-invoice11:before {
  content: "\f074";
}
.ficon-file-invoice-dollar11:before {
  content: "\f075";
}
.ficon-file-medical11:before {
  content: "\f076";
}
.ficon-file-medical-alt11:before {
  content: "\f077";
}
.ficon-file-minus11:before {
  content: "\f078";
}
.ficon-file-pdf11:before {
  content: "\f079";
}
.ficon-file-plus11:before {
  content: "\f07a";
}
.ficon-file-powerpoint11:before {
  content: "\f07b";
}
.ficon-file-prescription11:before {
  content: "\f07c";
}
.ficon-file-signature11:before {
  content: "\f07d";
}
.ficon-file-spreadsheet11:before {
  content: "\f07e";
}
.ficon-file-times11:before {
  content: "\f07f";
}
.ficon-file-upload11:before {
  content: "\f080";
}
.ficon-file-user11:before {
  content: "\f081";
}
.ficon-file-video11:before {
  content: "\f082";
}
.ficon-file-word11:before {
  content: "\f083";
}
.ficon-fill11:before {
  content: "\f084";
}
.ficon-fill-drip11:before {
  content: "\f085";
}
.ficon-film11:before {
  content: "\f086";
}
.ficon-film-alt11:before {
  content: "\f087";
}
.ficon-filter11:before {
  content: "\f088";
}
.ficon-fingerprint11:before {
  content: "\f089";
}
.ficon-fire11:before {
  content: "\f08a";
}
.ficon-fire-extinguisher11:before {
  content: "\f08b";
}
.ficon-fire-smoke11:before {
  content: "\f08c";
}
.ficon-first-aid11:before {
  content: "\f08d";
}
.ficon-fish11:before {
  content: "\f08e";
}
.ficon-fist-raised11:before {
  content: "\f08f";
}
.ficon-flag11:before {
  content: "\f090";
}
.ficon-flag-alt11:before {
  content: "\f091";
}
.ficon-flag-checkered11:before {
  content: "\f092";
}
.ficon-flag-usa11:before {
  content: "\f093";
}
.ficon-flame11:before {
  content: "\f094";
}
.ficon-flask11:before {
  content: "\f095";
}
.ficon-flask-poison11:before {
  content: "\f096";
}
.ficon-flask-potion11:before {
  content: "\f097";
}
.ficon-flushed11:before {
  content: "\f098";
}
.ficon-fog11:before {
  content: "\f099";
}
.ficon-folder11:before {
  content: "\f09a";
}
.ficon-folder-minus11:before {
  content: "\f09b";
}
.ficon-folder-open11:before {
  content: "\f09c";
}
.ficon-folder-plus11:before {
  content: "\f09d";
}
.ficon-folders11:before {
  content: "\f09e";
}
.ficon-folder-times11:before {
  content: "\f09f";
}
.ficon-font11:before {
  content: "\f0a0";
}
.ficon-font-awesome-logo-full111:before {
  content: "\f0a1";
}
.ficon-football-ball11:before {
  content: "\f0a2";
}
.ficon-football-helmet11:before {
  content: "\f0a3";
}
.ficon-forklift11:before {
  content: "\f0a4";
}
.ficon-forward11:before {
  content: "\f0a5";
}
.ficon-fragile11:before {
  content: "\f0a6";
}
.ficon-frog11:before {
  content: "\f0a7";
}
.ficon-frown11:before {
  content: "\f0a8";
}
.ficon-frown-open11:before {
  content: "\f0a9";
}
.ficon-function11:before {
  content: "\f0aa";
}
.ficon-funnel-dollar11:before {
  content: "\f0ab";
}
.ficon-futbol11:before {
  content: "\f0ac";
}
.ficon-gamepad11:before {
  content: "\f0ad";
}
.ficon-gas-pump11:before {
  content: "\f0ae";
}
.ficon-gas-pump-slash11:before {
  content: "\f0af";
}
.ficon-gavel11:before {
  content: "\f0b0";
}
.ficon-gem11:before {
  content: "\f0b1";
}
.ficon-genderless11:before {
  content: "\f0b2";
}
.ficon-ghost11:before {
  content: "\f0b3";
}
.ficon-gift11:before {
  content: "\f0b4";
}
.ficon-gift-card11:before {
  content: "\f0b5";
}
.ficon-glasses11:before {
  content: "\f0b6";
}
.ficon-glasses-alt11:before {
  content: "\f0b7";
}
.ficon-glass-martini11:before {
  content: "\f0b8";
}
.ficon-glass-martini-alt11:before {
  content: "\f0b9";
}
.ficon-globe11:before {
  content: "\f0ba";
}
.ficon-globe-africa11:before {
  content: "\f0bb";
}
.ficon-globe-americas11:before {
  content: "\f0bc";
}
.ficon-globe-asia11:before {
  content: "\f0bd";
}
.ficon-globe-stand11:before {
  content: "\f0be";
}
.ficon-golf-ball11:before {
  content: "\f0bf";
}
.ficon-golf-club11:before {
  content: "\f0c0";
}
.ficon-gopuram11:before {
  content: "\f0c1";
}
.ficon-graduation-cap11:before {
  content: "\f0c2";
}
.ficon-greater-than11:before {
  content: "\f0c3";
}
.ficon-greater-than-equal11:before {
  content: "\f0c4";
}
.ficon-grimace11:before {
  content: "\f0c5";
}
.ficon-grin11:before {
  content: "\f0c6";
}
.ficon-grin-alt11:before {
  content: "\f0c7";
}
.ficon-grin-beam11:before {
  content: "\f0c8";
}
.ficon-grin-beam-sweat11:before {
  content: "\f0c9";
}
.ficon-grin-hearts11:before {
  content: "\f0ca";
}
.ficon-grin-squint11:before {
  content: "\f0cb";
}
.ficon-grin-squint-tears11:before {
  content: "\f0cc";
}
.ficon-grin-stars11:before {
  content: "\f0cd";
}
.ficon-grin-tears11:before {
  content: "\f0ce";
}
.ficon-grin-tongue11:before {
  content: "\f0cf";
}
.ficon-grin-tongue-squint11:before {
  content: "\f0d0";
}
.ficon-grin-tongue-wink11:before {
  content: "\f0d1";
}
.ficon-grin-wink11:before {
  content: "\f0d2";
}
.ficon-grip-horizontal11:before {
  content: "\f0d3";
}
.ficon-grip-vertical11:before {
  content: "\f0d4";
}
.ficon-h111:before {
  content: "\f0d5";
}
.ficon-h211:before {
  content: "\f0d6";
}
.ficon-h311:before {
  content: "\f0d7";
}
.ficon-hammer11:before {
  content: "\f0d8";
}
.ficon-hammer-war11:before {
  content: "\f0d9";
}
.ficon-hamsa11:before {
  content: "\f0da";
}
.ficon-hand-heart11:before {
  content: "\f0db";
}
.ficon-hand-holding11:before {
  content: "\f0dc";
}
.ficon-hand-holding-box11:before {
  content: "\f0dd";
}
.ficon-hand-holding-heart11:before {
  content: "\f0de";
}
.ficon-hand-holding-magic11:before {
  content: "\f0df";
}
.ficon-hand-holding-seedling11:before {
  content: "\f0e0";
}
.ficon-hand-holding-usd11:before {
  content: "\f0e1";
}
.ficon-hand-holding-water11:before {
  content: "\f0e2";
}
.ficon-hand-lizard11:before {
  content: "\f0e3";
}
.ficon-hand-paper11:before {
  content: "\f0e4";
}
.ficon-hand-peace11:before {
  content: "\f0e5";
}
.ficon-hand-point-down11:before {
  content: "\f0e6";
}
.ficon-hand-pointer11:before {
  content: "\f0e7";
}
.ficon-hand-point-left11:before {
  content: "\f0e8";
}
.ficon-hand-point-right11:before {
  content: "\f0e9";
}
.ficon-hand-point-up11:before {
  content: "\f0ea";
}
.ficon-hand-receiving11:before {
  content: "\f0eb";
}
.ficon-hand-rock11:before {
  content: "\f0ec";
}
.ficon-hands11:before {
  content: "\f0ed";
}
.ficon-hand-scissors11:before {
  content: "\f0ee";
}
.ficon-handshake11:before {
  content: "\f0ef";
}
.ficon-handshake-alt11:before {
  content: "\f0f0";
}
.ficon-hands-heart11:before {
  content: "\f0f1";
}
.ficon-hands-helping11:before {
  content: "\f0f2";
}
.ficon-hand-spock11:before {
  content: "\f0f3";
}
.ficon-hands-usd11:before {
  content: "\f0f4";
}
.ficon-hanukiah11:before {
  content: "\f0f5";
}
.ficon-hashtag11:before {
  content: "\f0f6";
}
.ficon-hat-witch11:before {
  content: "\f0f7";
}
.ficon-hat-wizard11:before {
  content: "\f0f8";
}
.ficon-haykal11:before {
  content: "\f0f9";
}
.ficon-hdd11:before {
  content: "\f0fa";
}
.ficon-heading11:before {
  content: "\f0fb";
}
.ficon-headphones11:before {
  content: "\f0fc";
}
.ficon-headphones-alt11:before {
  content: "\f0fd";
}
.ficon-headset11:before {
  content: "\f0fe";
}
.ficon-head-side11:before {
  content: "\f0ff";
}
.ficon-head-vr11:before {
  content: "\f100";
}
.ficon-heart11:before {
  content: "\f101";
}
.ficon-heartbeat11:before {
  content: "\f102";
}
.ficon-heart-circle11:before {
  content: "\f103";
}
.ficon-heart-rate11:before {
  content: "\f104";
}
.ficon-heart-square11:before {
  content: "\f105";
}
.ficon-helicopter11:before {
  content: "\f106";
}
.ficon-helmet-battle11:before {
  content: "\f107";
}
.ficon-hexagon11:before {
  content: "\f108";
}
.ficon-highlighter11:before {
  content: "\f109";
}
.ficon-hiking11:before {
  content: "\f10a";
}
.ficon-hippo11:before {
  content: "\f10b";
}
.ficon-history11:before {
  content: "\f10c";
}
.ficon-hockey-mask11:before {
  content: "\f10d";
}
.ficon-hockey-puck11:before {
  content: "\f10e";
}
.ficon-hockey-sticks11:before {
  content: "\f10f";
}
.ficon-home11:before {
  content: "\f110";
}
.ficon-home-heart11:before {
  content: "\f111";
}
.ficon-hood-cloak11:before {
  content: "\f112";
}
.ficon-horse11:before {
  content: "\f113";
}
.ficon-hospital11:before {
  content: "\f114";
}
.ficon-hospital-alt11:before {
  content: "\f115";
}
.ficon-hospital-symbol11:before {
  content: "\f116";
}
.ficon-hotel11:before {
  content: "\f117";
}
.ficon-hot-tub11:before {
  content: "\f118";
}
.ficon-hourglass11:before {
  content: "\f119";
}
.ficon-hourglass-end11:before {
  content: "\f11a";
}
.ficon-hourglass-half11:before {
  content: "\f11b";
}
.ficon-hourglass-start11:before {
  content: "\f11c";
}
.ficon-house-damage11:before {
  content: "\f11d";
}
.ficon-house-flood11:before {
  content: "\f11e";
}
.ficon-hryvnia11:before {
  content: "\f11f";
}
.ficon-h-square11:before {
  content: "\f120";
}
.ficon-humidity11:before {
  content: "\f121";
}
.ficon-hurricane11:before {
  content: "\f122";
}
.ficon-i-cursor11:before {
  content: "\f123";
}
.ficon-id-badge11:before {
  content: "\f124";
}
.ficon-id-card11:before {
  content: "\f125";
}
.ficon-id-card-alt11:before {
  content: "\f126";
}
.ficon-image11:before {
  content: "\f127";
}
.ficon-images11:before {
  content: "\f128";
}
.ficon-inbox11:before {
  content: "\f129";
}
.ficon-inbox-in11:before {
  content: "\f12a";
}
.ficon-inbox-out11:before {
  content: "\f12b";
}
.ficon-indent11:before {
  content: "\f12c";
}
.ficon-industry11:before {
  content: "\f12d";
}
.ficon-industry-alt11:before {
  content: "\f12e";
}
.ficon-infinity11:before {
  content: "\f12f";
}
.ficon-info11:before {
  content: "\f130";
}
.ficon-info-circle11:before {
  content: "\f131";
}
.ficon-info-square11:before {
  content: "\f132";
}
.ficon-inhaler11:before {
  content: "\f133";
}
.ficon-integral11:before {
  content: "\f134";
}
.ficon-intersection11:before {
  content: "\f135";
}
.ficon-inventory11:before {
  content: "\f136";
}
.ficon-italic11:before {
  content: "\f137";
}
.ficon-jack-o-lantern11:before {
  content: "\f138";
}
.ficon-jedi11:before {
  content: "\f139";
}
.ficon-joint11:before {
  content: "\f13a";
}
.ficon-journal-whills11:before {
  content: "\f13b";
}
.ficon-kaaba11:before {
  content: "\f13c";
}
.ficon-key11:before {
  content: "\f13d";
}
.ficon-keyboard11:before {
  content: "\f13e";
}
.ficon-keynote11:before {
  content: "\f13f";
}
.ficon-key-skeleton11:before {
  content: "\f140";
}
.ficon-khanda11:before {
  content: "\f141";
}
.ficon-kidneys11:before {
  content: "\f142";
}
.ficon-kiss11:before {
  content: "\f143";
}
.ficon-kiss-beam11:before {
  content: "\f144";
}
.ficon-kiss-wink-heart11:before {
  content: "\f145";
}
.ficon-kite11:before {
  content: "\f146";
}
.ficon-kiwi-bird11:before {
  content: "\f147";
}
.ficon-knife-kitchen11:before {
  content: "\f148";
}
.ficon-lambda11:before {
  content: "\f149";
}
.ficon-lamp11:before {
  content: "\f14a";
}
.ficon-landmark11:before {
  content: "\f14b";
}
.ficon-landmark-alt11:before {
  content: "\f14c";
}
.ficon-language11:before {
  content: "\f14d";
}
.ficon-laptop11:before {
  content: "\f14e";
}
.ficon-laptop-code11:before {
  content: "\f14f";
}
.ficon-laugh11:before {
  content: "\f150";
}
.ficon-laugh-beam11:before {
  content: "\f151";
}
.ficon-laugh-squint11:before {
  content: "\f152";
}
.ficon-laugh-wink11:before {
  content: "\f153";
}
.ficon-layer-group11:before {
  content: "\f154";
}
.ficon-layer-minus11:before {
  content: "\f155";
}
.ficon-layer-plus11:before {
  content: "\f156";
}
.ficon-leaf11:before {
  content: "\f157";
}
.ficon-leaf-heart11:before {
  content: "\f158";
}
.ficon-leaf-maple11:before {
  content: "\f159";
}
.ficon-leaf-oak11:before {
  content: "\f15a";
}
.ficon-lemon11:before {
  content: "\f15b";
}
.ficon-less-than11:before {
  content: "\f15c";
}
.ficon-less-than-equal11:before {
  content: "\f15d";
}
.ficon-level-down11:before {
  content: "\f15e";
}
.ficon-level-down-alt11:before {
  content: "\f15f";
}
.ficon-level-up11:before {
  content: "\f160";
}
.ficon-level-up-alt11:before {
  content: "\f161";
}
.ficon-life-ring11:before {
  content: "\f162";
}
.ficon-lightbulb11:before {
  content: "\f163";
}
.ficon-lightbulb-dollar11:before {
  content: "\f164";
}
.ficon-lightbulb-exclamation11:before {
  content: "\f165";
}
.ficon-lightbulb-on11:before {
  content: "\f166";
}
.ficon-lightbulb-slash11:before {
  content: "\f167";
}
.ficon-link11:before {
  content: "\f168";
}
.ficon-lips11:before {
  content: "\f169";
}
.ficon-lira-sign11:before {
  content: "\f16a";
}
.ficon-list11:before {
  content: "\f16b";
}
.ficon-list-alt11:before {
  content: "\f16c";
}
.ficon-list-ol11:before {
  content: "\f16d";
}
.ficon-list-ul11:before {
  content: "\f16e";
}
.ficon-location11:before {
  content: "\f16f";
}
.ficon-location-arrow11:before {
  content: "\f170";
}
.ficon-location-circle11:before {
  content: "\f171";
}
.ficon-location-slash11:before {
  content: "\f172";
}
.ficon-lock11:before {
  content: "\f173";
}
.ficon-lock-alt11:before {
  content: "\f174";
}
.ficon-lock-open11:before {
  content: "\f175";
}
.ficon-lock-open-alt11:before {
  content: "\f176";
}
.ficon-long-arrow-alt-down11:before {
  content: "\f177";
}
.ficon-long-arrow-alt-left11:before {
  content: "\f178";
}
.ficon-long-arrow-alt-right11:before {
  content: "\f179";
}
.ficon-long-arrow-alt-up11:before {
  content: "\f17a";
}
.ficon-long-arrow-down11:before {
  content: "\f17b";
}
.ficon-long-arrow-left11:before {
  content: "\f17c";
}
.ficon-long-arrow-right11:before {
  content: "\f17d";
}
.ficon-long-arrow-up11:before {
  content: "\f17e";
}
.ficon-loveseat11:before {
  content: "\f17f";
}
.ficon-low-vision11:before {
  content: "\f180";
}
.ficon-luchador11:before {
  content: "\f181";
}
.ficon-luggage-cart11:before {
  content: "\f182";
}
.ficon-lungs11:before {
  content: "\f183";
}
.ficon-mace11:before {
  content: "\f184";
}
.ficon-magic11:before {
  content: "\f185";
}
.ficon-magnet11:before {
  content: "\f186";
}
.ficon-mail-bulk11:before {
  content: "\f187";
}
.ficon-male11:before {
  content: "\f188";
}
.ficon-mandolin11:before {
  content: "\f189";
}
.ficon-map11:before {
  content: "\f18a";
}
.ficon-map-marked11:before {
  content: "\f18b";
}
.ficon-map-marked-alt11:before {
  content: "\f18c";
}
.ficon-map-marker11:before {
  content: "\f18d";
}
.ficon-map-marker-alt11:before {
  content: "\f18e";
}
.ficon-map-marker-alt-slash11:before {
  content: "\f18f";
}
.ficon-map-marker-check11:before {
  content: "\f190";
}
.ficon-map-marker-edit11:before {
  content: "\f191";
}
.ficon-map-marker-exclamation11:before {
  content: "\f192";
}
.ficon-map-marker-minus11:before {
  content: "\f193";
}
.ficon-map-marker-plus11:before {
  content: "\f194";
}
.ficon-map-marker-question11:before {
  content: "\f195";
}
.ficon-map-marker-slash11:before {
  content: "\f196";
}
.ficon-map-marker-smile11:before {
  content: "\f197";
}
.ficon-map-marker-times11:before {
  content: "\f198";
}
.ficon-map-pin11:before {
  content: "\f199";
}
.ficon-map-signs11:before {
  content: "\f19a";
}
.ficon-marker11:before {
  content: "\f19b";
}
.ficon-mars11:before {
  content: "\f19c";
}
.ficon-mars-double11:before {
  content: "\f19d";
}
.ficon-mars-stroke11:before {
  content: "\f19e";
}
.ficon-mars-stroke-h11:before {
  content: "\f19f";
}
.ficon-mars-stroke-v11:before {
  content: "\f1a0";
}
.ficon-mask11:before {
  content: "\f1a1";
}
.ficon-medal11:before {
  content: "\f1a2";
}
.ficon-medkit11:before {
  content: "\f1a3";
}
.ficon-megaphone11:before {
  content: "\f1a4";
}
.ficon-meh11:before {
  content: "\f1a5";
}
.ficon-meh-blank11:before {
  content: "\f1a6";
}
.ficon-meh-rolling-eyes11:before {
  content: "\f1a7";
}
.ficon-memory11:before {
  content: "\f1a8";
}
.ficon-menorah11:before {
  content: "\f1a9";
}
.ficon-mercury11:before {
  content: "\f1aa";
}
.ficon-meteor11:before {
  content: "\f1ab";
}
.ficon-microchip11:before {
  content: "\f1ac";
}
.ficon-microphone11:before {
  content: "\f1ad";
}
.ficon-microphone-alt11:before {
  content: "\f1ae";
}
.ficon-microphone-alt-slash11:before {
  content: "\f1af";
}
.ficon-microphone-slash11:before {
  content: "\f1b0";
}
.ficon-microscope11:before {
  content: "\f1b1";
}
.ficon-mind-share11:before {
  content: "\f1b2";
}
.ficon-minus11:before {
  content: "\f1b3";
}
.ficon-minus-circle11:before {
  content: "\f1b4";
}
.ficon-minus-hexagon11:before {
  content: "\f1b5";
}
.ficon-minus-octagon11:before {
  content: "\f1b6";
}
.ficon-minus-square11:before {
  content: "\f1b7";
}
.ficon-mobile11:before {
  content: "\f1b8";
}
.ficon-mobile-alt11:before {
  content: "\f1b9";
}
.ficon-mobile-android11:before {
  content: "\f1ba";
}
.ficon-mobile-android-alt11:before {
  content: "\f1bb";
}
.ficon-money-bill11:before {
  content: "\f1bc";
}
.ficon-money-bill-alt11:before {
  content: "\f1bd";
}
.ficon-money-bill-wave11:before {
  content: "\f1be";
}
.ficon-money-bill-wave-alt11:before {
  content: "\f1bf";
}
.ficon-money-check11:before {
  content: "\f1c0";
}
.ficon-money-check-alt11:before {
  content: "\f1c1";
}
.ficon-monitor-heart-rate11:before {
  content: "\f1c2";
}
.ficon-monkey11:before {
  content: "\f1c3";
}
.ficon-monument11:before {
  content: "\f1c4";
}
.ficon-moon11:before {
  content: "\f1c5";
}
.ficon-moon-cloud11:before {
  content: "\f1c6";
}
.ficon-moon-stars11:before {
  content: "\f1c7";
}
.ficon-mortar-pestle11:before {
  content: "\f1c8";
}
.ficon-mosque11:before {
  content: "\f1c9";
}
.ficon-motorcycle11:before {
  content: "\f1ca";
}
.ficon-mountain11:before {
  content: "\f1cb";
}
.ficon-mountains11:before {
  content: "\f1cc";
}
.ficon-mouse-pointer11:before {
  content: "\f1cd";
}
.ficon-music11:before {
  content: "\f1ce";
}
.ficon-narwhal11:before {
  content: "\f1cf";
}
.ficon-network-wired11:before {
  content: "\f1d0";
}
.ficon-neuter11:before {
  content: "\f1d1";
}
.ficon-newspaper11:before {
  content: "\f1d2";
}
.ficon-not-equal11:before {
  content: "\f1d3";
}
.ficon-notes-medical11:before {
  content: "\f1d4";
}
.ficon-object-group11:before {
  content: "\f1d5";
}
.ficon-object-ungroup11:before {
  content: "\f1d6";
}
.ficon-octagon11:before {
  content: "\f1d7";
}
.ficon-oil-can11:before {
  content: "\f1d8";
}
.ficon-oil-temp11:before {
  content: "\f1d9";
}
.ficon-om11:before {
  content: "\f1da";
}
.ficon-omega11:before {
  content: "\f1db";
}
.ficon-otter11:before {
  content: "\f1dc";
}
.ficon-outdent11:before {
  content: "\f1dd";
}
.ficon-paint-brush11:before {
  content: "\f1de";
}
.ficon-paint-brush-alt11:before {
  content: "\f1df";
}
.ficon-paint-roller11:before {
  content: "\f1e0";
}
.ficon-palette11:before {
  content: "\f1e1";
}
.ficon-pallet11:before {
  content: "\f1e2";
}
.ficon-pallet-alt11:before {
  content: "\f1e3";
}
.ficon-paperclip11:before {
  content: "\f1e4";
}
.ficon-paper-plane11:before {
  content: "\f1e5";
}
.ficon-parachute-box11:before {
  content: "\f1e6";
}
.ficon-paragraph11:before {
  content: "\f1e7";
}
.ficon-parking11:before {
  content: "\f1e8";
}
.ficon-parking-circle11:before {
  content: "\f1e9";
}
.ficon-parking-circle-slash11:before {
  content: "\f1ea";
}
.ficon-parking-slash11:before {
  content: "\f1eb";
}
.ficon-passport11:before {
  content: "\f1ec";
}
.ficon-pastafarianism11:before {
  content: "\f1ed";
}
.ficon-paste11:before {
  content: "\f1ee";
}
.ficon-pause11:before {
  content: "\f1ef";
}
.ficon-pause-circle11:before {
  content: "\f1f0";
}
.ficon-paw11:before {
  content: "\f1f1";
}
.ficon-paw-alt11:before {
  content: "\f1f2";
}
.ficon-paw-claws11:before {
  content: "\f1f3";
}
.ficon-peace11:before {
  content: "\f1f4";
}
.ficon-pegasus11:before {
  content: "\f1f5";
}
.ficon-pen11:before {
  content: "\f1f6";
}
.ficon-pen-alt11:before {
  content: "\f1f7";
}
.ficon-pencil11:before {
  content: "\f1f8";
}
.ficon-pencil-alt11:before {
  content: "\f1f9";
}
.ficon-pencil-paintbrush11:before {
  content: "\f1fa";
}
.ficon-pencil-ruler11:before {
  content: "\f1fb";
}
.ficon-pen-fancy11:before {
  content: "\f1fc";
}
.ficon-pennant11:before {
  content: "\f1fd";
}
.ficon-pen-nib11:before {
  content: "\f1fe";
}
.ficon-pen-square11:before {
  content: "\f1ff";
}
.ficon-people-carry11:before {
  content: "\f200";
}
.ficon-percent11:before {
  content: "\f201";
}
.ficon-percentage11:before {
  content: "\f202";
}
.ficon-person-booth11:before {
  content: "\f203";
}
.ficon-person-carry11:before {
  content: "\f204";
}
.ficon-person-dolly11:before {
  content: "\f205";
}
.ficon-person-dolly-empty11:before {
  content: "\f206";
}
.ficon-person-sign11:before {
  content: "\f207";
}
.ficon-phone11:before {
  content: "\f208";
}
.ficon-phone-office11:before {
  content: "\f209";
}
.ficon-phone-plus11:before {
  content: "\f20a";
}
.ficon-phone-slash11:before {
  content: "\f20b";
}
.ficon-phone-square11:before {
  content: "\f20c";
}
.ficon-phone-volume11:before {
  content: "\f20d";
}
.ficon-pi11:before {
  content: "\f20e";
}
.ficon-pie11:before {
  content: "\f20f";
}
.ficon-pig11:before {
  content: "\f210";
}
.ficon-piggy-bank11:before {
  content: "\f211";
}
.ficon-pills11:before {
  content: "\f212";
}
.ficon-place-of-worship11:before {
  content: "\f213";
}
.ficon-plane11:before {
  content: "\f214";
}
.ficon-plane-alt11:before {
  content: "\f215";
}
.ficon-plane-arrival11:before {
  content: "\f216";
}
.ficon-plane-departure11:before {
  content: "\f217";
}
.ficon-play11:before {
  content: "\f218";
}
.ficon-play-circle11:before {
  content: "\f219";
}
.ficon-plug11:before {
  content: "\f21a";
}
.ficon-plus11:before {
  content: "\f21b";
}
.ficon-plus-circle11:before {
  content: "\f21c";
}
.ficon-plus-hexagon11:before {
  content: "\f21d";
}
.ficon-plus-octagon11:before {
  content: "\f21e";
}
.ficon-plus-square11:before {
  content: "\f21f";
}
.ficon-podcast11:before {
  content: "\f220";
}
.ficon-podium11:before {
  content: "\f221";
}
.ficon-podium-star11:before {
  content: "\f222";
}
.ficon-poll11:before {
  content: "\f223";
}
.ficon-poll-h11:before {
  content: "\f224";
}
.ficon-poll-people11:before {
  content: "\f225";
}
.ficon-poo11:before {
  content: "\f226";
}
.ficon-poop11:before {
  content: "\f227";
}
.ficon-poo-storm11:before {
  content: "\f228";
}
.ficon-portrait11:before {
  content: "\f229";
}
.ficon-pound-sign11:before {
  content: "\f22a";
}
.ficon-power-off11:before {
  content: "\f22b";
}
.ficon-pray11:before {
  content: "\f22c";
}
.ficon-praying-hands11:before {
  content: "\f22d";
}
.ficon-prescription11:before {
  content: "\f22e";
}
.ficon-prescription-bottle11:before {
  content: "\f22f";
}
.ficon-prescription-bottle-alt11:before {
  content: "\f230";
}
.ficon-presentation11:before {
  content: "\f231";
}
.ficon-print11:before {
  content: "\f232";
}
.ficon-print-slash11:before {
  content: "\f233";
}
.ficon-procedures11:before {
  content: "\f234";
}
.ficon-project-diagram11:before {
  content: "\f235";
}
.ficon-pumpkin11:before {
  content: "\f236";
}
.ficon-puzzle-piece11:before {
  content: "\f237";
}
.ficon-qrcode11:before {
  content: "\f238";
}
.ficon-question11:before {
  content: "\f239";
}
.ficon-question-circle11:before {
  content: "\f23a";
}
.ficon-question-square11:before {
  content: "\f23b";
}
.ficon-quidditch11:before {
  content: "\f23c";
}
.ficon-quote-left11:before {
  content: "\f23d";
}
.ficon-quote-right11:before {
  content: "\f23e";
}
.ficon-quran11:before {
  content: "\f23f";
}
.ficon-rabbit11:before {
  content: "\f240";
}
.ficon-rabbit-fast11:before {
  content: "\f241";
}
.ficon-racquet11:before {
  content: "\f242";
}
.ficon-rainbow11:before {
  content: "\f243";
}
.ficon-raindrops11:before {
  content: "\f244";
}
.ficon-ram11:before {
  content: "\f245";
}
.ficon-ramp-loading11:before {
  content: "\f246";
}
.ficon-random11:before {
  content: "\f247";
}
.ficon-receipt11:before {
  content: "\f248";
}
.ficon-rectangle-landscape11:before {
  content: "\f249";
}
.ficon-rectangle-portrait11:before {
  content: "\f24a";
}
.ficon-rectangle-wide11:before {
  content: "\f24b";
}
.ficon-recycle11:before {
  content: "\f24c";
}
.ficon-redo11:before {
  content: "\f24d";
}
.ficon-redo-alt11:before {
  content: "\f24e";
}
.ficon-registered11:before {
  content: "\f24f";
}
.ficon-repeat11:before {
  content: "\f250";
}
.ficon-repeat-111:before {
  content: "\f251";
}
.ficon-repeat-1-alt11:before {
  content: "\f252";
}
.ficon-repeat-alt11:before {
  content: "\f253";
}
.ficon-reply11:before {
  content: "\f254";
}
.ficon-reply-all11:before {
  content: "\f255";
}
.ficon-republican11:before {
  content: "\f256";
}
.ficon-retweet11:before {
  content: "\f257";
}
.ficon-retweet-alt11:before {
  content: "\f258";
}
.ficon-ribbon11:before {
  content: "\f259";
}
.ficon-ring11:before {
  content: "\f25a";
}
.ficon-road11:before {
  content: "\f25b";
}
.ficon-robot11:before {
  content: "\f25c";
}
.ficon-rocket11:before {
  content: "\f25d";
}
.ficon-route11:before {
  content: "\f25e";
}
.ficon-route-highway11:before {
  content: "\f25f";
}
.ficon-route-interstate11:before {
  content: "\f260";
}
.ficon-rss11:before {
  content: "\f261";
}
.ficon-rss-square11:before {
  content: "\f262";
}
.ficon-ruble-sign11:before {
  content: "\f263";
}
.ficon-ruler11:before {
  content: "\f264";
}
.ficon-ruler-combined11:before {
  content: "\f265";
}
.ficon-ruler-horizontal11:before {
  content: "\f266";
}
.ficon-ruler-triangle11:before {
  content: "\f267";
}
.ficon-ruler-vertical11:before {
  content: "\f268";
}
.ficon-running11:before {
  content: "\f269";
}
.ficon-rupee-sign11:before {
  content: "\f26a";
}
.ficon-sad-cry11:before {
  content: "\f26b";
}
.ficon-sad-tear11:before {
  content: "\f26c";
}
.ficon-save11:before {
  content: "\f26d";
}
.ficon-scalpel11:before {
  content: "\f26e";
}
.ficon-scalpel-path11:before {
  content: "\f26f";
}
.ficon-scanner11:before {
  content: "\f270";
}
.ficon-scanner-keyboard11:before {
  content: "\f271";
}
.ficon-scanner-touchscreen11:before {
  content: "\f272";
}
.ficon-scarecrow11:before {
  content: "\f273";
}
.ficon-school11:before {
  content: "\f274";
}
.ficon-screwdriver11:before {
  content: "\f275";
}
.ficon-scroll11:before {
  content: "\f276";
}
.ficon-scroll-old11:before {
  content: "\f277";
}
.ficon-scrubber11:before {
  content: "\f278";
}
.ficon-scythe11:before {
  content: "\f279";
}
.ficon-search11:before {
  content: "\f27a";
}
.ficon-search-dollar11:before {
  content: "\f27b";
}
.ficon-search-location11:before {
  content: "\f27c";
}
.ficon-search-minus11:before {
  content: "\f27d";
}
.ficon-search-plus11:before {
  content: "\f27e";
}
.ficon-seedling11:before {
  content: "\f27f";
}
.ficon-server11:before {
  content: "\f280";
}
.ficon-shapes11:before {
  content: "\f281";
}
.ficon-share11:before {
  content: "\f282";
}
.ficon-share-all11:before {
  content: "\f283";
}
.ficon-share-alt11:before {
  content: "\f284";
}
.ficon-share-alt-square11:before {
  content: "\f285";
}
.ficon-share-square11:before {
  content: "\f286";
}
.ficon-sheep11:before {
  content: "\f287";
}
.ficon-shekel-sign11:before {
  content: "\f288";
}
.ficon-shield11:before {
  content: "\f289";
}
.ficon-shield-alt11:before {
  content: "\f28a";
}
.ficon-shield-check11:before {
  content: "\f28b";
}
.ficon-shield-cross11:before {
  content: "\f28c";
}
.ficon-ship11:before {
  content: "\f28d";
}
.ficon-shipping-fast11:before {
  content: "\f28e";
}
.ficon-shipping-timed11:before {
  content: "\f28f";
}
.ficon-shoe-prints11:before {
  content: "\f290";
}
.ficon-shopping-bag11:before {
  content: "\f291";
}
.ficon-shopping-basket11:before {
  content: "\f292";
}
.ficon-shopping-cart11:before {
  content: "\f293";
}
.ficon-shovel11:before {
  content: "\f294";
}
.ficon-shower11:before {
  content: "\f295";
}
.ficon-shredder11:before {
  content: "\f296";
}
.ficon-shuttlecock11:before {
  content: "\f297";
}
.ficon-shuttle-van11:before {
  content: "\f298";
}
.ficon-sigma11:before {
  content: "\f299";
}
.ficon-sign11:before {
  content: "\f29a";
}
.ficon-signal11:before {
  content: "\f29b";
}
.ficon-signal-111:before {
  content: "\f29c";
}
.ficon-signal-211:before {
  content: "\f29d";
}
.ficon-signal-311:before {
  content: "\f29e";
}
.ficon-signal-411:before {
  content: "\f29f";
}
.ficon-signal-alt11:before {
  content: "\f2a0";
}
.ficon-signal-alt-111:before {
  content: "\f2a1";
}
.ficon-signal-alt-211:before {
  content: "\f2a2";
}
.ficon-signal-alt-311:before {
  content: "\f2a3";
}
.ficon-signal-alt-slash11:before {
  content: "\f2a4";
}
.ficon-signal-slash11:before {
  content: "\f2a5";
}
.ficon-signature11:before {
  content: "\f2a6";
}
.ficon-sign-in11:before {
  content: "\f2a7";
}
.ficon-sign-in-alt11:before {
  content: "\f2a8";
}
.ficon-sign-language11:before {
  content: "\f2a9";
}
.ficon-sign-out11:before {
  content: "\f2aa";
}
.ficon-sign-out-alt11:before {
  content: "\f2ab";
}
.ficon-sitemap11:before {
  content: "\f2ac";
}
.ficon-skeleton11:before {
  content: "\f2ad";
}
.ficon-skull11:before {
  content: "\f2ae";
}
.ficon-skull-crossbones11:before {
  content: "\f2af";
}
.ficon-slash11:before {
  content: "\f2b0";
}
.ficon-sliders-h11:before {
  content: "\f2b1";
}
.ficon-sliders-h-square11:before {
  content: "\f2b2";
}
.ficon-sliders-v11:before {
  content: "\f2b3";
}
.ficon-sliders-v-square11:before {
  content: "\f2b4";
}
.ficon-smile11:before {
  content: "\f2b5";
}
.ficon-smile-beam11:before {
  content: "\f2b6";
}
.ficon-smile-plus11:before {
  content: "\f2b7";
}
.ficon-smile-wink11:before {
  content: "\f2b8";
}
.ficon-smog11:before {
  content: "\f2b9";
}
.ficon-smoke11:before {
  content: "\f2ba";
}
.ficon-smoking11:before {
  content: "\f2bb";
}
.ficon-smoking-ban11:before {
  content: "\f2bc";
}
.ficon-snake11:before {
  content: "\f2bd";
}
.ficon-snow-blowing11:before {
  content: "\f2be";
}
.ficon-snowflake11:before {
  content: "\f2bf";
}
.ficon-socks11:before {
  content: "\f2c0";
}
.ficon-solar-panel11:before {
  content: "\f2c1";
}
.ficon-sort11:before {
  content: "\f2c2";
}
.ficon-sort-alpha-down11:before {
  content: "\f2c3";
}
.ficon-sort-alpha-up11:before {
  content: "\f2c4";
}
.ficon-sort-amount-down11:before {
  content: "\f2c5";
}
.ficon-sort-amount-up11:before {
  content: "\f2c6";
}
.ficon-sort-down11:before {
  content: "\f2c7";
}
.ficon-sort-numeric-down11:before {
  content: "\f2c8";
}
.ficon-sort-numeric-up11:before {
  content: "\f2c9";
}
.ficon-sort-up11:before {
  content: "\f2ca";
}
.ficon-spa11:before {
  content: "\f2cb";
}
.ficon-space-shuttle11:before {
  content: "\f2cc";
}
.ficon-spade11:before {
  content: "\f2cd";
}
.ficon-spider11:before {
  content: "\f2ce";
}
.ficon-spider-black-widow11:before {
  content: "\f2cf";
}
.ficon-spider-web11:before {
  content: "\f2d0";
}
.ficon-spinner11:before {
  content: "\f2d1";
}
.ficon-spinner-third11:before {
  content: "\f2d2";
}
.ficon-splotch11:before {
  content: "\f2d3";
}
.ficon-spray-can11:before {
  content: "\f2d4";
}
.ficon-square11:before {
  content: "\f2d5";
}
.ficon-square-full11:before {
  content: "\f2d6";
}
.ficon-square-root11:before {
  content: "\f2d7";
}
.ficon-square-root-alt11:before {
  content: "\f2d8";
}
.ficon-squirrel11:before {
  content: "\f2d9";
}
.ficon-staff11:before {
  content: "\f2da";
}
.ficon-stamp11:before {
  content: "\f2db";
}
.ficon-star11:before {
  content: "\f2dc";
}
.ficon-star-and-crescent11:before {
  content: "\f2dd";
}
.ficon-star-exclamation11:before {
  content: "\f2de";
}
.ficon-star-half11:before {
  content: "\f2df";
}
.ficon-star-half-alt11:before {
  content: "\f2e0";
}
.ficon-star-of-david11:before {
  content: "\f2e1";
}
.ficon-star-of-life11:before {
  content: "\f2e2";
}
.ficon-stars11:before {
  content: "\f2e3";
}
.ficon-steering-wheel11:before {
  content: "\f2e4";
}
.ficon-step-backward11:before {
  content: "\f2e5";
}
.ficon-step-forward11:before {
  content: "\f2e6";
}
.ficon-stethoscope11:before {
  content: "\f2e7";
}
.ficon-sticky-note11:before {
  content: "\f2e8";
}
.ficon-stomach11:before {
  content: "\f2e9";
}
.ficon-stop11:before {
  content: "\f2ea";
}
.ficon-stop-circle11:before {
  content: "\f2eb";
}
.ficon-stopwatch11:before {
  content: "\f2ec";
}
.ficon-store11:before {
  content: "\f2ed";
}
.ficon-store-alt11:before {
  content: "\f2ee";
}
.ficon-stream11:before {
  content: "\f2ef";
}
.ficon-street-view11:before {
  content: "\f2f0";
}
.ficon-strikethrough11:before {
  content: "\f2f1";
}
.ficon-stroopwafel11:before {
  content: "\f2f2";
}
.ficon-subscript11:before {
  content: "\f2f3";
}
.ficon-subway11:before {
  content: "\f2f4";
}
.ficon-suitcase11:before {
  content: "\f2f5";
}
.ficon-suitcase-rolling11:before {
  content: "\f2f6";
}
.ficon-sun11:before {
  content: "\f2f7";
}
.ficon-sun-cloud11:before {
  content: "\f2f8";
}
.ficon-sun-dust11:before {
  content: "\f2f9";
}
.ficon-sun-haze11:before {
  content: "\f2fa";
}
.ficon-sunrise11:before {
  content: "\f2fb";
}
.ficon-sunset11:before {
  content: "\f2fc";
}
.ficon-superscript11:before {
  content: "\f2fd";
}
.ficon-surprise11:before {
  content: "\f2fe";
}
.ficon-swatchbook11:before {
  content: "\f2ff";
}
.ficon-swimmer11:before {
  content: "\f300";
}
.ficon-swimming-pool11:before {
  content: "\f301";
}
.ficon-sword11:before {
  content: "\f302";
}
.ficon-swords11:before {
  content: "\f303";
}
.ficon-synagogue11:before {
  content: "\f304";
}
.ficon-sync11:before {
  content: "\f305";
}
.ficon-sync-alt11:before {
  content: "\f306";
}
.ficon-syringe11:before {
  content: "\f307";
}
.ficon-table11:before {
  content: "\f308";
}
.ficon-tablet11:before {
  content: "\f309";
}
.ficon-tablet-alt11:before {
  content: "\f30a";
}
.ficon-tablet-android11:before {
  content: "\f30b";
}
.ficon-tablet-android-alt11:before {
  content: "\f30c";
}
.ficon-table-tennis11:before {
  content: "\f30d";
}
.ficon-tablet-rugged11:before {
  content: "\f30e";
}
.ficon-tablets11:before {
  content: "\f30f";
}
.ficon-tachometer11:before {
  content: "\f310";
}
.ficon-tachometer-alt11:before {
  content: "\f311";
}
.ficon-tachometer-alt-average11:before {
  content: "\f312";
}
.ficon-tachometer-alt-fast11:before {
  content: "\f313";
}
.ficon-tachometer-alt-fastest11:before {
  content: "\f314";
}
.ficon-tachometer-alt-slow11:before {
  content: "\f315";
}
.ficon-tachometer-alt-slowest11:before {
  content: "\f316";
}
.ficon-tachometer-average11:before {
  content: "\f317";
}
.ficon-tachometer-fast11:before {
  content: "\f318";
}
.ficon-tachometer-fastest11:before {
  content: "\f319";
}
.ficon-tachometer-slow11:before {
  content: "\f31a";
}
.ficon-tachometer-slowest11:before {
  content: "\f31b";
}
.ficon-tag11:before {
  content: "\f31c";
}
.ficon-tags11:before {
  content: "\f31d";
}
.ficon-tally11:before {
  content: "\f31e";
}
.ficon-tape11:before {
  content: "\f31f";
}
.ficon-tasks11:before {
  content: "\f320";
}
.ficon-taxi11:before {
  content: "\f321";
}
.ficon-teeth11:before {
  content: "\f322";
}
.ficon-teeth-open11:before {
  content: "\f323";
}
.ficon-temperature-frigid11:before {
  content: "\f324";
}
.ficon-temperature-high11:before {
  content: "\f325";
}
.ficon-temperature-hot11:before {
  content: "\f326";
}
.ficon-temperature-low11:before {
  content: "\f327";
}
.ficon-tennis-ball11:before {
  content: "\f328";
}
.ficon-terminal11:before {
  content: "\f329";
}
.ficon-text-height11:before {
  content: "\f32a";
}
.ficon-text-width11:before {
  content: "\f32b";
}
.ficon-th11:before {
  content: "\f32c";
}
.ficon-theater-masks11:before {
  content: "\f32d";
}
.ficon-thermometer11:before {
  content: "\f32e";
}
.ficon-thermometer-empty11:before {
  content: "\f32f";
}
.ficon-thermometer-full11:before {
  content: "\f330";
}
.ficon-thermometer-half11:before {
  content: "\f331";
}
.ficon-thermometer-quarter11:before {
  content: "\f332";
}
.ficon-thermometer-three-quarters11:before {
  content: "\f333";
}
.ficon-theta11:before {
  content: "\f334";
}
.ficon-th-large11:before {
  content: "\f335";
}
.ficon-th-list11:before {
  content: "\f336";
}
.ficon-thumbs-down11:before {
  content: "\f337";
}
.ficon-thumbs-up11:before {
  content: "\f338";
}
.ficon-thumbtack11:before {
  content: "\f339";
}
.ficon-thunderstorm11:before {
  content: "\f33a";
}
.ficon-thunderstorm-moon11:before {
  content: "\f33b";
}
.ficon-thunderstorm-sun11:before {
  content: "\f33c";
}
.ficon-ticket11:before {
  content: "\f33d";
}
.ficon-ticket-alt11:before {
  content: "\f33e";
}
.ficon-tilde11:before {
  content: "\f33f";
}
.ficon-times11:before {
  content: "\f340";
}
.ficon-times-circle11:before {
  content: "\f341";
}
.ficon-times-hexagon11:before {
  content: "\f342";
}
.ficon-times-octagon11:before {
  content: "\f343";
}
.ficon-times-square11:before {
  content: "\f344";
}
.ficon-tint11:before {
  content: "\f345";
}
.ficon-tint-slash11:before {
  content: "\f346";
}
.ficon-tire11:before {
  content: "\f347";
}
.ficon-tired11:before {
  content: "\f348";
}
.ficon-tire-flat11:before {
  content: "\f349";
}
.ficon-tire-pressure-warning11:before {
  content: "\f34a";
}
.ficon-tire-rugged11:before {
  content: "\f34b";
}
.ficon-toggle-off11:before {
  content: "\f34c";
}
.ficon-toggle-on11:before {
  content: "\f34d";
}
.ficon-toilet-paper11:before {
  content: "\f34e";
}
.ficon-toilet-paper-alt11:before {
  content: "\f34f";
}
.ficon-tombstone11:before {
  content: "\f350";
}
.ficon-tombstone-alt11:before {
  content: "\f351";
}
.ficon-toolbox11:before {
  content: "\f352";
}
.ficon-tooth11:before {
  content: "\f353";
}
.ficon-toothbrush11:before {
  content: "\f354";
}
.ficon-torah11:before {
  content: "\f355";
}
.ficon-torii-gate11:before {
  content: "\f356";
}
.ficon-tornado11:before {
  content: "\f357";
}
.ficon-tractor11:before {
  content: "\f358";
}
.ficon-trademark11:before {
  content: "\f359";
}
.ficon-traffic-cone11:before {
  content: "\f35a";
}
.ficon-traffic-light11:before {
  content: "\f35b";
}
.ficon-traffic-light-go11:before {
  content: "\f35c";
}
.ficon-traffic-light-slow11:before {
  content: "\f35d";
}
.ficon-traffic-light-stop11:before {
  content: "\f35e";
}
.ficon-train11:before {
  content: "\f35f";
}
.ficon-transgender11:before {
  content: "\f360";
}
.ficon-transgender-alt11:before {
  content: "\f361";
}
.ficon-trash11:before {
  content: "\f362";
}
.ficon-trash-alt11:before {
  content: "\f363";
}
.ficon-treasure-chest11:before {
  content: "\f364";
}
.ficon-tree11:before {
  content: "\f365";
}
.ficon-tree-alt11:before {
  content: "\f366";
}
.ficon-trees11:before {
  content: "\f367";
}
.ficon-triangle11:before {
  content: "\f368";
}
.ficon-trophy11:before {
  content: "\f369";
}
.ficon-trophy-alt11:before {
  content: "\f36a";
}
.ficon-truck11:before {
  content: "\f36b";
}
.ficon-truck-container11:before {
  content: "\f36c";
}
.ficon-truck-couch11:before {
  content: "\f36d";
}
.ficon-truck-loading11:before {
  content: "\f36e";
}
.ficon-truck-monster11:before {
  content: "\f36f";
}
.ficon-truck-moving11:before {
  content: "\f370";
}
.ficon-truck-pickup11:before {
  content: "\f371";
}
.ficon-truck-ramp11:before {
  content: "\f372";
}
.ficon-tshirt11:before {
  content: "\f373";
}
.ficon-tty11:before {
  content: "\f374";
}
.ficon-turkey11:before {
  content: "\f375";
}
.ficon-turtle11:before {
  content: "\f376";
}
.ficon-tv11:before {
  content: "\f377";
}
.ficon-tv-retro11:before {
  content: "\f378";
}
.ficon-umbrella11:before {
  content: "\f379";
}
.ficon-umbrella-beach11:before {
  content: "\f37a";
}
.ficon-underline11:before {
  content: "\f37b";
}
.ficon-undo11:before {
  content: "\f37c";
}
.ficon-undo-alt11:before {
  content: "\f37d";
}
.ficon-unicorn11:before {
  content: "\f37e";
}
.ficon-union11:before {
  content: "\f37f";
}
.ficon-universal-access11:before {
  content: "\f380";
}
.ficon-university11:before {
  content: "\f381";
}
.ficon-unlink11:before {
  content: "\f382";
}
.ficon-unlock11:before {
  content: "\f383";
}
.ficon-unlock-alt11:before {
  content: "\f384";
}
.ficon-upload11:before {
  content: "\f385";
}
.ficon-usd-circle11:before {
  content: "\f386";
}
.ficon-usd-square11:before {
  content: "\f387";
}
.ficon-user11:before {
  content: "\f388";
}
.ficon-user-alt11:before {
  content: "\f389";
}
.ficon-user-alt-slash11:before {
  content: "\f38a";
}
.ficon-user-astronaut11:before {
  content: "\f38b";
}
.ficon-user-chart11:before {
  content: "\f38c";
}
.ficon-user-check11:before {
  content: "\f38d";
}
.ficon-user-circle11:before {
  content: "\f38e";
}
.ficon-user-clock11:before {
  content: "\f38f";
}
.ficon-user-cog11:before {
  content: "\f390";
}
.ficon-user-crown11:before {
  content: "\f391";
}
.ficon-user-edit11:before {
  content: "\f392";
}
.ficon-user-friends11:before {
  content: "\f393";
}
.ficon-user-graduate11:before {
  content: "\f394";
}
.ficon-user-injured11:before {
  content: "\f395";
}
.ficon-user-lock11:before {
  content: "\f396";
}
.ficon-user-md11:before {
  content: "\f397";
}
.ficon-user-minus11:before {
  content: "\f398";
}
.ficon-user-ninja11:before {
  content: "\f399";
}
.ficon-user-plus11:before {
  content: "\f39a";
}
.ficon-users11:before {
  content: "\f39b";
}
.ficon-users-class11:before {
  content: "\f39c";
}
.ficon-users-cog11:before {
  content: "\f39d";
}
.ficon-users-crown11:before {
  content: "\f39e";
}
.ficon-user-secret11:before {
  content: "\f39f";
}
.ficon-user-shield11:before {
  content: "\f3a0";
}
.ficon-user-slash11:before {
  content: "\f3a1";
}
.ficon-user-tag11:before {
  content: "\f3a2";
}
.ficon-user-tie11:before {
  content: "\f3a3";
}
.ficon-user-times11:before {
  content: "\f3a4";
}
.ficon-utensil-fork11:before {
  content: "\f3a5";
}
.ficon-utensil-knife11:before {
  content: "\f3a6";
}
.ficon-utensils11:before {
  content: "\f3a7";
}
.ficon-utensils-alt11:before {
  content: "\f3a8";
}
.ficon-utensil-spoon11:before {
  content: "\f3a9";
}
.ficon-value-absolute11:before {
  content: "\f3aa";
}
.ficon-vector-square11:before {
  content: "\f3ab";
}
.ficon-venus11:before {
  content: "\f3ac";
}
.ficon-venus-double11:before {
  content: "\f3ad";
}
.ficon-venus-mars11:before {
  content: "\f3ae";
}
.ficon-vial11:before {
  content: "\f3af";
}
.ficon-vials11:before {
  content: "\f3b0";
}
.ficon-video11:before {
  content: "\f3b1";
}
.ficon-video-plus11:before {
  content: "\f3b2";
}
.ficon-video-slash11:before {
  content: "\f3b3";
}
.ficon-vihara11:before {
  content: "\f3b4";
}
.ficon-volcano11:before {
  content: "\f3b5";
}
.ficon-volleyball-ball11:before {
  content: "\f3b6";
}
.ficon-volume11:before {
  content: "\f3b7";
}
.ficon-volume-down11:before {
  content: "\f3b8";
}
.ficon-volume-mute11:before {
  content: "\f3b9";
}
.ficon-volume-off11:before {
  content: "\f3ba";
}
.ficon-volume-slash11:before {
  content: "\f3bb";
}
.ficon-volume-up11:before {
  content: "\f3bc";
}
.ficon-vote-nay11:before {
  content: "\f3bd";
}
.ficon-vote-yea11:before {
  content: "\f3be";
}
.ficon-vr-cardboard11:before {
  content: "\f3bf";
}
.ficon-walking11:before {
  content: "\f3c0";
}
.ficon-wallet11:before {
  content: "\f3c1";
}
.ficon-wand11:before {
  content: "\f3c2";
}
.ficon-wand-magic11:before {
  content: "\f3c3";
}
.ficon-warehouse11:before {
  content: "\f3c4";
}
.ficon-warehouse-alt11:before {
  content: "\f3c5";
}
.ficon-watch11:before {
  content: "\f3c6";
}
.ficon-watch-fitness11:before {
  content: "\f3c7";
}
.ficon-water11:before {
  content: "\f3c8";
}
.ficon-water-lower11:before {
  content: "\f3c9";
}
.ficon-water-rise11:before {
  content: "\f3ca";
}
.ficon-weight11:before {
  content: "\f3cb";
}
.ficon-weight-hanging11:before {
  content: "\f3cc";
}
.ficon-whale11:before {
  content: "\f3cd";
}
.ficon-wheat11:before {
  content: "\f3ce";
}
.ficon-wheelchair11:before {
  content: "\f3cf";
}
.ficon-whistle11:before {
  content: "\f3d0";
}
.ficon-wifi11:before {
  content: "\f3d1";
}
.ficon-wifi-111:before {
  content: "\f3d2";
}
.ficon-wifi-211:before {
  content: "\f3d3";
}
.ficon-wifi-slash11:before {
  content: "\f3d4";
}
.ficon-wind11:before {
  content: "\f3d5";
}
.ficon-window11:before {
  content: "\f3d6";
}
.ficon-window-alt11:before {
  content: "\f3d7";
}
.ficon-window-close11:before {
  content: "\f3d8";
}
.ficon-window-maximize11:before {
  content: "\f3d9";
}
.ficon-window-minimize11:before {
  content: "\f3da";
}
.ficon-window-restore11:before {
  content: "\f3db";
}
.ficon-windsock11:before {
  content: "\f3dc";
}
.ficon-wind-warning11:before {
  content: "\f3dd";
}
.ficon-wine-bottle11:before {
  content: "\f3de";
}
.ficon-wine-glass11:before {
  content: "\f3df";
}
.ficon-wine-glass-alt11:before {
  content: "\f3e0";
}
.ficon-won-sign11:before {
  content: "\f3e1";
}
.ficon-wrench11:before {
  content: "\fad8";
}
.ficon-x-ray11:before {
  content: "\fad9";
}
.ficon-yen-sign11:before {
  content: "\fada";
}
.ficon-yin-yang11:before {
  content: "\fadb";
}
.ficon-correct-symbol:before {
  content: "\e901";
}
.ficon-facebook:before {
  content: "\e900";
}
.ficon-pinterest:before {
  content: "\e902";
  color: #f44336;
}
.ficon-twitter:before {
  content: "\e903";
  color: #03a9f4;
}
