// Call Font Icons
@import "assets/fonticon/style.css";
@import "assets/fonts/style.css";

$tomato: #e64c3b;
$darkgrey: #333;

body {
  font-family: "SF-Pro";
  font-weight: normal;
  letter-spacing: 0.5px;
  font-size: 0.85em;
}

.max-width-500 {
  max-width: 550px;
}

.error-message {
  font-size: 0.75rem;
  margin: 2px 0 0;
  color: $tomato !important;
}

/* ============= Common Styles =============*/
.table {
  margin-bottom: 0;
  thead {
    background: #444;
    color: #ddd;
    th {
      border: 0;
      font-weight: 600;
      align-items: center;
      vertical-align: middle;
      justify-content: space-between;
    }
  }
  tbody {
    tr {
      transition: 0.5s;
      &:nth-child(odd) {
        background: #fafafa;
      }
      td {
        border-color: #eee;
        a {
          color: $tomato;
          &:hover {
            text-decoration: none;
          }
          i {
            &.ficon-times1 {
              font-size: 1.2em;
            }
          }
          &.has-icon {
            // padding: 2px 3px;
            margin: 0 5px;
            border: 0;
          }
        }
        button {
          white-space: nowrap;
          min-width: auto;
          margin-left: 3px !important;
          margin-right: 3px !important;
          &.has-icon {
            padding: 0;
            margin: 0;
            border: 0;
            background: transparent;
            color: $tomato;
          }
          &.no-border {
            border: 0 !important;
            background: transparent !important;
            &:active {
              border: 0 !important;
              background: none !important;
              background: transparent !important;
              color: $tomato !important;
            }
          }
          &.disabled {
            cursor: not-allowed;
            i[class*="ficon-"] {
              &:hover {
                pointer-events: none;
                background: transparent;
                color: $tomato;
              }
            }
          }
        }
        .btn {
          padding: 5px 10px;
          font-size: 0.85em;
          line-height: 1.3;
          margin: -8px 0 -4px;
          &.btn-secondary {
            background: #fff;
            border: 1px solid #333;
            &:active {
              background: #333;
              color: #eee;
              border-color: #333;
            }
          }
        }
        i[class*="ficon-"] {
          font-size: 0.9rem;
          border: 1px solid $tomato;
          border-radius: 100px;
          padding: 4px 10px;
          min-width: 37px;
          display: inline-block;
          text-align: center;
          transition: 0.5s;
          &:hover {
            background: $tomato;
            color: #fff;
          }
        }
        em {
          &.small-label {
            font-style: normal;
            font-size: 0.75rem;
            letter-spacing: normal;
            font-weight: 500;
            white-space: nowrap;
          }
        }
        label {
          margin-bottom: 0;
        }
      }
      &:hover {
        background: #eee;
      }
    }
  }
}

button {
  &:focus {
    outline: none;
  }
}

.btn {
  border-radius: 0.2em;
  min-width: 72px;
  &.btn-primary {
    &:not(:disabled) {
      background: #333 !important;
      border-color: #333 !important;
      color: #fff;
      &:hover {
        background-color: #222;
      }
    }
  }
  &:focus {
    box-shadow: none;
    &:active {
      outline: none;
      box-shadow: none !important;
    }
  }
  &.btn-outline-dark {
    background: transparent;
    &:hover {
      background: $darkgrey;
    }
  }
  &.btn-link {
    color: $tomato;
  }
}

.form-control:focus {
  box-shadow: none;
  border-color: #666;
}

/* end Common Styles*/

.app-header {
  box-shadow: 0 -2px 5px 0px rgba(0, 0, 0, 0.25);
  border: 0;
  position: sticky !important;
  top: 0;
  .navbar-toggler {
    &:focus {
      outline: none;
    }
  }
  .profile-btn {
    button {
      &.btn {
        &.btn-secondary {
          background: transparent;
          border: 0;
          margin-right: 8px;
          min-width: auto;
          &:focus {
            box-shadow: none;
          }
        }
      }
      span {
        margin-right: 5px;
      }
    }
    .dropdown-menu {
      left: auto !important;
      right: 18px !important;
      top: 35px !important;
      transform: none !important;
      overflow: hidden;
      button {
        &.dropdown-item {
          padding: 6px 22px 8px;
          display: flex;
          align-items: center;
          &:active {
            background: $darkgrey;
            color: #eee;
          }
          &:focus {
            outline: none;
          }
          &:hover {
            background: $darkgrey;
            color: #eee;
            i {
              color: #eee;
            }
          }
          i {
            color: $darkgrey;
            margin-right: 8px;
          }
        }
      }
    }
  }
  .business-title {
    font-weight: 600;
    font-size: 1.1rem;
  }
  @media screen and (max-width: 991.98px) {
    .business-title {
      display: none;
    }
  }
}

.border-radius-0 {
  border-radius: 0 !important;
}

.section-tabs {
  .nav-link {
    color: #555 !important;
    cursor: pointer;
    &.active {
      background: none;
      border-bottom: 2px solid #333;
      border-radius: 0;
      color: #333 !important;
      font-weight: 600;
    }
  }
}

.sidebar {
  background: $darkgrey;
  .nav-link {
    font-weight: 300;
    &:hover {
      background: $tomato;
    }
    &.active {
      background: $tomato;
      .nav-icon {
        color: #fff;
      }
    }
    .nav-icon {
      margin-right: 0.75rem;
      width: 1.25rem;
      font-size: 1rem;
    }
  }
  // .navbar-logo{
  //     margin: 0;
  //     padding: 0 0 5px 10px;
  //     img{
  //         width: auto;
  //         max-width: 100%;
  //         height: auto;
  //         padding-left: 25px;
  //         padding-bottom: 20px;
  //     }
  // }
  .navbar-logo {
    padding: 0 10px;
    margin: 0 0 20px;
    height: 46px;
    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }
}
body {
  &.sidebar-minimized {
    .navbar-logo {
      display: flex;
      margin-bottom: 50px;
    }
  }
}

@media (max-width: 575.98px) {
  .sidebar {
    margin-top: 56px;
  }
}

.aside-menu {
  .nav-tabs {
    .nav-link.active {
      color: $tomato;
    }
  }
}

.navbar-brand {
  .navbar-brand-full {
    max-width: 126px;
  }
  .navbar-brand-minimized {
    max-width: 32px;
  }
}

.sidebar-minimized {
  .sidebar {
    .nav-link {
      &:hover {
        background: #e64c3b !important;
      }
      .badge {
        &:empty {
          display: none;
        }
      }
    }
  }
}

.card-header {
  border: 0;
  h2 {
    font-size: 1.2em;
    margin: 0.25em 0;
  }
  i {
    display: none;
  }
}

input[type="checkbox"] {
  display: none;
  & + label {
    &:before {
      font-family: "fonticon";
      content: "\ed1a";
      font-weight: normal;
      font-size: 1.1rem;
    }
  }
  &:checked {
    & + label {
      &:before {
        font-family: "fonticon";
        content: "\e9ed";
      }
    }
  }
}

.note-sidebar {
  background: #fafafa;
  .note-block {
    margin: 15px 0 0;
    &:first-child {
      padding-bottom: 15px;
      border-bottom: 1.2px dashed #ddd;
    }
    h4 {
      font-size: 0.95rem;
    }
    textarea {
      width: 100%;
      resize: none;
      height: 160px;
      border-radius: 4px;
      border: 1px solid #bbb;
      padding: 8px 10px;
    }
    button {
      margin-bottom: 10px;
      background: $tomato;
      border: 0;
      color: #fff;
      border-radius: 0.2rem;
      padding: 4px 15px;
      font-size: 0.8rem;
    }
  }
}
@media screen and (max-width: 575.98px) {
  .note-sidebar {
    padding-bottom: 70px;
  }
}

.video-window {
  margin: 0;
  height: calc(100vh - 105px);
  border: 0;
  background: #222;
  border-radius: 0;
  .video-wrapper {
    height: 100%;
    position: relative;
    .guest-video {
      position: absolute;
      left: 50%;
      top: 0;
      height: 100%;
      width: auto;
      transform: translate(-50%, 0);
      -webkit-transform: translate(-50%, 0);
      z-index: 1;
      // .flex-item{
      //     overflow: hidden !important;
      //     margin: 0;
      //     height: 100%;
      //     background: transparent;
      //     div{
      //         height: 100%;
      //     }

      // }
      video {
        max-height: 100%;
        height: 100%;
        width: auto;
      }
    }
    .host-video {
      position: absolute;
      right: 0;
      bottom: 0;
      height: auto;
      z-index: 2;
      margin: 0 40px 40px 0;
      .flex-item {
        overflow: hidden !important;
        margin: 0;
        background: transparent;
        video {
          max-width: 150px;
          height: auto;
        }
      }
    }
    @media (max-width: 575.98px) {
      .host-video {
        top: 0;
        bottom: auto;
        margin: 20px 20px 0 0;
        video {
          max-width: 120px !important;
        }
      }
    }
    .video-btns {
      position: absolute;
      z-index: 3;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0);
      -webkit-transform: translate(-50%, 0);
      margin: 0 0 40px;
      display: flex;
      button {
        margin: 0 10px;
        padding: 0;
        height: 50px;
        width: 50px;
        border-radius: 200px;
        background: #ddd;
        border: 0;
        color: #452563;
        min-width: auto;
        &.critical {
          background: #f45e5c;
          color: #fff;
          i {
            transform: rotate(-137deg);
          }
        }
        i {
          font-size: 1.2rem;
          display: inline-block;
        }
        &.not-rounded {
          border-radius: 0.2rem;
          width: auto;
          height: auto;
          padding: 6px 15px;
          &:not(:disabled) {
            background: #70984c !important;
            color: #fff;
          }
          &:disabled {
            color: #ddd !important;
            background: transparent;
          }
        }
      }
    }
  }
}

.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  border-radius: 0.2rem;
  border-color: #ddd;
  min-height: 40px;
  &:-webkit-autofill {
    background-color: red !important;
    box-shadow: 0 0 0px 1000px white inset;
    -webkit-box-shadow: 0 0 0px 1000px white inset;
  }
  &:focus {
    background: #fff;
    box-shadow: 0 0 0px 1000px white inset !important;
    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
  }
}

.form-control,
textarea {
  border-color: #ccc;
  border-radius: 0.2rem;
}

textarea {
  width: 100%;
  resize: none;
  height: 100px;
  padding: 2px 5px;
  &:focus {
    border-color: #333;
    box-shadow: none;
    outline: none;
  }
}

.app-loader {
  position: fixed;
  left: 50%;
  top: 50%;
  width: 100%;
  height: 100%;
  z-index: 9999;
}
.start-appointment {
  margin-left: 0px !important;
}

.appointment-button {
  border: none;
  background: none;
  color: #f86c6b;
}

.privacypolicy-button {
  border: none;
  background: none;
  color: #000000;
  text-decoration: underline;
}

/* ------- New Appintment ------- */
.time-slot {
  min-height: 110px;
  max-height: 250px;
  overflow: auto;
  button {
    width: 100%;
    max-width: 100%;
    text-align: center;
    margin: 0 0 10px;
    font-weight: 500;
  }
}

/* xxxxxxxxxxxx */

.app-alert {
  position: fixed;
  left: 35%;
  top: 0%;
  width: 500px;
  height: 50px;
  z-index: 9999;
}

/* =========== Login ========== */
.app-login {
  background: url(assets/img/bg-pattern.jpg) repeat;
}

.login-card {
  box-shadow: 0 0 100px 0 rgba(0, 0, 0, 0.15);
  border: 0;
  .card-body {
    padding: 0.5rem;
    img {
      display: flex;
      max-width: 190px;
      margin: 0 auto;
    }
  }
}

/* ============= Popup =========== */
.popup-invite {
  min-height: 100px;
  .form-group {
    width: calc(100% - 83px);
    margin-right: 10px;
    margin-bottom: 0;
    input {
      margin: 0;
    }
  }
}

.has-calendar {
  position: relative;
  .print-date {
    width: 100%;
    text-align: left;
    border-color: #ccc;
    &:hover,
    &:active {
      background: transparent !important;
      color: #2f353a !important;
    }
    &:active {
      border-color: #666;
    }
  }
  .date-btn {
    position: absolute;
    right: 0;
    padding: 6px 10px;
    border: 0;
    min-width: auto;
    margin: 0 20px 0 0;
    &:hover,
    &:active {
      background: transparent !important;
      color: #2f353a !important;
    }
  }
  .react-calendar {
    position: absolute;
    z-index: 1;
  }
}

/* ========== Profile / Settings ======== */
.settings-footer {
  margin-left: 0 !important;
}

.spinner {
  flex: 1;
  align-items: "center";
  justify-content: "center";
  background-color: "#ddd";
}

.profile-photo {
  margin: 0;
  width: 68px;
  height: 68px;
  border-radius: 300px;
  border: 1.5px solid $tomato;
  overflow: hidden;
  margin-bottom: 10px;
  position: relative;
  img {
    object-fit: cover;
    width: auto;
    height: 100%;
    left: 50%;
    top: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
  }
}

.node {
  display: inline-block;
  margin: 0 5px 5px 0;
  position: relative;
  background: $tomato;
  border-radius: 3px;
  padding: 4px 10px;
  line-height: 1;
  button {
    background: transparent;
    padding: 0;
    margin: 0;
    min-width: auto;
    border: 0;
    color: #fff;
    cursor: default;
    &:hover,
    &:active,
    &:focus {
      background: none !important;
      color: #fff !important;
    }
    &.close-node {
      padding: 0 2px 0 10px;
      font-size: 0.9em;
      line-height: 1;
    }
  }
}

/* Call Quota Chart */
.call-quota {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 300px;
  flex-wrap: wrap;
  .quota-chart {
    width: 100%;
    max-width: 160px;
    height: auto;
  }
  span {
    b.used-minutes {
      color: $tomato;
    }
  }
  .CircularProgressbar .CircularProgressbar-path {
    stroke: $tomato;
  }
  .CircularProgressbar .CircularProgressbar-trail {
    stroke: #eee;
  }
}

/* ============= Reset Password =========== */
.reset-password-divider {
  width: 5px;
  height: auto;
  display: inline-block;
}

/* ============ New Client ============ */
.PhoneInput {
  .PhoneInputCountryIcon {
    display: flex;
  }
  input {
    border: 1px solid #ccc;
    border-radius: 0.2rem;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
  }
}
.pagination-wrapper {
  display: flex;
  margin-top: 25px;
  justify-content: center;
}

.acitve-pagination {
  color: #e64c3b !important;
}

.in-acitve-pagination {
  background-color: #e64c3b !important;
  border-color: #e64c3b !important;
}
.list-dots {
  color: #e64c3b !important;
  position: relative;
  display: block;
  padding: 0.5rem 0.6rem;
  margin-left: -1px;
  line-height: 1.25;
  background-color: #fff;
  border: 1px solid #c8ced3;
}
.pointer {
  cursor: pointer;
}
.flex-centered {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.active-sorting {
  color: #e64c3b;
}
